import comingSoonImg from "../../assets/img/album_4.jpg";
import { useGSAP } from "@gsap/react";
import SplitType from "split-type";
import { gsap } from "gsap";
import { useState } from "react";
import "./style.css";

gsap.registerPlugin(useGSAP);

const PopUp = ({ setShowPopup }) => {
    const [popup, setPopup] = useState(false);

    useGSAP(() => {
        setPopup(true);
        SplitType.create(".soon-txt");
        gsap.set(".coming-soon-bg", {
            yPercent: -20,
            opacity: 0,
        });
        const tl = gsap.timeline();

        tl.to(".coming-soon-bg", {
            yPercent: 0,
            opacity: 1,
        });

        tl.from(".soon-txt .char", {
            yPercent: -120,
            stagger: 0.15,
            duration: 1.5,
            ease: "bounce.out",
        });
    });

    return (
        <div
            className={`z-150 position-relative ${popup ? "d-flex" : "d-none"}`}
            onClick={() => setShowPopup(false)}>
            <div className="position-fixed w-100 z-50 modal-bg">
                <div className="d-flex align-items-center justify-content-center text-center modal-inner">
                    <div className="position-relative modal-in coming-soon-bg">
                        <div
                            className="position-absolute modal-inn"
                            onClick={() => setShowPopup(false)}>
                            &times;
                        </div>

                        <div className="d-flex justify-content-center text-xs">
                            <h4 className="sz-color soon-txt">Coming Soon</h4>
                        </div>

                        <div className="sizeguide-table text-center">
                            <img
                                src={comingSoonImg}
                                alt="coming Soon img"
                                className="coming-soon-img"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopUp;
