import "./style.css";
import { useRef } from "react";
import { gsap } from "gsap";
import SplitType from "split-type";
import { useGSAP } from '@gsap/react';
import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-autoplay.css';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgAutoplay from 'lightgallery/plugins/autoplay'
import { Helmet } from "react-helmet";

const images = [
    { src: "./images/image0.jpeg", alt: "1" },
    { src: "./images/image1.jpeg", alt: "2" },
    { src: "./images/image2.jpeg", alt: "3" },
    { src: "./images/image3.jpeg", alt: "4", },
    { src: "./images/image4.jpeg", alt: "5", },
    { src: "./images/image5.jpeg", alt: "6", },
    { src: "./images/image6.jpeg", alt: "7", },
    { src: "./images/image7.jpeg", alt: "8", },
    { src: "./images/image8.jpeg", alt: "9", },
    { src: "./images/image9.jpeg", alt: "10", },
    { src: "./images/image9-a.jpeg", alt: "11", },
    { src: "./images/image10.jpeg", alt: "12", },
    { src: "./images/image11.jpeg", alt: "13", },
    { src: "./images/image12.jpeg", alt: "14", },
    { src: "./images/image13.jpeg", alt: "15", },
    { src: "./images/image14.jpeg", alt: "16", },
    { src: "./images/image15.jpeg", alt: "17", },
    { src: "./images/image16.jpeg", alt: "18", },
]

const Gallery = () => {

    const meet = useRef(null);
    const owner = useRef(null);
    useGSAP(() => {
        SplitType.create(meet.current)
        SplitType.create(owner.current)

        gsap.set(".char", { yPercent: 100 })

        gsap.to(".char", {
            yPercent: 0,
            stagger: .05,
            rotate: 0.001,
            duration: 1.7,
            ease: "Expo.easeInOut",
        })
    });
    return (
        <>
            <Helmet>
                <title>Gallery: Meet Shaderah Dey-Al Today</title>
                <meta name="description" content="Visit the Gallery of Tertius AhmaNuph and meet Shaderah Dey-Al, discovering her unique blend of art and musical talent." />
                <link rel="canonical" href="https://tertiusahmanuph.co/gallery" />
            </Helmet>
            <main id="gallery">

                <div className="gallery-info text-center py-5">
                    <h3 ref={meet}>Meet</h3>
                    <h2 ref={owner}>SHADERAH Dey-Al</h2>
                </div>

                <div className="gallery-wrapper px-3">
                    <LightGallery
                        speed={500}
                        download={false}
                        slideShowAutoplay={true} // Enable autoplay
                        plugins={[lgThumbnail, lgAutoplay]}>
                        {images.map((image, index) => (
                            <a href={image.src} key={index}>
                                <img alt={image.alt} src={image.src} />
                            </a>
                        ))}
                    </LightGallery>
                </div>
            </main>
        </>
    );
};

export default Gallery;
