import { useRef, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { PopUp } from "../../components";
import axios from "axios";
import "./style.css";

const Albums = ({ showAlbums, hideAlbums }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [albums, setAlbums] = useState([]);
  const sidebarRef = useRef(null);
  const { pathname } = useLocation();

  const handleShowPopup = () => {
    hideAlbums()
    setShowPopup(true);
  }

  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        const response = await axios.post(
          `https://1cd11f-e1.myshopify.com/api/2023-07/graphql.json`,
          {
            query: `{
              collectionByHandle(handle: "albums") {
                products(first: 10) {
                  edges {
                    node {
                      id
                      title
                    }
                  }
                }
              }
            }`,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Shopify-Storefront-Access-Token': 'fcfc23a617e5f957bd56a60f1b68191b',
            },
          }
        );
        setAlbums(response.data.data.collectionByHandle.products.edges);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchAlbums();
  }, []);

  const extractId = (gid) => gid.split("/").pop();

  useEffect(() => {

    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        hideAlbums();
      }
    }

    if (showAlbums) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAlbums, hideAlbums]);

  return (
    <>
      {showPopup && <PopUp setShowPopup={setShowPopup} />}
      <div
        ref={sidebarRef}
        className={`albums-container ${showAlbums ? "left-0" : "left-full"} ${pathname === "/room" ? "room__album" : ""} `}>
        <h3 className={pathname === "/room" ? "text-white" : ""}>Albums</h3>
        <div className={`divider ${pathname === "/room" ? "bg-white" : ""}`}></div>
        <ul>
          {albums.map((album) => (
            <li key={extractId(album.node.id)} className="mb-3">
              <NavLink className={pathname === "/room" ? "text-white" : ""} to={`/products/${extractId(album.node.id)}`}>{album.node.title}</NavLink>
            </li>
          ))}
        </ul>
        <h5 onClick={hideAlbums} className={pathname === "/room" ? "text-white" : ""}>Close</h5>
      </div>
    </>
  );
};

export default Albums;
