import { Container, Row, Col } from "react-bootstrap";
import fb from "../../../assets/img/facebook-2.svg";
import insta from "../../../assets/img/instagram.svg";
import tiktok from "../../../assets/img/tiktok.svg";
import tube from "../../../assets/img/youtube.svg";
import send from "../../../assets/img/send-1.svg";
import ReCAPTCHA from 'react-google-recaptcha'
import { useRef, useState } from "react";
import { useGSAP } from "@gsap/react";
import { Helmet } from "react-helmet";
import emailjs from 'emailjs-com';
import { gsap } from "gsap";
import "./style.css";

const Contact = () => {
  const contactTXT = useRef(null);
  const pTxt = useRef(null);
  const recaptcha = useRef(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue()

    if (!captchaValue) {
      alert('Please verify the reCAPTCHA!')
    } else {
      setLoading(true);
      emailjs.sendForm(process.env.REACT_APP_YOUR_SERVICE_ID, process.env.REACT_APP_YOUR_TEMPLATE_ID, e.target, process.env.REACT_APP_YOUR_PUBLIC_KEY)
        .then((result) => {
          alert('Email sent successfully!');
          setFormData({ name: '', email: '', subject: '', message: '' });
          recaptcha.current.reset();
        }, (error) => {
          alert('Failed to send email, try again later.');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };


  useGSAP(() => {
    const headLineFirst = pTxt.current.children[0];
    const headLineSecond = headLineFirst.nextSibling;
    const headLineThird = headLineSecond.nextSibling;
    const headLineFourth = headLineThird.nextSibling;
    const headLineFifth = headLineFourth.nextSibling;
    const headLineSixth = headLineFifth.nextSibling;
    const headLineSeventh = headLineSixth.nextSibling;

    gsap.from(
      [
        headLineFirst.children,
        headLineSecond.children,
        headLineThird.children,
        headLineFourth.children,
        headLineFifth.children,
        headLineSixth.children,
        headLineSeventh.children,
      ],
      {
        yPercent: 130,
        stagger: 0.1,
        delay: 0.5,
      }
    );

    gsap.from(contactTXT.current, {
      yPercent: 130,
      duration: 1.2,
      stagger: 0.1,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Contact Tertius AhmaNuph Anytime | Savannah GA</title>
        <meta name="description" content="In Savannah Georgia? Don’t hesitate to Contact Tertius AhmaNuph. We’re excited to hear your thoughts and start a conversation!" />
        <link rel="canonical" href="https://tertiusahmanuph.co/contact" />
      </Helmet>

      <section id="contact">
        <Container fluid>
          <Row>
            <Col xs="12" sm="12" md="6" xl="6">
              <div className="contact-txt-wrapper">
                <h2 className="text-left" ref={contactTXT}>
                  Get in Touch with Us
                </h2>
              </div>
              <div className="p-txt" ref={pTxt}>
                <div className="p-txt-container">
                  <div>Whether you have a question, want to share</div>
                </div>
                <div className="p-txt-container">
                  <div>
                    your thoughts, or just want to say hello to
                  </div>
                </div>
                <div className="p-txt-container">
                  <div>Shaderah Dey-Al and the Tertius AhmaNuph</div>
                </div>
                <div className="p-txt-container">
                  <div>
                    team would love to hear from you! We're all
                  </div>
                </div>
                <div className="p-txt-container">
                  <div>
                    about connection, just like the music we create.
                  </div>
                </div>
                <div className="p-txt-container">
                  <div>Drop us a message, and let’s start a conversation.</div>
                </div>
                <div className="p-txt-container">
                  <div>
                    <strong>Reach Out, Don’t Be Shy!</strong>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <address className="contact-txt">
                PO Box 15156, Savannah Georgia 31416
              </address>{" "}
              <br />
              <a className="contact-txt" href="mailto:tertiusdim9nsion@gmail.com">
                tertiusdim9nsion@gmail.com
              </a>{" "}
              <br />
              <a className="contact-txt" href="tel:912-323-0129">
                912-323-0129
              </a>{" "}
              <br />
              <ul className="d-flex align-items-center justify-content-start my-5">
                <li>
                  <a
                    href="https://www.facebook.com/share/VhvQaeAjtwL1W9o2/?mibextid=LQQJ4d"
                    rel="noreferrer"
                    target="_blank">
                    <img src={fb} alt="icon" width={30} className="social" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/shaderahdeyal?igsh=MW91OG5ndmJiaTlxZA%3D%3D&utm_source=qr"
                    target="_blank"
                    rel="noreferrer">
                    <img src={insta} alt="icon" width={30} className="social" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.tiktok.com/@shaderahdeyal?_t=8mgHauBCkuM&_r=1"
                    target="_blank" rel="noreferrer">
                    <img src={tiktok} alt="icon" width={30} className="social" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@shaderahdeyal"
                    target="_blank" rel="noreferrer">
                    <img src={tube} alt="icon" width={30} className="social" />
                  </a>
                </li>
              </ul>
            </Col>
            <Col xs="12" sm="12" md="6" xl="6">
              <form onSubmit={sendEmail}>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="mb-3">
                      <input
                        type="text"
                        name="subject"
                        className="form-control"
                        placeholder="Subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="mb-3">
                      <textarea
                        name="message"
                        id="message"
                        cols="30"
                        rows="10"
                        className="form-control"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>

                      <button className="btn-send" type="submit" disabled={loading}>
                        {loading ? 'Sending...' : 'Send'}
                        &nbsp;
                        {loading ? "" : <img src={send} alt="icon" width={20} />}
                      </button>
                      <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={recaptcha} />
                    </div>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Contact;
