import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "./style.css";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy at Tertius AhmaNuph</title>
        <meta name="description" content="Review the Privacy Policy for Tertius AhmaNuph. We are committed to protecting your privacy here in Savannah Georgia." />
        <link rel="canonical" href="https://tertiusahmanuph.co/pirvacy-policy" />
      </Helmet>

      <main className="privacy">
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-center">Privacy Policy</h1>

              <h2>Introduction</h2>

              <p>
                At Tertius AhmaNuph, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you visit our website, purchase our products, or engage with our services.
              </p>

              <h3>Information We Collect</h3>

              <p>
                •	<strong>Personal Information:</strong> When you register on our site, make a purchase, or contact us, we collect personal information such as your name, email address, mailing address, phone number, and payment details.
              </p>

              <p>
                •	<strong>Non-Personal Information:</strong> We may collect non-personal data such as browser type, device type, IP address, and browsing habits to improve our website's functionality and user experience.
              </p>

              <p>
                •	<strong>Cookies:</strong> We use cookies to track your activity on our site and enhance your experience. Cookies are small data files stored on your device. You can disable cookies through your browser settings, but this may affect your ability to use certain features of our website.
              </p>

              <h3>How We Use Your Information</h3>

              <p>
                •	<strong>Order Processing:</strong> Your personal information is primarily used to process and fulfill your orders, including payment processing, shipping, and customer support.
              </p>

              <p>
                •	<strong>Communication:</strong> We may use your contact information to send you updates, promotional offers, and newsletters. You can opt out of these communications at any time by following the unsubscribe instructions provided in the emails.
              </p>

              <p>
                •	<strong>Website Improvement:</strong> Non-personal information helps us analyze website traffic and user behavior, enabling us to enhance our services and tailor our content to better meet your needs.
              </p>

              <p>
                •	<strong>Security:</strong> We use your data to detect, prevent, and address potential security issues and to ensure that your information is protected.
              </p>

              <h3>How We Share Your Information</h3>

              <p>
                •	<strong>Third-Party Service Providers:</strong> We may share your personal information with trusted third-party service providers who assist us in operating our website, conducting business, or servicing you. These providers are obligated to keep your information confidential and use it only for the purposes we specify.
              </p>

              <p>
                •	<strong>Legal Compliance:</strong> We may disclose your information if required by law or in response to valid requests by public authorities (e.g., a court or a government agency).
              </p>

              <p>
                •	<strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction. We will notify you via email and/or a prominent notice on our website of any such change in ownership.
              </p>

              <h3>Data Security</h3>

              <p>
                We implement a variety of security measures to maintain the safety of your personal information. These measures include encryption, access controls, and secure payment gateways. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
              </p>

              <h3>Your Rights and Choices</h3>

              <p>
                •	<strong>Access and Correction:</strong>You have the right to access the personal information we hold about you and request corrections if necessary. You can update your account information directly through our website or by contacting us.
              </p>

              <p>
                •	<strong>Data Deletion:</strong>You can request the deletion of your personal information by contacting us, and we will take reasonable steps to erase your data, except where we are required to retain it for legal or legitimate business purposes.
              </p>

              <p>
                •	<strong>Opt-Out:</strong>You may opt out of receiving promotional communications from us by following the unsubscribe instructions provided in our emails or by contacting us directly.
              </p>

              <h3>Third-Party Links</h3>

              <p>
                Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage you to read the privacy policies of any website you visit.
              </p>

              <h3>Children’s Privacy</h3>

              <p>
                Tertius AhmaNuph does not knowingly collect or solicit personal information from children under the age of 13. If we learn that we have collected personal information from a child under 13, we will delete that information as quickly as possible.
              </p>

              <h3>Changes to This Privacy Policy</h3>

              <p>
                We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the new policy on our website with an updated effective date.
              </p>

              <h3>Contact Us</h3>

              <p>
                If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a href="mailto:tertiusdim9nsion@gmail.com" className="text-black">tertiusdim9nsion@gmail.com</a>.
              </p>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
};

export default PrivacyPolicy;
