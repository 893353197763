import "./style.css";
import { Container, Row, Col } from "react-bootstrap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import SplitType from "split-type";
import { gsap, Expo } from "gsap";
import { useRef, useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Helmet } from "react-helmet";

gsap.registerPlugin(ScrollTrigger);

const Videos = () => {
  const ytText = useRef();

  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const API_URL = `https://www.googleapis.com/youtube/v3/search?key=${process.env.REACT_APP_API_KEY}&channelId=${process.env.REACT_APP_CHANNEL_ID}&part=snippet,id&order=date&maxResults=10&type=video`;

  useEffect(() => {
    fetch(API_URL)
      .then((response) => response.json())
      .then((data) => {
        setVideos(data.items)
        setIsLoading(true)
      })
      .catch((error) => {
        console.error("Error fetching videos:", error);
      });
  }, [API_URL]);

  useGSAP(() => {
    SplitType.create(ytText.current);

    gsap.set(".char", { yPercent: 100 });

    gsap.to(".char", {
      yPercent: 0,
      stagger: 0.05,
      duration: 1.7,
      ease: Expo.easeInOut,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>YouTube | The Album Dive | Tertius AhmaNuph</title>
        <meta name="description" content="Watch YouTube for insights into The Album Dive by Tertius AhmaNuph, including the 'Silhouette' video for 'Get This Way.'" />
        <link rel="canonical" href="https://tertiusahmanuph.co/youtube" />
      </Helmet>
      <main id="youtube">
        <Container fluid>
          <Row>
            <h2 className="text-center pb-5" ref={ytText}>
              The Album Dive
            </h2>
            {isLoading ? (
              <>
                {videos.map((video) => (
                  <Col sm={12} md={12} lg={6} key={video.id.videoId}>
                    <iframe
                      className="yt-videos"
                      src={`https://www.youtube.com/embed/${video.id.videoId}`}
                      allowFullScreen
                      title={video.snippet.title}
                    ></iframe>
                    <h3 className="yt-title">
                      {video.snippet.title}
                    </h3>
                    <p className="yt-description">
                      {video.snippet.description}
                    </p>
                  </Col>
                ))}
              </>
            ) : (
              <>
                {Array(videos.length || 4).fill(0).map((_, index) => (
                  <Col sm={12} md={12} lg={6} key={index}>
                    <SkeletonTheme
                      baseColor="var(--dark-beige)"
                      highlightColor="var(--middle-beige)"
                      className="yt-videos-wrapper">
                      <Skeleton height={400} />
                      <h3 className="yt-title">
                        <Skeleton width={500} />
                      </h3>
                      <p className="yt-description">
                        <Skeleton width={400} />
                      </p>
                    </SkeletonTheme>
                  </Col>
                ))}
              </>
            )}
          </Row>
        </Container>
      </main>
    </>
  );
};

export default Videos;
