import "./home.css";
import { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import albumOne from "../../../assets/img/album_1.webp";
import heroVedio from "../../../assets/img/hero-vedio.mp4";
import { Swiper, SwiperSlide } from "swiper/react";
import SplitType from "split-type";
import ScrollTrigger from "gsap/ScrollTrigger";
import "swiper/css";
import "swiper/css/pagination";
import { Helmet } from "react-helmet";

import { Pagination, Autoplay } from "swiper/modules";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {

  const [activeTab, setActiveTab] = useState("story");

  let albums = useRef(null);
  let herotxt = useRef(null);
  let spanTxt = useRef(null);
  let videoRef = useRef(null);
  let testimonials = useRef(null);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useGSAP(() => {
    SplitType.create(herotxt.current);
    SplitType.create(spanTxt.current);
    SplitType.create(albums.current);
    SplitType.create(testimonials.current);

    gsap.from("#hero-section .char", {
      yPercent: 100,
      delay: 1,
      duration: 0.5,
      stagger: 0.05,
    });

    gsap.from("#home .char", {
      scrollTrigger: {
        trigger: "#albums",
        start: "top 80%",
      },
      yPercent: 100,
      delay: 1,
      duration: 0.5,
      stagger: 0.05,
    });

  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Error trying to play video:", error);
      });
    }
  }, []);


  return (
    <>
      <Helmet>
        <title>Shaderah’s Artistic Journey | Music
          Label | Pop & R&B</title>
        <meta name="description" content="Explore Shaderah’s Artistic Journey, a music label
          for pop & R&B that features passionate music
          storytelling and healing songs." />
        <link rel="canonical" href="https://tertiusahmanuph.co/home" />
      </Helmet>

      <main id="home">
        <section id="hero-section" className="section default-header section-home-header">
          <div className="background-media overlay">
            <figure className="overlay">
              <div className="styled-video playpauze overlay">
                <video className="overlay hero-video" preload="meta" poster="" src={heroVedio} ref={videoRef} loop muted webkit-playsInline playsInline></video>
              </div>
            </figure>
          </div>
        </section>

        <section id="albums" className="pt-5">
          <div className="pt-5">
            <div className="album-txt-wrapper">
              <h2 className="text-center" ref={albums}>
                Albums
              </h2>
            </div>

            <p className="text-center font">
              Single and all music albums released
            </p>

            <Container fluid>
              <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="6">
                  <div className="wvc-column-container wvc-column-bg-default wvc-column-bg-effect-none wvc-font-inherit wvc-column-font-inherit wvc-column-style-none wvc-border-color-none wvc-background-color-default">
                    <div className="wvc-column-inner">
                      <div className="wvc-column-wrapper wpb_wrapper">
                        <div className="wvc-album-disc wvc-album-disc-align-left wvc-album-disc-cd wvc-album-disc-worn-border-yes wvc-album-disc-rotate-hover-stop wvc-element wvc-album-disc-has-link">
                          <span className="wvc-album-disc-link-mask internal-link"></span>
                          <div className="wvc-album-disc-cover-container">
                            <div
                              className="wvc-album-disc-disc-container wow wvc-album-disc-reveal animated"
                              style={{
                                transitionDelay: "0.4s",
                                visibility: "visible",
                              }}>
                              <div
                                className="wvc-album-disc-disc-inner"
                                style={{ animationDuration: "3.5s" }}>
                                <img
                                  decoding="async"
                                  className="wvc-album-disc-disc-img"
                                  src={albumOne}
                                  alt="album"
                                  loading="lazy"
                                />
                              </div>
                              <div className="wvc-album-disc-disc-hole"></div>
                            </div>
                          </div>
                          <div className="wvc-album-disc-cover-inner">
                            <img
                              decoding="async"
                              className="wvc-album-disc-cover-img"
                              src={albumOne}
                              alt="cd-cover"
                              title="cd-cover"
                              loading="lazy"
                            />
                            <div className="wvc-album-disc-cover-border"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wvc-text-block wvc-clearfix wvc-element wvc-mobile-text-align- wvc-wow fadeIn"
                        style={{
                          animationDelay: "0.2s",
                          visibility: "visible",
                          animationName: "fadeIn",
                        }}
                      ></div>
                    </div>
                  </div>
                </Col>
                <Col xs="12" sm="12" md="12" lg="12" xl="6" style={{ marginTop: "1.5rem" }}>
                  <div className="head">
                    <ul className="d-flex flex-wrap align-items-center">
                      <li
                        className={`story ${activeTab === "story" ? "active" : ""
                          }`}
                        onClick={() => handleTabChange("story")}>
                        The Story
                      </li>
                      <li
                        className={`concept ${activeTab === "concept" ? "active" : ""
                          }`}
                        onClick={() => handleTabChange("concept")}>
                        The Concept
                      </li>
                    </ul>

                    <div className={activeTab === "story" ? "" : "d-none"} style={{ height: "357px", overflow: "auto" }}>
                      <ol>
                        <li>
                          <span className="size">Shaderah’s Artistic Journey</span> <br />
                          Shaderah Dey-Al’s path to music has been one of passion and determination, leading her to create her own music label. Her journey reflects a deep commitment to crafting music that resonates with listeners on a personal level.
                        </li>
                        <br />
                        <li>
                          <span className="size">Building a Musical Community</span> <br />
                          Shaderah’s music is more than just sound; it’s about fostering a community of fans who connect through shared experiences and the powerful emotions in her work.
                        </li>
                        <li>
                          <span className="size">Kneedle in My Back: A Journey of Pain and Healing</span> <br />
                          This track emerges from a storm of challenges, encapsulating Shaderah's journey through physical and emotional trials. It's a declaration of resilience, exploring her quest to find dignity and accept responsibility amidst the chaos around her.
                        </li>
                        <br />
                        <li>
                          <span className="size">The "Lonely Homes" Album</span> <br />
                          "Lonely Homes" is a heartfelt collection of songs that explore life’s complexities, blending Shaderah’s personal experiences with universal themes of love and solitude.
                        </li>
                      </ol>
                    </div>

                    <div className={activeTab === "concept" ? "" : "d-none"} style={{ height: "357px", overflow: "auto" }}>
                      <ol>
                        <li>
                          <span className="size">Exclusive Fan Experiences</span> <br />
                          Shaderah offers exclusive experiences for her dedicated fans, from limited edition items to special content, deepening the connection between the artist and her audience.
                        </li>
                        <br />
                        <li>
                          <span className="size">Art Meets Merchandise</span> <br />
                          Shaderah’s merchandise line is an extension of her artistic vision, designed to complement her music and allow fans to carry a piece of her world with them.
                        </li>
                        <br />
                        <li>
                          <span className="size">Kneedle In My Back Interlude</span> <br />
                          While dealing with medical issues the song came about due to how everyone around her was treating the situation & emotional stress that came from being injured physically & mentally. The need for Shaderah to take some space for herself & regain some dignity while accepting responsibility for her own part within the situation.
                        </li>
                        <li>
                          <span className="size">Exploring "Lonely Homes"</span> <br />
                          The album "Lonely Homes" delves into themes of solitude and connection, offering a poignant reflection on the duality of feeling alone amidst others.
                        </li>
                      </ol>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>

          </div>
        </section>

        <section id="testimonials" className="my-5">
          <h2 className="text-center" ref={testimonials}>
            Testimonials
          </h2>

          <p className="text-center font">
            What Our Client Says About Us
          </p>

          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            grabCursor={true}
            loop={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 10000,
              disableOnInteraction: true,
            }}
            breakpoints={{
              360: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1300: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiper">
            <SwiperSlide>
              <div className="tes-warpper">
                <div className="tes-head d-flex align-items-center justify-content-between">
                  <div className="d-flex gap-2">
                    <div className="tes-avatar">J</div>
                    <div>
                      <div className="tes-customer-name">James Potter</div>
                      <div className="tes-customer-role">CEO at InkHouse Media</div>
                    </div>
                  </div>
                  <div className="tes-svg">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-quote-right" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z"></path>
                    </svg>
                  </div>
                </div>
                <div className="tes-customer-description mt-3">
                  The black printed T-shirt I got from Tertius Ahman Uph is more than just apparel. It's a statement. The quality is unmatched, and it’s quickly become my go-to for casual wear!
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="tes-warpper">
                <div className="tes-head d-flex align-items-center justify-content-between">
                  <div className="d-flex gap-2">
                    <div className="tes-avatar">S</div>
                    <div>
                      <div className="tes-customer-name">Sarah Mitchell</div>
                      <div className="tes-customer-role">Fashion Blogger</div>
                    </div>
                  </div>
                  <div className="tes-svg">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-quote-right" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z"></path>
                    </svg>
                  </div>
                </div>
                <div className="tes-customer-description mt-3">
                  "The golden printed T-shirt is a true game-changer! The design is so sleek and eye-catching. Every time I wear it, I get compliments. I can’t wait to add more pieces from this brand to my collection."
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="tes-warpper">
                <div className="tes-head d-flex align-items-center justify-content-between">
                  <div className="d-flex gap-2">
                    <div className="tes-avatar">D</div>
                    <div>
                      <div className="tes-customer-name">Daniel Carter</div>
                      <div className="tes-customer-role">Music Producer at Soundwave Studios</div>
                    </div>
                  </div>
                  <div className="tes-svg">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-quote-right" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z"></path>
                    </svg>
                  </div>
                </div>
                <div className="tes-customer-description mt-3">
                  Wearing the white printed T-shirt feels like walking in a dream. The fabric is super comfortable, and the minimalist design speaks volumes. Tertius Ahman Uph knows how to deliver quality!
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="tes-warpper">
                <div className="tes-head d-flex align-items-center justify-content-between">
                  <div className="d-flex gap-2">
                    <div className="tes-avatar">E</div>
                    <div>
                      <div className="tes-customer-name">Emily Larson</div>
                      <div className="tes-customer-role">Co-Founder at ArtScape</div>
                    </div>
                  </div>
                  <div className="tes-svg">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-quote-right" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z"></path>
                    </svg>
                  </div>
                </div>
                <div className="tes-customer-description mt-3">
                  ‘3am on Jan 31st’ is not just a track; it’s an
                  experience! Tertius Ahman Uph’s music speaks to the soul. It’s been on repeat ever since I
                  discovered it!
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="tes-warpper">
                <div className="tes-head d-flex align-items-center justify-content-between">
                  <div className="d-flex gap-2">
                    <div className="tes-avatar">M</div>
                    <div>
                      <div className="tes-customer-name">Marcus Reid</div>
                      <div className="tes-customer-role">Creative Director at Imprint Agency</div>
                    </div>
                  </div>
                  <div className="tes-svg">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-quote-right" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z"></path>
                    </svg>
                  </div>
                </div>
                <div className="tes-customer-description mt-3">
                  ‘Get This Way’ is such a powerful piece. It’s beautifully composed and resonates on a deep emotional level. Tertius Ahman Uph never disappoints when it comes to authenticity in sound.
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="tes-warpper">
                <div className="tes-head d-flex align-items-center justify-content-between">
                  <div className="d-flex gap-2">
                    <div className="tes-avatar">O</div>
                    <div>
                      <div className="tes-customer-name">Olivia Hayes</div>
                      <div className="tes-customer-role">Singer-Songwriter</div>
                    </div>
                  </div>
                  <div className="tes-svg">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-quote-right" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z"></path>
                    </svg>
                  </div>
                </div>
                <div className="tes-customer-description mt-3">
                  The interlude ‘Kneedle In My Back’ hits hard. It’s raw, emotional, and a perfect lead into the rest of the album. Tertius Ahman Uph’s creativity shines through every beat!
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="tes-warpper">
                <div className="tes-head d-flex align-items-center justify-content-between">
                  <div className="d-flex gap-2">
                    <div className="tes-avatar">L</div>
                    <div>
                      <div className="tes-customer-name">Liam Carter</div>
                      <div className="tes-customer-role">Owner at Timeless Apparel</div>
                    </div>
                  </div>
                  <div className="tes-svg">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-quote-right" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z"></path>
                    </svg>
                  </div>
                </div>
                <div className="tes-customer-description mt-3">
                  Tertius Ahman Uph’s printed T-shirts have quickly become a favorite in my wardrobe. The white one, in particular, is simple yet stylish. Perfect for both casual and dressed-up looks!
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="tes-warpper">
                <div className="tes-head d-flex align-items-center justify-content-between">
                  <div className="d-flex gap-2">
                    <div className="tes-avatar">N</div>
                    <div>
                      <div className="tes-customer-name">Natalie Simmons</div>
                      <div className="tes-customer-role">Event Coordinator at Vibe City Events</div>
                    </div>
                  </div>
                  <div className="tes-svg">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-quote-right" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z"></path>
                    </svg>
                  </div>
                </div>
                <div className="tes-customer-description mt-3">
                  Lonely Homes’ is a hauntingly beautiful track. It’s on repeat in my playlist, and I’m constantly amazed at the depth and emotion Tertius Ahman Uph brings to their work.
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="tes-warpper">
                <div className="tes-head d-flex align-items-center justify-content-between">
                  <div className="d-flex gap-2">
                    <div className="tes-avatar">R</div>
                    <div>
                      <div className="tes-customer-name">Richard Moore</div>
                      <div className="tes-customer-role">Owner at Moore Studio Designs</div>
                    </div>
                  </div>
                  <div className="tes-svg">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-quote-right" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z"></path>
                    </svg>
                  </div>
                </div>
                <div className="tes-customer-description mt-3">
                  The black printed T-shirt from Tertius Ahman Uph is a perfect blend of style and comfort. The bold design makes a great statement, and the fabric quality is top-notch!
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="tes-warpper">
                <div className="tes-head d-flex align-items-center justify-content-between">
                  <div className="d-flex gap-2">
                    <div className="tes-avatar">S</div>
                    <div>
                      <div className="tes-customer-name">Sophia Martinez</div>
                      <div className="tes-customer-role">Head of Marketing at UrbanWave</div>
                    </div>
                  </div>
                  <div className="tes-svg">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-quote-right" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z"></path>
                    </svg>
                  </div>
                </div>
                <div className="tes-customer-description mt-3">
                  I’ve been hooked on ‘Get This Way’ since the first listen. It’s a track that captures emotions so vividly, and I find myself playing it on loop. Tertius Ahman Uph’s music is truly one-of-a-kind!
                </div>
              </div>
            </SwiperSlide>

          </Swiper>
        </section>
      </main>
    </>
  );
};

export default Home;
