import { useState, useEffect, useRef } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { dispatchCartUpdatedEvent } from '../../CartEvent/CartEvent.js';
import axios from "axios";
import "./cart.css";

const Cart = ({ showCart, closeCart }) => {
  const cartRef = useRef(null);

  const [cartItems, setCartItems] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState('');

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedCartItems =
        JSON.parse(localStorage.getItem("shopify_cart_id")) || [];
      setCartItems(updatedCartItems);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);


  useEffect(() => {
    function handleClickOutsideCart(event) {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        closeCart();
      }
    }

    if (showCart) {
      document.addEventListener("mousedown", handleClickOutsideCart);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideCart);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideCart);
    };
  }, [showCart, closeCart]);

  useEffect(() => {
    const fetchCheckout = async () => {
      let cartId = JSON.parse(localStorage.getItem('shopify_cart_id'));
      if (cartId) {
        try {
          const fetchCartItems = await axios.post(
            'https://1cd11f-e1.myshopify.com/api/2023-07/graphql.json',
            {
              query: `
                query($cartId: ID!) {
                  cart(id: $cartId) {
                    id
                    createdAt
                    updatedAt
                    lines(first: 100) {
                      edges {
                        node {
                          id
                          quantity
                          merchandise {
                            ... on ProductVariant {
                              id
                              title
                              image {
                                url
                              }
                            }
                          }
                          attributes {
                            key
                            value
                          }
                        }
                      }
                    }
                    cost {
                      totalAmount {
                        amount
                        currencyCode
                      }
                      subtotalAmount {
                        amount
                        currencyCode
                      }
                    }
                  }
                }
              `,
              variables: {
                cartId: cartId
              }
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': 'fcfc23a617e5f957bd56a60f1b68191b',
              },
            }
          );

          const cart = fetchCartItems.data.data.cart;
          setCartItems(cart);

          const createCheckoutResponse = await axios.post(
            'https://1cd11f-e1.myshopify.com/api/2023-07/graphql.json',
            {
              query: `
                mutation($input: CheckoutCreateInput!) {
                  checkoutCreate(input: $input) {
                    checkout {
                      id
                      webUrl
                    }
                  }
                }
              `,
              variables: {
                input: {
                  lineItems: cart.lines.edges.map(item => ({
                    variantId: item.node.merchandise.id,
                    quantity: item.node.quantity
                  }))
                }
              }
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': 'fcfc23a617e5f957bd56a60f1b68191b',
              },
            }
          );

          const checkoutUrl = createCheckoutResponse.data.data.checkoutCreate.checkout.webUrl;
          setCheckoutUrl(checkoutUrl);

        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsUpdating(false);
        }
      }
    };

    fetchCheckout();
  }, [cartItems]);

  const handleIncrease = async (lineId, currentQuantity) => {
    setIsUpdating(true);
    try {
      const updatedCart = await axios.post(
        'https://1cd11f-e1.myshopify.com/api/2023-07/graphql.json',
        {
          query: `mutation {
            cartLinesUpdate(cartId: "${cartItems.id}", lines: {
              id: "${lineId}",
              quantity: ${currentQuantity + 1}
            }) {
              cart {
                id
                lines(first: 10) {
                  edges {
                    node {
                      id
                      quantity
                    }
                  }
                }
              }
            }
          }`
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Shopify-Storefront-Access-Token': 'fcfc23a617e5f957bd56a60f1b68191b',
          },
        }
      );

      setCartItems(updatedCart.data.data.cart);

    } catch (error) {
      console.error('Error increasing item quantity:', error);
    } finally {
      setIsUpdating(false);
    }
    dispatchCartUpdatedEvent();
  };

  const handleDecrease = async (lineId, currentQuantity) => {
    setIsUpdating(true);
    if (currentQuantity === 1) {
      await handleRemove(lineId);
    } else {
      try {
        const updatedCart = await axios.post(
          'https://1cd11f-e1.myshopify.com/api/2023-07/graphql.json',
          {
            query: `mutation {
              cartLinesUpdate(cartId: "${cartItems.id}", lines: {
                id: "${lineId}",
                quantity: ${currentQuantity - 1}
              }) {
                cart {
                  id
                  lines(first: 10) {
                    edges {
                      node {
                        id
                        quantity
                      }
                    }
                  }
                }
              }
            }`
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Shopify-Storefront-Access-Token': 'fcfc23a617e5f957bd56a60f1b68191b',
            },
          }
        );

        setCartItems(updatedCart.data.data.cart);
      } catch (error) {
        console.error('Error decreasing item quantity:', error);
      } finally {
        setIsUpdating(false);
      }
      dispatchCartUpdatedEvent();
    }
  };

  const handleRemove = async (lineId) => {
    try {
      const updatedCart = await axios.post(
        'https://1cd11f-e1.myshopify.com/api/2023-07/graphql.json',
        {
          query: `mutation {
            cartLinesRemove(cartId: "${cartItems.id}", lineIds: ["${lineId}"]) {
              cart {
                id
                lines(first: 10) {
                  edges {
                    node {
                      id
                      quantity
                    }
                  }
                }
              }
            }
          }`
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Shopify-Storefront-Access-Token': 'fcfc23a617e5f957bd56a60f1b68191b',
          },
        }
      );

      setCartItems(updatedCart.data.data.cart);
    } catch (error) {
      console.error('Error removing item:', error);
    }
    dispatchCartUpdatedEvent();
  };

  const totalQuantity = cartItems?.lines?.edges.reduce((total, edge) => {
    return total + (edge.node.quantity || 0);
  }, 0) || 0;

  return (
    <>
      <div className={`cart-container ${showCart ? "right-0" : "right-full"}`} ref={cartRef}>
        <div className="grid">
          <div className="vh-100 d-flex flex-column">
            <div className="border-bottom h-18 border-dark d-flex align-items-center justify-content-between">
              <button className="antialiased" onClick={closeCart}>Close</button>
              {totalQuantity > 0 ? (
                <button className="antialiased">Cart ({totalQuantity})</button>
              ) : (
                <button className="antialiased d-none">Cart (0)</button>
              )}
            </div>
            <section className="overflow-y-scroll scrollbar-thin">

              <ul className="d-flex flex-column">
                {cartItems?.lines?.edges?.map((cartItem, index) => (
                  <SkeletonTheme key={index}
                    baseColor="var(--dark-beige)"
                    highlightColor="var(--middle-beige)">
                    <li className="d-flex justify-content-between antialiased border-bottom border-dark">
                      <div className="w-50" style={{ marginRight: "1.5rem" }}>
                        {isUpdating ? (
                          <Skeleton width={151.6} height={151.6} />
                        ) : (
                          <img src={cartItem.node.merchandise.image?.url} alt="cart img" className="cart-img" />
                        )}
                      </div>
                      <div className="d-flex justify-content-between w-50">
                        <div className="grid gap-2">
                          <h3 className="whitespace-pre-wrap w-100">
                            {isUpdating ? (
                              <Skeleton width={120} />
                            ) : (
                              <span className="whitespace-pre-wra">{cartItem.node.merchandise.title}</span>
                            )}
                          </h3>
                          <div className="d-flex align-items-center" style={{ marginTop: "-30px", width: "120px" }}>
                            <label className="sr-only">Quantity, {cartItem.node.quantity}</label>
                            <div className="w-100 h-25 d-flex align-items-center justify-content-between border border-dark px-2" style={{ borderRadius: "9999px" }}>
                              <button
                                type="button"
                                className="btn-decrease"
                                onClick={() => handleDecrease(cartItem.node.id, cartItem.node.quantity)}
                                disabled={isUpdating}>
                                -
                              </button>
                              <div className="h-100 antialiased d-flex align-items-center justify-content-center cart-value">
                                {isUpdating ? <Skeleton width={20} /> : cartItem.node.quantity}
                              </div>
                              <button
                                type="button"
                                className="btn-increase"
                                onClick={() => handleIncrease(cartItem.node.id, cartItem.node.quantity)}
                                disabled={isUpdating}>
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </SkeletonTheme>
                ))}
              </ul>

            </section>
            {cartItems?.cost?.totalAmount?.amount > 0 && (
              <section>
                <div className="d-flex align-items-center justify-content-between" style={{ padding: "1.5rem" }}>
                  <dt className="whitespace-pre-wrap antialiased">Subtotal</dt>
                  <dd data-test="subtotal" className="whitespace-pre-wrap antialiased">
                    <div>${cartItems.cost.subtotalAmount.amount}</div>
                  </dd>
                </div>
                <div className="d-flex flex-column">
                  <a href={checkoutUrl} target="_blank" rel="noopener noreferrer">
                    <div className="w-100 position-relative border-top border-dark text-start d-flex align-items-center justify-content-between antialiased btn-checkout">
                      Checkout
                    </div>
                  </a>
                </div>
              </section>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
