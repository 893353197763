import { Container, Row, Col } from "react-bootstrap";
import ProductCard from "../../ProductCard";
import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import axios from "axios";
import "./shop.css";

const Shop = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          `https://1cd11f-e1.myshopify.com/api/2023-07/graphql.json`,
          {
            query: `{
              products(first: 10) {
                edges {
                  node {
                    id
                    title
                    description
                    images(first: 4) {
                      edges {
                        node {
                          src
                          altText
                        }
                      }
                    }
                    variants(first: 1) { 
                      edges { 
                        node { 
                          priceV2 { 
                            amount 
                          } 
                        } 
                      } 
                    }
                  }
                }
              }
            }`,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Shopify-Storefront-Access-Token': 'fcfc23a617e5f957bd56a60f1b68191b',
            },
          }
        );
        setProducts(response.data.data.products.edges);
        setIsLoading(true);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const extractId = (gid) => gid.split("/").pop();

  return (
    <>
      <Helmet>
        <title>Tertius AhmaNuph T Shirt | Musicinspired clothing</title>
        <meta name="description" content="Shop our Tertius AhmaNuph T Shirt and discover exclusive artist merchandise and music-themed accessories for true fans." />
        <link rel="canonical" href="https://tertiusahmanuph.co/shop-all" />
      </Helmet>

      <main id="shop">
        <section>
          <h1>Shop All</h1>

          <Container fluid>
            <Row>
              {isLoading ? (
                <>
                  {products.map((product) => {
                    const images = product.node.images.edges.map((img) => img.node.src);

                    return (
                      <ProductCard
                        key={extractId(product.node.id)}
                        productUrl={`/products/${extractId(product.node.id)}`}
                        title={product.node.title}
                        price={"$" + product.node.variants.edges[0]?.node.priceV2.amount}
                        image={images}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  {Array(products.length || 7).fill(0).map((_, index) => (
                    <Col sm={12} md={12} lg={3} key={index}>
                      <SkeletonTheme
                        baseColor="var(--dark-beige)"
                        highlightColor="var(--middle-beige)"
                        className="yt-videos-wrapper">
                        <div className="product-card">
                          <Skeleton height={345} />
                        </div>
                        <h2 className="yt-title">
                          <Skeleton />
                        </h2>
                        <h3 className="yt-description">
                          <Skeleton width={100} />
                        </h3>
                      </SkeletonTheme>
                    </Col>
                  ))}
                </>
              )}
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
};

export default Shop;
