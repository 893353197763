import "./loader.css";
import { useLocation } from "react-router-dom";

const Loader = () => {
  const { pathname } = useLocation();

  return (
    <div className={`loading-container ${pathname !== "/room" ? "d-none" : ""}`}>
      <div className="loading-screen">
        <div className="loading-logo">
          <div className="iconmark">
            <svg version="1.1" viewBox="0 0 2048 917" width="200" xmlns="http://www.w3.org/2000/svg">
              <path transform="translate(1263,455)" d="m0 0 8 1 24 9 19 9 21 13 12 9 10 9 8 9 9 14 8 17 4 15 2 12 1 20-2 21-5 25-7 20-13 28-11 21-9 15-16 25-10 14-6 9-2 10v5l5 3h7l3-3-1-5-6-1h-4l3-5 3-2h33l10 2h11l12 2h9l8-8 4-2 5 1 1 2v8l-14 26-15 23-9 13-6 7-9-3-10-3-23-3h-23l-15 4-14 7-9 7-12 13-7 5-2-1 1-7 12-39 11-29 12-30 13-30 14-29 3-7-1-5-9-6-11-6-13-4-4-1h-21l-18 6-12 6-6 4-1 2-1 9-2 60-3 20-5 18-10 19-8 11-9 10-8 8-16 11-12 5-16 4-7 1h-27l-20-3-36-9-15-3h-13l-11 2-9 3h-8v-5l10-13 17-17 14-9 13-6 7-1 42 5 14 1h15l11-2 9-5 8-9 2-6v-10l-4-13-7-10-7-7-15-8-17-6-7-1-23-1-1-5 6-8 8-7 8-4 11-2h11l10 4 7 1-6-16-6-11-5-6-11-8-12-5-10-2-17-1-3-1-1-4 4-7 8-7 2-1h10l18 5 17 7 9 3v-15l-6-16-7-11-10-10-14-8-9-3-16-3-4-3 1-6 10-8 9-4h11l12 3 26 12v-10l2-34 3-9 9-8 15-9 17-10 10-6 9-4 18-13 40-24zm-15 16-12 6-17 10-32 16 3 1 24-7 31-15 7-8v-3zm83 28 2 5 8 9 8 10 11 15 7 12 5 4-1-9-8-17-8-12-11-11-12-6zm-116 19-1 2-1 9v57l5 5 10 7 14 7 16 5 6 1h20l12-3 11-6 13-10 1-3-5-12-8-13-11-12-9-8-14-9-16-8-16-6-17-3zm-23 4-9 4-6 4-1 4-1 207-2 23-5 20-5 12-6 10-11 12-5 8 1 1h12l8-3 12-8 8-8 6-10 4-14 2-14 1-15 2-71 1-50 1-108-1-4zm132 85-11 6-12 7-11 4-11 2h-22l-17-3-23-11h-2l-1 2-1 18v45l7-1 17-8 16-4 7-1h9l24 5 12 5 12 6 3-4 7-26 2-17-1-19-2-6zm18 172-3 1-1 5 3 3 7-1 4-4v-3zm16 0-5 4v2l-3 1v1h10l2-2-1-6z" fill="#EBA412" />
              <path transform="translate(1206,10)" d="m0 0 4 1 1 3 1 61 1 34 2 2 8-4 14-12 16-15 8-6 5-1-1 7-8 11-14 17-9 11-5 7h23l59 2 13 1 7 3-1 6-3 3-11 4-21 2-16 1-45 1 10 13 8 10 7 10-1 3-9 7-10 13-4 7-1 4-1 28v119l3 12 4 5 9 3 12 3v4l-14 14-14 11-19 14-5 3-6-2-12-11-8-9-7-12-1-4v-44l1-71-1-57-3-8-12-16-3-3-7-3-4-6 15-14 9-12 5-10-53-2-20-2-15-3-1 3-1 18h57l2 4v16l1 1v7h-2l-2 7-2 1-45 1-8 1-1 1-1 78v83l1 4 15 6 6 4 5 5 6-1 8-6 2-1h6l1 1v6l-6 9-7 8-10 8-9 7-11 10-8 7-2 1-11-7-18-13-18-11-18-10-4-4 1-6 6-12 3-11 2-153v-4l-9-2-9-4 1-7 7-11 14-18 8-10 11-12 13-17 10-13 7-8 5-2 2 3v30l2 2 6-3 6-1 46-1 28-1 3-1-10-14-12-18-8-16v-4l6 1 12 9 11 11 10 8 13 7v-23l2-50 2-21zm-171 188-1 4h5v-4zm167 7-1 4 1 2h3v-6zm-1 8 1 4zm0 10-1 22 3 1 1-4-1-8-1-11zm0 24-1 1v8l2 9 5 1 2-2-1-14-3-3z" fill="#FBB81F" />
              <path transform="translate(371,461)" d="m0 0 5 1 1 4-9 12-6 9-4 10-1 6-1 21-1 89 9-3 13-10 17-12 13-10 14-11 12-9h6l10 9 9 11 7 11 4 10 3 20v73l-1 70-1 19-3 14-9 17-12 16-10 11-10 8-15 10-18 10-21 9-34 12-16 4-5-1-1-5 5-6 12-6 29-12 21-11 11-8 8-8 7-12 4-15 1-9 2-135v-33l-4-10-6-8-7-7-7 1-18 13-17 12h-2l-3 123v9l2 10 8 9 1 3v7l-9 12-7 7-10 8-13 11-5 3-6-3-12-11-11-9-7-6-5-7 1-5 9-13 2-5 1-8 1-21 1-40 1-67v-38l-21-1-5-3-3-9v-8l3-9 3-6 3-2h7l12 6 5 3-1-5-1-41-2-17-4-13-8-16-1-7 8 1 16 8 10 7 7 1 13-11 14-10 16-7z" fill="#DD920C" />
              <path transform="translate(1712,558)" d="m0 0 5 2 8 8 7 8 8 11 5 10 3 14 4 1 6-4 29-29 8-7 10-9 6-2 8 3 12 7 10 9 9 10 8 17 6 21 1 5 1 15v45l-1 80-3 7-10 9-15 10-18 13-11 9-13 12-6 2-13-5-27-13-3-1-1 4v27l4 17 5 13 11 21 1 8-3 4-3 1h-7l-12-5-11-7-12-11-10-10-6 3-12 10-8 5-9 3-2-2 1-6 5-11 5-7 11-6-1-5-4-7-1-9v-37l-2-8-12-1h-8l-6-2-2-4v-8l4-8 5-4 16-5 5-3 1-7 1-41v-96l-3-10-7-3h-13l-3-1-1-3 3-6 13-11 8-7 10-10 11-9zm70 53-8 3-13 10-10 9-4 6-1 86v37l5 4 21 9 20 7 11 6 5 4 2-1-1-11v-79l1-36v-26l-5-9-9-10-8-7z" fill="#DC920C" />
              <path transform="translate(460,39)" d="m0 0 23 2 24 4 40 10 27 7 7 1h8l10-4 12-7 11-9 4 1-2 12-7 18-8 11-5 5-9 7-11 5-15 4-21 2-1 31-1 151-1 3-5 1h-8l-1-3 2-108v-77l-10-3-11-1-2 7-1 72-2 20-3 13-7 16-11 16-11 13-7 9 1 4 15 8 24 7 17 5 6 4 9 3h9l8-3 5-5-1-10 19-10 9-7 6-5 7-8 11-11 7-4-1 8-6 13-12 22-8 12-11 12-10 9-13 8-14 6-23 5-11 1h-12l-21-2-21-5-15-6-13-7-13-10-15-15-9-13-9-17-4-13-4-19-2-13 1-11 5-22 8-20 11-19 12-16 11-13 14-14 5-9h-25l-19 5-13 7-7 7-7 8-5 4-5 1-1-1 1-9 9-19 10-16 11-11 10-7 16-8 13-4h9l16 4 25 9 15 5 13 5 19 6 21 4 21 2 6-1-10-5-12-7-36-12-30-7-7-1-30-2-5-1 2-2 7-2zm1 51-8 6-10 11-8 13-6 12-6 17-4 20-1 9v17l4 18 6 15 8 14 10 13 7 6 4-2 6-12 3-16 1-23v-114l-1-4zm14 30-1 12v31l2 12h6v-16l-3-31-2-7zm-80 47-5 8-6 18-5 13-1 9 5-1 4-2 4-13 4-21 1-11z" fill="#FBB81D" />
              <path transform="translate(1263,455)" d="m0 0 8 1 24 9 19 9 21 13 12 9 10 9 8 9 9 14 8 17 4 15 2 12 1 20-2 21-5 25-2 6h-1l-2-9v-7l-7-1-6-3 1-2-10 2-6-1-1-3-9-1h-4l-1-10-4-6-5-16-5-12-8-12-8-9-5-3v1l-1 2-7-7-15-10-16-8-15-6-20-4-10-1-1 11-1 55-3-1-3-7-4-1-2 1-1 9-1 15h-1v-78l-12 3-8 5-1 4-1 207-2 2-4-4 1-3v-7h-2l-5-9-4-3h-8l1 6-2 1-2-1v-2h-6l-2 2-3 1v1h-10l-1 8-4 5-6 2-2 4-5-1-9-5-25-5-15-1 2-4 9-8 8-4 11-2h11l10 4 7 1-6-16-6-11-5-6-11-8-12-5-10-2-17-1-3-1-1-4 4-7 8-7 2-1h10l18 5 17 7 9 3v-15l-6-16-7-11-10-10-14-8-9-3-16-3-4-3 1-6 10-8 9-4h11l12 3 26 12v-10l2-34 3-9 9-8 15-9 17-10 10-6 9-4 18-13 40-24zm-15 16-12 6-17 10-32 16 3 1 24-7 31-15 7-8v-3zm83 28 2 5 8 9 8 10 11 15 7 12 5 4-1-9-8-17-8-12-11-11-12-6z" fill="#FBB71C" />
              <path transform="translate(100,466)" d="m0 0h7l36 8 27 8 19 9 8-4 16-12 10-6 7-1 1 5-6 9-8 9-6 9-4 11-1 6v48l2 88 1 20 2 14 2 6h10l6-2h6l2 2-1 5-11 12-8 7-18 14-8 6-4 1-6-4-11-13-6-11-3-9v-4l-10 5-16 12-25 17-5 4-8-1-17-8-19-10-26-13-3-2h-3l-2 7-2 13-4 11-3 4h-8l-5-3-1-2v-9l5-15 9-17 12-17 16-24 5-8v-3l-6-4-8-9-10-14-10-19-4-14-1-10v-10l2-15 6-16 10-17 9-11 8-9 14-10 16-8 14-4zm-13 38-15 4-10 4-10 7-10 9-7 11-5 12-2 8v14l3 14 5 10 9 13 12 13 3 3 4-2 10-13 10-15 13-18 10-15 2-5v-5l-5-4-3-1h-15l-16 8-3-1 1-7 8-13 1-3 10-5h17l7 1h13l6-3 8-7 1-5-3-3-19-5-6-1zm66 18-8 7-9 13-8 12-9 12-18 26-12 20 1 2 9 3 26 4h19l8-3 5-5 2-7 1-67-2-16zm-83 126-9 2-6 5-1 7 8 6 15 8 10 9 7 7 12 6h24l12-6 13-12 5-6 2-5v-8l-3-9-4-3-14-1z" fill="#CA7D06" />
              <path transform="translate(548,549)" d="m0 0h6l19 19 3 7 2 17 8-4 20-15 15-9 14-12 5-3 5 2 12 16 8 16 3 8 6-1 12-8 14-9 12-7 8-7 6-9 5 4 9 11 14 14 4-1 10-11 2-1h7l-1 5-16 24-2 6-1 34v69l2 8 4 7 15 3 2 1-1 5-6 7-8 7-9 8-13 9h-8l-6-4-8-8-5-10-2-9v-44l1-12-1-58-3-9-8-10-4-3-9 2-13 9-6 5-2 4-1 32-1 65 4 8 5 6 1 5-7 9-12 13-9 8-7 1-9-6-10-9-9-9-1-4 4-7 4-5 2-8 2-21 2-30v-15l-1-8-1-24-2-10-6-9-5-4-7 1-10 6-13 10-4 6-1 96 3 6 7 9-1 4-4 6-9 10-13 11-5 4-6-1-11-9-13-13-1-2v-7l6-10 1-7 2-73v-30l-3-12-6-7-5-5 1-4 7-8 9-10z" fill="#EBA40F" />
              <path transform="translate(909,555)" d="m0 0 9 2 20 9 12 6 8 6h8l9-1 11 4h8l9-4h5l-1 6-8 13-8 24-1 9-1 47v88l4 10 6 9 6 2 9-3h6l2 2-1 5-7 8-12 11-14 10-12 11-6 5-5-1-5-5-6-5-9-11-6-10-4-16-1-12-7 3-9 7-16 13-14 11-18 14-15 11-6-1-8-8-9-11-9-14-7-16-5-21v-11l5-22 8-19 8-16 7-9 11-13 7-8 8-10 1-3-19-56-1-4v-9l5-3 21-2 9-3 17 4 5-5 3-9zm-47 39v8l6 20 8 22 4 5 21 11 32 16 2-1 1-28v-25l-8-5-26-10-26-9-12-4zm13 73-5 8-5 14-3 12-1 7v17l5 19 4 10 10 16 11 14 5 1 11-10 10-8 11-9 5-7 2-38 1-15-1-3-10-6-27-13-19-9z" fill="#DC920C" />
              <path transform="translate(1348,148)" d="m0 0 6 1 12 11 8 7 6 5 2 4-4 6-5 6-1 3-2 54v43l2 20 2 7 1 11 8-4 10-7 15-9 7-6 1-5v-101l-5-12-1-4 3-5 9-7 9-8 7-8 3-1 10 7 9 8 8 7 3 4-1 5-7 6-1 2-1 42-1 93 13-2h8l2 2v5l-13 13-18 13-4 6v2l31-1 3-1 1-2 2-13 2-3 5 1 5 5 11 14 9 8 1 5-7 13-8 10-6 5-9 3-9 1-106 4-17 1-9 2-6 12-4 1-7-4-7-9-8-10-3-6 2-6 7-11 9-9 10-5 96-4-4-9-8-10-6-13-3-10v-3l-11 6-10 7-14 10-17 12-11 7-4 1-4-8-9-10-6-12-3-14v-123l-4-5-9-5-2-4 5-6 16-12 10-9z" fill="#ECA40F" />
              <path transform="translate(1965,468)" d="m0 0h7l1 2-6 10-8 13-2 6-1 7-1 58-1 21 10-6 16-13 17-12 15-10 7-1 7 6 7 8 6 7 4 8 1 5v29l-1 24-3 9 2 10 3 9-1 7v7l-5 10v7l4 8 1 12-4 13-8 16-8 10-7 7-10 8-15 10-16 8-19 7-16 4h-9l-2-2 1-4 8-5 26-11 15-9 8-7 6-9 3-9 3-23 1-80v-27l-3-14-7-10-4-2-10 5-16 11-5 4-1 2-1 11-1 88 4 8 5 6-1 7-12 14-11 9-9 6-7-1-14-13-8-7-2-3 1-8 6-11 2-8 1-53 1-19-1-50-1-3-16-2-3-3-1-7 3-9 3-5 5-3 10-3 1-6v-11l-2-21-5-14-1-2v-9l2-3 7 1 8 6 8 5 5-1 9-6 10-5 13-8zm-52 164 2 33 1 9 4-4 1-4-1-21-3-11z" fill="#EBA30F" />
              <path transform="translate(731,152)" d="m0 0 4 2 9 9 7 8 12 12 13 12 11 12 17 17 1 3-5 6-8 7-18 13-13 10-18 13-13 10-11 9-12 11-1 2v45l4 15v5h8l9-3 7 1 4 5 2 5v3l9-1 25-13 16-10 5 1 1 7-7 8-18 13-11 7-25 17-14 10-8 5-6-2-23-11-25-9-17-5-4-2-1-4 9-19 2-8 1-17 1-57 1-87 7-9 9-6 23-12 20-13 12-9zm-24 50-1 2-1 12-1 48v20l1 3 6-2 25-20 11-8 6-5 2-5-7-9-25-25-7-8-4-3z" fill="#EBA30F" />
              <path transform="translate(1482,556)" d="m0 0 7 1 13 10 13 12 2 4-1 5-10 9-1 25v89l2 10 3 5 7 6 6-1 14-8 13-11 2-3v-25l2-76-3-8-6-7 1-6 4-9 6-8 9-6h3l3-6 3-2 9 1 11 9 14 10 2 3-1 7-8 14-1 5v119l1 7 19 4 2 3-1 4-7 8-11 10-9 7-12 9-6-1-10-11-9-13-5-16v-4l-11 6-14 10-16 11-16 12-9 5-10-9-9-12-6-12-3-14v-24l1-26 1-73-2-5-5-2-9-2-2-2 1-5 7-8 13-12 10-8zm86 11-5 1-1 6-1 7 3 3 4 1 5-3 1-10-4-5zm-4 86-3 20-3 27 5-2 6-4v-16l-3-25zm-94 42-1 1v18l2 14 3 7 4 1-1-9-4-30-1-2z" fill="#EBA410" />
              <path transform="translate(1664,114)" d="m0 0h11l5 3 1 2-1 13-5 13-14 19-11 16-8 11-6 9 1 5 10 7 11 7 9 8 2 6v61l-2 8-7 8-11 6-16 8-16 9h-8l-19-5-12-2h-18l-15 4-9 3h-7l-1-5 6-12 14-19 11-16 10-14 3-5-2-4-19-10-12-11-3-7v-42l2-10 3-6 7-7 22-12 12-6 10-9 8 1 31 15 9 4 6-1 13-10 3-6-5-14 1-6 3-5zm-85 64-2 2-2 10v17l3 6 7 6 15 9 5-2 11-16 12-18 2-4-1-5-22-1-15-2-8-2zm27 60-10 11-13 18-10 14-1 5 26 6 15 4 6 1 7-3 3-3 3-16v-18l-4-8-6-5-11-6z" fill="#ECA40F" />
              <path transform="translate(1196,158)" d="m0 0h3l1 41 1 14 1-4v2h3v32l-1 4-3 1v8l2 9 5-1 2-19 2-35v-36l-1-16 6 1 9 6 7 7 13 10 5 2 5-2-3-5 1-3 5 8-1 3-9 7-10 13-4 7-1 4-1 28v119l3 12 4 5 9 3 12 3v4l-14 14-14 11-19 14-5 3-6-2-12-11-8-9-7-12-1-4v-44l1-71-1-57-3-8-12-16-3-3-3-2 5-5 11-9 10-9 12-10zm5 65-1 22 3 1 1-4-1-8-1-11z" fill="#FBB81E" />
              <path transform="translate(1346,656)" d="m0 0h19l4 2 2 3v6l-16 33-10 17-10 16-13 20-10 13-3 6-1 13 5 3h7l3-3-1-5-6-1h-4l3-5 3-2h33l10 2h11l12 2h9l8-8 4-2 5 1 1 2v8l-14 26-15 23-9 13-6 7-9-3-10-3-23-3h-23l-15 4-14 7-9 7-12 13-7 5-2-1 1-7 12-39 11-29 12-30 13-30 14-29 3-7-1-5-9-6-11-6-13-4-7-2-11-1 5-4h36l5 4 14 1 3-7 3-8 5-1 6-4h3v2zm-4 123-3 1-1 5 3 3 7-1 4-4v-3zm16 0-5 4v2l-3 1v1h10l2-2-1-6z" fill="#DD920C" />
              <path transform="translate(614,581)" d="m0 0 7 6 4 5 4 8 6 6 1 5 4-3 6-3 3-1v-3l5 1 3-2h8v-2l8-3v3h2l2 2 4-3v3l-10 8-2 4-1 32-1 65 4 8 5 6 1 5-7 9-12 13-9 8-7 1-9-6-10-9-9-9-1-4 4-7 4-5 2-8 2-21 2-30v-15l-1-8-1-24-2-10-6-9-5-4-7 1-10 6-13 10-4 6-1 96 3 6 7 9-1 4-4 6-9 10-13 11-5 4-6-1-11-9-13-13-1-2v-7l6-10 1-7 1-31h2l1-24 3-9v-7l1-1v-8-4l4-12 2-4 2-1 2-4 8-4 3-3 5-1h8l6-2h8l16-8 5-5z" fill="#DD930D" />
              <path transform="translate(909,555)" d="m0 0 9 2 20 9 12 6 8 6h8l9-1 11 4h8l9-4h5l-1 6-8 13-8 24-1 9-1 47-1 27-2-3v-3l-10-3-9-1-5-2-8-4-14-3h-6l-6 1-2-1v-2h-3l-1 2-5-3-10-2-4-2-8-1-16-8-5 1-7 16-4 16-2 19-2-1-1-11-1-5-4-1-8 7-4 6-6 11h-2l-2 5h-2l-3 6-5 2-4-1-3 2 2-11 8-19 8-16 7-9 11-13 7-8 8-10 1-3-19-56-1-4v-9l5-3 21-2 9-3 17 4 5-5 3-9zm-47 39v8l6 20 8 22 4 5 21 11 32 16 2-1 1-28v-25l-8-5-26-10-26-9-12-4z" fill="#FBB71C" />
              <path transform="translate(100,466)" d="m0 0h7l36 8 27 8 19 9 8-4 16-12 10-6 7-1 1 5-6 9-8 9-6 9-4 11-1 6v48l2 88v10h-1l-3-31-2-20-3-2v-2h-2l-3-5-3-13-2-1-10 8-10 3-6-3v-59l-2-10h-3l-1-5-6 1-7 8-6 8-10-1-10-2-1-3h-8l-3-3-6 1-9 3-8 4h-2v2l-8 4-3 3-2 7 4 1-4 1-2-2 1-7 8-13 5-5 7-4h17l7 1h13l6-3 7-6 1-5-2-2-19-5-6-1h-24l-15 4-10 4-10 7-9 8-7 11-5 12-2 8v14l1 11-7-3v7l-3 6h-2l-4-11-2-15v-10l2-15 6-16 10-17 9-11 8-9 14-10 16-8 14-4z" fill="#DD920C" />
              <path transform="translate(377,215)" d="m0 0 5 3 4 5 6 2 15-3 1-3 5 1v2l6 3 5 5 3 1 1-2v2l3 1 10 16 9 10 5 2 6-10 3-9h2v-2l5-5 4-8 3-3 14-5 6 2 2 3-5 10-10 14-9 11-8 10 1 4 15 8 24 7 17 5 6 4 9 3h9l8-3 5-5-1-10 19-10 9-7 6-5 7-8 11-11 7-4-1 8-6 13-12 22-8 12-11 12-10 9-13 8-14 6-23 5-11 1h-12l-21-2-21-5-15-6-13-7-13-10-15-15-9-13-9-17-4-13-4-19 1-7z" fill="#DC920C" />
              <path transform="translate(382,610)" d="m0 0 2 1v2l-23 16-4 3h-2l-3 123v9l2 10 8 9 1 3v7l-9 12-7 7-10 8-13 11-5 3-6-3-12-11-11-9-7-6-5-7 1-5 9-13 2-5 5-1-2-3 2-40 1-94 2-2 3 1v-2l11-5 6-2 9-4 8 1h3l3 4h9l3 1 4-8 5-2 2-2h6l1-2 7-4z" fill="#CA7D07" />
              <path transform="translate(871,115)" d="m0 0 6 1 14 13 9 14 4 1 15-12 13-9 7-5 4-1 5 3 10 10 7 8 4 6-2 6-7 9-10 9-8 5-6-1-6-4-15-12-8-1-5 5-2 4-1 9v94l3 6 9 7 3 3 3 1h9l6-2h7l-2 6-8 10-9 9-14 11-7 6-5-2-14-9-16-10-14-8-4-4v-6l6-13 2-10v-94l-3-6-5-6-8-6-1-3 8-10 11-10 14-11z" fill="#FBB81E" />
              <path transform="translate(1704,686)" d="m0 0h6l11 4 9-1 1 3 4 1 5 5 3 4v3l2 1 1 52 2 5 16 8 28 10 11 6 5 4h1l-1-11v-25l1-3v-34l2-13h4l3-5 3-2h5l9 4 20 1 1 3 6 1 1 3v58l-2 13-9 8-15 10-18 13-11 9-13 12-6 2-13-5-27-13-3-1v-6l11 3 18 7 6 3v2l5 2 2 2 6-3-2-4-7-4h-5l-3-5-6-3-13-10-10-6v-2h-2v-2l-3-1v-3h-2v-2h-2l-5-8 2 6-4-4-7-11-2-5-8-10v-2h-2l-3-5v-4h-2v-2h-2l-8-11-2-10-2-2v-5l2 1 2-7v-8l2-4h2v-2l2-1-3-2z" fill="#CA7D06" />
              <path transform="translate(1206,10)" d="m0 0 4 1 1 3 1 61 1 34 2 2 8-4 14-12 16-15 8-6 5-1-1 7-8 11-14 17-9 11-5 7h23l59 2 13 1 7 3-1 6-3 3-11 4-21 2-16 1-45 1 10 13 8 10 5 11-8 2-12-9-12-11-10-6-4-1 2 16v36l-3 48h-2l-1-10-3-1 1-42-3 1-1 12h-1l-1-19v-41l-10 5-16 15-11 9-6 4-1 2-5-1-4-6 15-14 9-12 3-5 5-4 3-6v-1l-26-2-32-2-31-3h-13l1-4 7-2 5-2 6-1 46-1 28-1 3-1-10-14-12-18-8-16v-4l6 1 12 9 11 11 10 8 13 7v-23l2-50 2-21z" fill="#9433B1" />
              <path transform="translate(731,152)" d="m0 0 4 2 9 9 7 8 12 12 13 12 11 12 17 17 1 3-5 6-8 7-8 4-10 7-4 3h-6l-4 3h-2l-2 5-9 6-5 2-4 5-9 6-3 1v2h-3v2h-2v2l-3 1-5-1-4 3h-4l-3-6-3-4-2-6-7-7-1-3-2-1v2h-2v2l-6 1-2-1v-4l-5-2v-4l-4 1-4-3-5-2-3-5-1-17v-8l-2-6-1-13 7-8 11-7 16-8 20-12 16-12zm-24 50-1 2-1 12-1 48v20l1 3 6-2 25-20 11-8 6-5 2-5-7-9-25-25-7-8-4-3z" fill="#FBB71D" />
              <path transform="translate(1620,770)" d="m0 0 6 3 9 11 6 7 5 5 3 5-1 9-6 9-7 8-8 6-8 2-13 1-121 5-6 1-5 12-3 4-5-2-7-6-7-9-8-11-1-4 3-8 7-10 7-7 11-6 5-1 102-3 28-2 7-2 4-14z" fill="#537303" />
              <path transform="translate(1487,342)" d="m0 0 5 1 5 5 11 14 9 8 1 5-7 13-8 10-6 5-9 3-9 1-106 4-17 1-9 2-6 12-4 1-7-4-7-9-8-10-3-6 2-6 7-11 9-9 10-5 73-3h25l-1-5 5 1 5-1 1 3 31-1 3-1 1-2 2-13z" fill="#678008" />
              <path transform="translate(441,687)" d="m0 0 3 1v3l10-2h3l5 3 3 5v2l4 2 1 5v75l-3 14-9 17-12 16-10 11-10 8-15 10-18 10-21 9-34 12-16 4-5-1-1-5 5-6 12-6 29-12 21-11 11-8 8-8 7-12 4-12 2-10 1-12 1-1 3-34v-5l2-3 2-11 2-8 2-19 4-10 4-6 4-2z" fill="#CA7D06" />
              <path transform="translate(1031,282)" d="m0 0h1l1 7 5-1 6-2 6 3-2 4 5 1v2l6-1 10 5 1 3-1 4 3 3v8l3 6 2 9 3 3 1 9v14 3l2 10 4 3v7l8 2 9-2h3l1 1h7l10-7 2-1h6l1 1v6l-6 9-7 8-10 8-9 7-11 10-8 7-2 1-11-7-18-13-18-11-18-10-4-4 1-6 6-12 4-17 1-6 1-38 1-18 3-3z" fill="#DC910C" />
              <path transform="translate(1712,558)" d="m0 0 5 2 8 8 7 8 8 11 5 10 3 14 4 1 6-4 29-29 8-7 10-9 6-2 8 3 12 7 10 9 9 10 8 17 6 21 1 5 1 15v45l-1 80-3 7-1-3 1-9 1-20v-100l-5-9-9-2-10 5-12-2-9-2-4-5-11-11v-2l-4-2-8-9-6-4-5 1-8 4-4 3h-2l-2 4-9 6-9 1-9-7-7 1-18 6-11 1-4 1-4-4-3-13h-2l-4-8-2-3-8-1-7 3v3l13 4 1 2h-15l-3-1-1-3 3-6 13-11 8-7 10-10 11-9z" fill="#FBB81C" />
              <path transform="translate(1985,574)" d="m0 0 7 2 4 3 5 2 10 8 12 7 9 3 3 5 3 11 3 3 2 8-1 6-2 10 5 16-1 7v7l-5 10v7l4 8 1 12-4 13-8 16-8 10-7 7-10 8-15 10-16 8-19 7-16 4h-9l-2-2 1-4 8-5 26-11 15-9 8-7 6-9 3-9 3-23 1-80v-27l-3-14-7-10-8-5z" fill="#CA7E08" />
              <path transform="translate(57,635)" d="m0 0 3 1v3l83 4 20 2 2 3-2 11h-1l-2-7-5-2-14-1h-71l-9 2-5 4-1 7 9 6 13 7 10 9 7 7 12 6 13 2 10 2 11-2 8-6 6-10 5-6h2v11l2 4 3 3 5 6v4l5 2 12 8h7l10-5h2v-2l13-6h9l-1 5-12 11-17 13-8 6-4 1-6-4-11-13-6-11-3-9v-4l-10 5-16 12-25 17-5 4-8-1-17-8-19-10-26-13-3-2h-3l-2 7-2 13-4 11-3 4h-8l-5-3-1-2v-9l5-15 9-17 12-17 24-36h3z" fill="#C97D07" />
              <path transform="translate(1612,230)" d="m0 0 6 1 6 4 8 2v2l7 4 7 13 3 3v2l4-1v-7h3l9-1v-11h1v55l-2 7-4 5-12 7-3 3-16 8-12 7h-8l-19-5-12-2h-18l-15 4-9 3h-7l-1-5 6-12 14-19 11-16 10-14 3-5-2-4-19-10-12-11-2-3v-3l12 6 8 1h9l8 2-2 2 8 3 5 2 9-4 4-2 2-5 4-4zm-6 8-10 11-13 18-10 14-1 5 26 6 15 4 6 1 7-3 3-3 3-16v-18l-4-8-6-5-11-6z" fill="#DC920C" />
              <path transform="translate(436,563)" d="m0 0 7 1 5 5 10 9 7 11 4 10 3 20v73l-1 70h-1l-1-56-1-5-5-5-2-4-5-2h-2l-5 1h-5l-1-3-2-1-1 4-5 3-4 8-2 6-2 19-3 11-1 8-1 5-1 3-3 34h-1v-11l2-40v-89l-1-20h2l-4-10-10-9v-2l-4-2-7-5-3-2 8-7 6-2 16-2 2-4 5-5 4-8z" fill="#DC920D" />
              <path transform="translate(1674,596)" d="m0 0 8 1 4 5 2 7h2l4 13 1 3 17-2 21-7 6 1 7 6 9-1 6-4 8-7 8-5 5-2 4 1 8 6 7 8 5 5 5 4 1 4 3 1 3 4 12 3 9 1 10-5 9 2 6 10v100h-1l-1-41-6-1-1-2-20-1-9-4-7 1-4 6-4-1-1 15v32l-2 3v-54l1-36v-26l-5-9-9-10-7-6-6-2-8 3-13 10-9 8-4 6-1 22h-1l-1-11-5-3-2 2-4-2v-1h-10l2 2-16 6-5 2-2 8-3 4-6 2-2 21v32l2 10 2 2 2 7v7l-4 13-1 9-3 3-7 2 2 1-1 5h-2v2l-11 3-12-1v7l8 3 12 2 2 2h-16l-6-2-2-4v-8l4-8 5-4 16-5 5-3 1-7 1-41v-96l-3-10-8-5-11-3-1-4 4-3z" fill="#DD920C" />
              <path transform="translate(603,587)" d="m0 0m-1 1m-2 1h2l-1 4-14 10-6 5-3 5-1 96 3 6 7 9-1 4-4 6-9 10-13 11-5 4-6-1-11-9-13-13-1-2v-7l6-10 1-7 1-31h2l1-24 3-9v-7l1-1v-8-4l4-12 2-4 2-1 2-4 8-4 3-3 5-1h8l6-2h8z" fill="#DD930E" />
              <path transform="translate(1048,816)" d="m0 0h9l25 3 31 1 2 5 8 6 7 7 7 8 10 11v2l7-2 13-12 7-8 3-7 9-10 5-1-3 5-9 11-9 10-6 7h-2l-2 5-11 7-16 6-17 3h-27l-20-3-36-9-15-3h-13l-11 2-9 3h-8v-5l10-13 4-4 6-2 7-2 2-4 3-4h2v-2l9-3h3v-2h2v-2h2v-2l11-1z" fill="#C97C06" />
              <path transform="translate(1165,196)" d="m0 0 7 4 5 6 2 6v8l-1 6 2 15v22l-1 6v13l-1 1v12l5 1 12 14 8 9 7 8 8 10 7 8 4 2 1 7h2l3 19 4 6 6 3 16 4v4l-14 14-14 11-19 14-5 3-6-2-12-11-8-9-7-12-1-4v-44l1-71-1-57-3-8-10-14 1-3z" fill="#DC920C" />
              <path transform="translate(1482,556)" d="m0 0 7 1 13 10 13 12 2 4-1 5-10 9-1 25-1 80-2-4h-2l-3-5-12-13-8-7-6-5-1 3 3 19v7l-1-2-2 1v18l2 14 2 6 2 2-2 3-4-1-3-8-2-10v-36h-2v54l3 9 8 13v3l-7-9-6-12-3-14v-24l1-26 1-73-2-5-5-2-9-2-2-2 1-5 7-8 13-12 10-8z" fill="#FBB71C" />
              <path transform="translate(1346,656)" d="m0 0h19l4 2 2 3v6l-16 33-10 17-10 16-13 20-10 13-3 6h-2l-1 3-7 2v2l-6 3h-18l1-7 8-20 11-24 14-30-1-5-9-6-11-6-13-4-7-2-11-1 5-4h36l5 4 14 1 3-7 3-8 5-1 6-4h3v2z" fill="#DC920C" />
              <path transform="translate(1439,149)" d="m0 0 5 2 8 6 14 12 5 6-1 5-7 6-1 2-1 42-1 93 13-2h8l2 2v5l-7 7-1-2-12-1-3 1-2-3-1-55-2-10-4-9h-3l-1 2-4-1-6-2-8-2h-4l-2 4-3 11-1 1-1 30-3 7-15 8-11 9-10 10-3 1v2l-4-1-2-5v-3l-3-1 1-11 4-2 1-2 2 15 8-4 10-7 15-9 7-6 1-5v-101l-5-12-1-4 3-5 9-7 9-8 7-8z" fill="#ECA40F" />
              <path transform="translate(424,83)" d="m0 0h15l15 2-1 3v1l6-2-3-1 3-1 10 2 2 24 2 6v18l-2 64-2 17-1 6h-2v-132l-7 2-10 9-9 12-6 10-9 22-5 24-1 9v17l4 18 6 15 1 3h-6l-5-4-3-3-4-2 1-2-5-1-1 4-15 3-7-3-3-3v-2l-4-2-3 4-2-8 1-11 5-22 8-20 11-19 12-16 11-13 14-14 5-9h-25l-19 5-11 5-1-3 5-4 9-4 14-3zm-29 84-5 8-6 18-5 13-1 9 5-1 4-2 4-13 4-21 1-11z" fill="#FBB81C" />
              <path transform="translate(855,181)" d="m0 0h1l1 22 1 3 3-1 4 4 2 3 1 6 4 2 3 5 9 4 4 3 5 15 2 3v4h2v-73h1l1 86 3 6 9 7 3 3 3 1h9l6-2h7l-2 6-8 10-9 9-14 11-7 6-5-2-14-9-16-10-14-8-4-4v-6l6-13 2-10v-47z" fill="#EAA311" />
              <path transform="translate(743,626)" d="m0 0 5 1 3 3 3 8 2 2 1 64 2 8 4 7 15 3 2 1-1 5-6 7-8 7-9 8-13 9h-8l-6-4-8-8-5-10-1-8 1-1-1-10v-31l8-3-1-19-1-4v-8h3l1-10 8-6 4-6z" fill="#DC910C" />
              <path transform="translate(1425,252)" d="m0 0 14 2 8 4v-5l2 3h3l4 9 2 10 1 55 2 2 5-1 9 2-1 4-8 7-15 11-3 4-9 2h-1v3l-2 1h-7l4-2h3l-4-9-8-10-6-13-3-10v-3l-11 6-10 7-14 10-10 7v-3l3-2 3-5 3-1 2-5 5-4 4-2 3-7 8-7 14-7 2-6 1-30 4-10 1-4z" fill="#DC920C" />
              <path transform="translate(1413,364)" d="m0 0h6l-4 2-3 3-6 2 4 10 6 5 8 3-1 7-3 3-44 4-21 1-3 1 1 2 11 1 4-1h27l26-1 24-3 51-2 5-2-4 4-9 3-9 1-106 4-17 1-9 2-6 12-4 1-7-4-7-9-8-10-3-6 2-6 7-11 9-9 10-5z" fill="#768D10" />
              <path transform="translate(1406,773)" d="m0 0h2l-1 11-10 19-15 23-9 13-6 7-9-3-10-3-23-3h-23l-15 4-14 7-9 7-4 2 2-4 9-10 15-7 15-5 10-2 12-1-4-5-10-7-10-8-7-5-1-3 5-3 27-3h9l3-1 26-1 15-3 10-5 10-4z" fill="#C97C05" />
              <path transform="translate(1154,716)" d="m0 0h6l5 4 4 6 2-2 1 5v7 4 2l3 1v14l-4 20-6 18-7 11-11 12-8 11h-9l-6-3 6-7 6-10-4-1 6-8 1-4v-10l-4-13-7-10-7-7-15-8-17-6-7-1-23-1-1-5 2-3h16l25 5 11 6h3v-3l3-2 6-2 3-6 1-5 4-1h5l1-2 4-1 5-1 2 1v2h3v-6z" fill="#EBA411" />
              <path transform="translate(1574,556)" d="m0 0 9 1 11 9 14 10 2 3-1 7-8 14-5 1-1 5v23l1 9v44l-1 12-2-4-2-5-2-11-1-4-1-5-3-3h-5l-4 6h-3l-1 25-2 5-1 3-1-6 1-1v-16l-1-10v-24l-1-7-2-1-2 4-3-8-3-13-1-15-1-3-4-2-5-6 1-6 4-9 6-8 9-6h3l3-6zm-6 11-5 1-1 6-1 7 3 3 4 1 5-3 1-10-4-5z" fill="#FBB81D" />
              <path transform="translate(1470,667)" d="m0 0 6 4 7 7 8 7 7 8 2 2v3h2l1-4 3 24 4 8 7 6 11-1 5-2h6l4-8h2l1-3h7l2 7 1 9 6 11 1 4 2 1 3 5 1 3 4 2 7 6 3 1 1 5-1 2h-6l-5-3-9-11-7-11-4-14v-4l-11 6-14 10-16 11-16 12-9 5-10-9-3-6-8-13-3-9v-47l1-7h2l1 36 4 16 1 2 3 1 1-3h3l-4-22-3-33-2-11z" fill="#DD930D" />
              <path transform="translate(371,461)" d="m0 0 5 1 1 4-9 12-6 9-4 10-1 6-1 21-1 88h-1l-1-72h-2l-1 3-2-4-2-5-8-5-5 1v2l-5 2-5 4-15-1-3 1-2 9v17l-3 1v4h-2l-1-2-1-41-2-17-4-13-8-16-1-7 8 1 16 8 10 7 7 1 13-11 14-10 16-7z" fill="#ECA410" />
              <path transform="translate(1965,468)" d="m0 0h7l1 2-6 10-8 13-2 6-1 7-1 58-1 21-2-1-1-29-2-7-2-2-8-2-4 6-9 1-1 1-1 9-5 5-7 1h-13l-10-1-2-3 3-9 3-5 5-3 10-3 1-6v-11l-2-21-5-14-1-2v-9l2-3 7 1 8 6 8 5 5-1 9-6 10-5 13-8z" fill="#FBB81D" />
              <path transform="translate(1937,630)" d="m0 0h8l5 3 1 2 1 54 1 12 4 8 5 6-1 7-12 14-11 9-9 6-7-1-14-13-8-7-2-3 1-8 2-3 6-3 2 3 6-4 1-6-4-3 1-9 1-2h2l2-4h2l1-5 1-14v-28l2-4 2 4 5-2 4-3z" fill="#CA7D06" />
              <path transform="translate(1664,114)" d="m0 0h11l5 3 1 2-1 13-5 13-14 19-11 16-8 11-6 9 1 5 10 7 11 7 4 4-7-2-16-8-3-3v-2l-12-1-5 1-3 2 2-4 10-16 2-6 7-1 1-4-18-2-1-1-28-3-1-6-5-2h-9l-4 6-3 4-7 3h-8l-9 3-5 8h-2v41h-2v-42l2-10 3-6 7-7 22-12 12-6 10-9 8 1 31 15 9 4 6-1 13-10 3-6-5-14 1-6 3-5z" fill="#FBB81E" />
              <path transform="translate(100,466)" d="m0 0h7l36 8 27 8 19 9 8-4 16-12 10-6 7-1 1 5-6 9-8 9-6 9-4 11-1 6v48l2 88v10h-1l-2-24-2-80-2-28-4-13-6-9-5-6-9-2-8-4-12-5-9-2-11-4-12-1-18 4-15 2-18 4-3 3-8 1v2l-14 6-9 5-3 3h-2v2l-4 2-7 8-5 8-4 12-1-4 6-16 10-17 9-11 8-9 14-10 16-8 14-4z" fill="#ECA40F" />
              <path transform="translate(1066,234)" d="m0 0 7 1 4 4v2h5l1 3v24l-1 5h2v91l1 4 15 6 6 4 5 6-4-1h-3l-10 2-8-3 1-7-4-2-2-6-2-7 1-1v-14l-1-9-2 2-4-17-2-3v-8l-3-4 1-5-10-5h-6v-2h-5v-3-2l-6-2-4 1-1 2-5-1-1-6-1 5-3 2-1 18-1 38-1 6h-1v-59l1-1 1-41 2-5 2 1 6-4h15l12-6z" fill="#ECA40F" />
              <path transform="translate(722,568)" d="m0 0h9l5 2 5-1 7 6 2 4 4 4 3 4 4-1 3-5 4-1-1 4-7 11-2 6-1 34-1 5-4-3-2-9-7-1-6 5-4 6-5 4-3 5-1 6h-3l2 12 1 19-2 2-7 1 1 31 1 11-3-1v-44l1-12-1-58-3-9-8-10-4-3-9 2h-4l-1-3 2-3h2l2-5 9-7 5-3 13-3z" fill="#EBA40F" />
              <path transform="translate(851,579)" d="m0 0 3 1h-2v6l-2 1 1 5 4 5 6 12 13 31v2h2l4 6 21 11 32 16 2-4 5 2 9 7 10 8-2 1-3-2-14-3h-6l-6 1-2-1v-2h-3l-1 2-5-3-10-2-4-2-8-1-16-8-5 1-7 16-4 16-2 19-2-1-1-11-1-5-4-1-8 7-4 6-6 11h-2l-2 5h-2l-3 6-5 2-4-1-3 2 2-11 8-19 8-16 7-9 11-13 7-8 8-10 1-3-19-56-1-4v-9z" fill="#EBA411" />
              <path transform="translate(1674,596)" d="m0 0 8 1 4 5 2 7h2l4 13 1 3 17-2 21-7 6 1 7 6 9-1 6-4 8-7 8-5 5-2 4 1-2 1v2l6 5-2 1-6-2-8 3-13 10-9 8-4 6-1 22h-1l-1-11-5-3-2 2-4-2v-1h-10l2 2-16 6-5 2-2 8-3 4-6 2-2 21v32l2 10 2 2 2 7v7l-4 13-1 9-3 3-7 2 2 1-1 5h-2v2l-11 3-12-1v7l8 3 12 2 2 2h-16l-6-2-2-4v-8l4-8 5-4 16-5 5-3 1-7 1-41v-96l-3-10-8-5-11-3-1-4 4-3z" fill="#E8A318" />
              <path transform="translate(429,803)" d="m0 0 7 1 9 3h6l5-2h4l-1 5-9 13-12 14-12 10-15 10-18 10-21 9-34 12-16 4-5-1-1-5 5-6 12-6 29-12 21-11 11-8 8-8 6-10 5-3 3-1 4-7 3-7z" fill="#C06E02" />
              <path transform="translate(1348,148)" d="m0 0 6 1 12 11 8 7 6 5 2 4-4 6-5 6-1 3-2 54-2 1v-24l-3-6-2 2-5-7v-2l-8-1 1-3h-11l-5 10h-3l-1-2-2 1-2-19-5-6-9-5-2-4 5-6 16-12 10-9z" fill="#FBB81D" />
              <path transform="translate(1620,770)" d="m0 0 6 3 9 11 6 7 5 5 3 5-1 9-5 2-2 4-3 5-7 3-12 3-19 2h-11l-1-9-8-14-1-3-4-2-1-7-36 1-64 1 2-2 102-3 28-2 7-2 4-14z" fill="#768E11" />
              <path transform="translate(1196,714)" d="m0 0h1l1 12 2 9-1 14h-2l-1 16-1 8v13l1 4h2l1 8-1 6-4 6-2 2-2 5-5 5-8 9-3 7-11 12-9 8-7 1-9-11-8-8-7-8-10-8v-3l-31-1-25-3h-9l-16 1h-5v2h-2v2h-2v2l-12 3v2h-2l-2 4-5 5-5 2-5 1 6-7 10-9 16-9 7-3 7-1 42 5 14 1h15l11-2 9-5 2-2h5l-2 6-8 10-1 1 5 2 9 1 1-3h14l8-3 12-8 7-7 6-10 4-14 2-14 1-15 1-36z" fill="#EBA412" />
              <path transform="translate(1439,149)" d="m0 0 5 2 8 6 14 12 5 6-1 5-7 6-1 2-2 81h-1l-1-47-1-12-2-4v-3l-4-2h-3l1 2-9 2-1 1h-5l-2-2-6-1-3 5-3 4-1 4-1 19h-1l-1-41-5-12-1-4 3-5 9-7 9-8 7-8z" fill="#FBB71E" />
              <path transform="translate(1210,658)" d="m0 0h1l1 19 1 2h14l7-3 9-3 18 1-1 2 8 1v1l-18 1-18 6-12 6-6 4-1 2-1 9-2 60-3 20-5 18-10 19-8 11-9 10-8 8-4 3-2-1 5-5 10-11 11-14 3-4-2-2 2-2 2-5 4-5 2-6v-10h-2l-2-4v-13l3-24h2l-1-4 1-13-2-9-1-14 1-8 1-25 2-9 2-4 4-2 2-2z" fill="#DD930D" />
              <path transform="translate(509,94)" d="m0 0h7l18 5 7 3v13l2 3 1 15 1 95h1v62l-1 3-5 1h-8l-1-3 2-108v-77l-13-4-8-3-3-1z" fill="#EAA414" />
              <path transform="translate(1296,549)" d="m0 0 4 2 6 4 10 12 8 14 6 18 3 7 2 3 1 10 7-1 5 2 3 3 9-1 6-1-1 2 5 3 7 2 2 11v8l-7 18-2-4-2-3-9-1h-13l-9 3v-2l-12 6-2-1 4-19 1-19-2-18-5-2 2-10-9-20-7-11-8-9z" fill="#EBA40F" />
              <path transform="translate(323,786)" d="m0 0 3 1 2 1 4-1 1 6 1 5h7l5-2v-2h5v-2l5 1 4 1-1 5-11 12-11 9-13 11-5 3-6-3-12-11-11-9-7-6-2-5 1-4 6-1 20-8h10z" fill="#C06F04" />
              <path transform="translate(100,466)" d="m0 0h7l36 8 27 8 19 9 8-4 16-12 10-6 7-1 1 5-3 4v-3l-8-1-1 3-9 5-18 12h-3v2l-12-1-14-5h-3v-2l-7-1-13-4-13-3-18 2-7 3-5 3-27 7-10 4-9 2-10 6-11 8-2 1 2-5 9-11 8-9 14-10 16-8 14-4z" fill="#FAB71F" />
              <path transform="translate(1458,599)" d="m0 0 4 2 1 1 2 38 2 18 5 3 5-5 4-6 2-1v2l10 1 3 1v2h2l1 8-1 6 1 5 1 6-3 3 3 4 2-59 1-20h2l-1 94-2-4h-2l-3-5-12-13-8-7-6-5-1 3 3 19v7l-1-2-2 1v18l2 14 2 6 2 2-2 3-4-1-3-8-2-10v-36h-2v54l3 9 8 13v3l-7-9-6-12-3-14v-24l1-26 1-73z" fill="#ECA40F" />
              <path transform="translate(1971,583)" d="m0 0 4 1-6 5-5 5-8 6-1 2-1 11-1 82h-1l-1-6-1-54-3-3-2-1h-8l-2 6-7 5-3-1-1-3-1 8v23l-1 14-2 5-4 4h-2l-1 11 4 3-1 6-5 4h-2l-1 2-2-4-4 2 4-10 2-13 2-5h2v-2l7 1 1-30v-26l-1-9-1-2-4-1 1-8h2v-4h2l1-7 2-4 6-4h7l3 2v2l3 1v2l2 1h6l7-5h2v-2l7-6z" fill="#DE940E" />
              <path transform="translate(1340,716)" d="m0 0h3l-1 6-16 25-10 14-6 9-1 2h-2l-1 3-7 2v2l-6 3h-18l1-7 8-20 8-18 7-7v3h9l11-4 5-1 7-4 5-6z" fill="#CA7E07" />
              <path transform="translate(1490,345)" d="m0 0 4 1 5 5 11 13 7 6 1 5-7 13-8 10-7 4-51 2-24 3-26 1h-27l-6 1-9-1-1-3-3-1 6-1 36-1 51-4 41-3 10-1-3-4-2-4-7-4 1-5 4-3h2v-2h2v-2h2l1-7v-7l-3-1-2-2v-6z" fill="#567505" />
              <path transform="translate(1946,671)" d="m0 0h4l1 4 2 26 4 8 5 6-1 7-12 14-11 9-9 6-7-1-14-13-8-7-1-6 3-3 5 4 5 3 4 4v2l9 3 8 2v-3l4-2 3-1 4-5h2v-2l-6 5-4 1-9-1-2-8-2-2 3-1v-4l5-12 6-11 2-1 5-18z" fill="#CE7803" />
              <path transform="translate(1463,684)" d="m0 0h2l1 36 1 3 1 11 4 10 6 9 3-7 5-12 3-5h2v-3l5 2 7 6 5 1-1-3 2-2h4v2l3 1-4 2 4 4 8 1 4-2h8l-3 5-15 10-19 14-12 8-8-6-4-5-5-9-6-10-2-7v-47z" fill="#C97D06" />
              <path transform="translate(558,708)" d="m0 0h5v2l5 2 6 8 4 7 6 3-2 4-9 10-13 11-5 4-6-1-7-8-13-13-3-5v-6l3-4h16v-2l7-6 1-3z" fill="#C97D07" />
              <path transform="translate(2014,543)" d="m0 0 7 1 15 16 6 10 2 7v29h-2l-1 4v-7l-4-9v-2h-2l-4-7-6-7-3-2-12-1-9-1-10-3-2-1h-6l-18 12-5 4h-3l-1 2-3-2 11-7 16-13 17-12 15-10z" fill="#FBB71E" />
              <path transform="translate(619,236)" d="m0 0h1l-1 8-6 13-12 22-4 1-2 4-6 6h-2l-2 4-3 4-13 1v2l-6 3-11 2-9-1-8-4-8-2v-2l-9-2-21-9 4-1 21 6 8 4 5 3 6 1h8l8-3 5-5-1-10 19-10 9-7 6-5 7-8 11-11z" fill="#EBA410" />
              <path transform="translate(1840,607)" d="m0 0h7l11 4 3 5 4 17 1 15v45l-1 80-3 7-1-3 1-9 1-20v-100l-5-9-9-2-10 5-12-2-9-2-4-5-11-11v-2l-4-2-5-6 7 2 9 11 3-1 4-4 9-1 9-3-1-5 3-3z" fill="#ECA510" />
              <path transform="translate(57,635)" d="m0 0 3 1v3l83 4 20 2 2 3-2 11h-1l-2-7-5-2-14-1h-71l-9 2-5 4-3 10-4 2-2 4-4 4-1 6v7l-5 5h-2v2l-6-2-3-9-4 2 2-4 30-45h3z" fill="#CE7804" />
              <path transform="translate(1230,234)" d="m0 0h1l1 13v107h-2l-2-7-4-2-7-8-11-13-12-14-10-11-2-3-4-1v13h-1v-25h7l9 8 7 11 4 3h7l4-1 4 1 2 1 1-3 5-1 3 14-1-67z" fill="#ECA40F" />
              <path transform="translate(851,275)" d="m0 0 1 2v8l4 2 5 5v2l4 1 4 4 11 6h4l3-11 5-4 3-1h8l1 3h21l8-1 1-9h5l-2 6-8 10-9 9-14 11-7 6-5-2-14-9-16-10-14-8-4-4v-6z" fill="#D59016" />
              <path transform="translate(383,253)" d="m0 0 3 2 5 10 10 14v2h2v2h2l5 5 10 9 20 13v2l8 2 9 1 9 3 9 2 30 2h34l19-4 15-10 3-3h2l-2 4-15 10-16 7-23 5-11 1h-12l-21-2-21-5-15-6-13-7-13-10-15-15-9-13-9-17z" fill="#AE6B05" />
              <path transform="translate(102,530)" d="m0 0 5 1 2 2h8l1 4 10 1 8 1 1 3-8 12-9 12-9 13-9 12h-2v2h-2l-1 2-1-2 4-9 6-8 4-5 2-10v-2h-2v-6l-4-2-5-2h-15l-11 6h-7l1-7 5-5 7-2v-2l10-5 9-3z" fill="#CB7E07" />
              <path transform="translate(2e3 580)" d="m0 0 4 2 12 9 12 6 6 2 3 5 3 11 3 3 2 8-1 6-2 10 5 16-2 5h-1l-2-13-3-1v-2h-2l-8-16-8-4-10-2-3-4-1-14-1-9-2-11z" fill="#DD920C" />
              <path transform="translate(1014,782)" d="m0 0h6l2 2-1 5-7 8-12 11-14 10-12 11-6 5-5-1-5-5-6-5-9-11-6-10 1-3 5 6 8 7 4 6 4-1v-5h2v-3h3l2-4 16-4 11-4 7-3v-5l2-2h3v-2z" fill="#CA7E07" />
              <path transform="translate(159,677)" d="m0 0h2v11l2 4 3 3 5 6v4l5 2 12 8h7l10-5h2v-2l13-6h9l-1 5-12 11-17 13-8 6-4 1-6-4-11-13-6-11-3-9v-4h-6l-6 5h-2v-3h2v-2h2l2-7 1-4 2-6z" fill="#BE6E03" />
              <path transform="translate(1158,628)" d="m0 0 6 1 8 10 3 5v17l-2 11-3 13-5-2-6-7-7-9-8-9-1-3 1-8 2-3h-2l-1-4 5-4 6-4h2z" fill="#ECA40F" />
              <path transform="translate(656,715)" d="m0 0 2 2 2 11 10 1 2 3-1 4-14 15-8 7-7 1-9-6-10-9-9-9-1-4h2l1-3 1 3 6 2 6 1 11-1 7-4 4-2 2-6 3-1z" fill="#C97C06" />
              <path transform="translate(160,545)" d="m0 0h1v53l-1 11v12l1 3-5 5-7 3-17 2h-8l-22-4-10-2-4-1v-2h-2l-1-5 5-5 3-1 12 3 20 3h19l8-3 3-1 3-10 1-59z" fill="#DC9412" />
              <path transform="translate(412,186)" d="m0 0 4 2 3 7 4 18 6 15 1 3h-6l-5-4-3-3-4-2 1-2-5-1-1 4-15 3-7-3-3-3v-2l-4-2 3-2 6-1 2 6 3-6 2-1 5-19 7-6 6 1z" fill="#EBA411" />
              <path transform="translate(25,683)" d="m0 0 2 1 2 9 6 2 9 4v1l-7 1 32 16 27 13 8 3 5-4 2 1-6 5-8-1-17-8-19-10-26-13-3-2h-3l-2 7-2 13-4 11-3 4h-8l-5-3-1-2v-9l5-15 9-17 5-5z" fill="#C27103" />
              <path transform="translate(769,199)" d="m0 0h7l8 8v2h2l9 13 3 7-3 5-8 1-8 3-11 1-6-8-1-1v-18l3-6h2l1-5z" fill="#ECA40F" />
              <path transform="translate(1632,825)" d="m0 0 3 1-5 5-6 3-18 2-121 5-6 1-5 12-3 4-5-2-7-6-4-6-1-4 4 2 6 2 7-1 10-4 17-3 37-3 54-3 34-3z" fill="#A26913" />
              <path transform="translate(1686,802)" d="m0 0 3 1 2 18v32l6 4v2l5 2 2 3v9h5l10 11 4 5 8 7 12 9-2 1-11-7-12-11-10-10-6 3-12 10-8 5-9 3-2-2 1-6 5-11 5-7 11-6-1-5-4-7-1-9v-37z" fill="#CB7E06" />
              <path transform="translate(704,308)" d="m0 0h1l1 45 4 15v5h8l9-3 7 1 4 5 2 5v3l9-1 25-13 16-10 5 1 1 7-7 8-18 13-2-1 10-8 10-7 2-8-5 2-5 4-19 13h-2l-1 2-5 4-5 2-5 4h-3v2l-3 1v-2h-2v-2l-4-2-3-5-5-2-10-1-1-3 5-2h7l6 2 2 5 4 1-2-5-6-4-14-1-4-3-7-3-1-4z" fill="#FAB71D" />
              <path transform="translate(2036,724)" d="m0 0h1l-1 7-7 11-9 10-10 9-18 12-16 8-19 7-6 1v-2l-11 3h-7l1-5 17-6h3v-2l9-4 5-1 15-5 8-5 9-3 11-7 11-10 6-10 6-6h2z" fill="#BF7713" />
              <path transform="translate(1231,126)" d="m0 0h21l59 2 13 1 7 3-1 6-3 3-11 4-21 2-16 1-32 1-15 1-4-7h38v-2l57-6-4-2-7-1-29-2-50-2z" fill="#CAA262" />
              <path transform="translate(1552,601)" d="m0 0h2l2 3 1 15 4 17 2 2 1-2 3 1 1 7v24h-1l-1-15h-2l-2 20-2 18-2 9 7-4h2v6h-4l-6 1-4 5-1-11 1-58 1-31z" fill="#CBA66B" />
              <path transform="translate(491,218)" d="m0 0 7 2 2 3-5 10-10 14-5 6h-8l-4 2-2 5-6 2-5-1 7-14 2-6h2v-2l5-5 4-8 3-3z" fill="#ECA40F" />
              <path transform="translate(138,516)" d="m0 0 9 4 3 5-6 7-6 8-10-1-10-2-1-3h-8l-3-3-6 1-9 3-8 4h-2v2l-8 4-3 3-2 7 4 1-4 1-2-2 1-7 8-13 5-5 7-4h17l7 1h13l6-3 7-6z" fill="#ECA40F" />
              <path transform="translate(433,558)" d="m0 0h6l10 9 6 7-1 2-8-8-3-2v-2h-7l-2 6-2 4-4 7-5 4-1 2-19 4-10 5-6 4-6 3-9 6-9 4-4 2-2 3h-2v-5l9-3 13-10 17-12 13-10 14-11z" fill="#ECA410" />
              <path transform="translate(1212,646)" d="m0 0h1v31l11-4 13-6 16-4 7-1h9l24 5 12 5 9 5v1h-11l-3-2v-2h-57l-12 4-5 2h-13l-1-1z" fill="#EDA611" />
              <path transform="translate(1578,791)" d="m0 0h15l4 2h-2v2l-5 1v3l4 2 1 2 1 8 5 8 2 1v2l4 2h17v2l-24 3h-11l-1-9-8-14-1-3-4-2-1-7-36 1-64 1 2-2z" fill="#7A8614" />
              <path transform="translate(1573,286)" d="m0 0 25 5 15 4 6 1 7-3 4-6 9 3 1 3h6l-1 7-3 3-8 4h-7l-1-2-4-2-4 2h-9l-12-2h-3l-9-1-7-2 3-1h2l-3-3 1-4-8-3z" fill="#EBA513" />
              <path transform="translate(1561,317)" d="m0 0h17l4 1v2l13 1 17 2h17v3l-12 7h-8l-19-5-12-2h-18l-15 4-9 3h-7l-1-5 2-4 4-1 2 2h6l4-4z" fill="#CA8313" />
              <path transform="translate(619,236)" d="m0 0h1l-1 8-6 13-5 9-2-1-10 4-8 1-7 7-3 5-3 2-1 4h-2l-1 4-10 8h-9l3-3 7-6-1-10 19-10 9-7 6-5 7-8 11-11z" fill="#F9B61F" />
              <path transform="translate(726,550)" d="m0 0 5 4 9 11 14 14 4-1 10-11 2-1h7l-1 5-6 9-6 2-4 6-4-1-4-6-4-3-3-5-4-3-3-1-1 2-4-1-7-1-6 1-9 3-8 2-10 7-6 2-3 5-8 1v2l-8 2v-2l7-3 11-7 14-9 12-7 8-7z" fill="#FAB822" />
              <path transform="translate(72,501)" d="m0 0h7l3 1 10-1h30l16 5 15 11 3 4-5 3-4-2v-2l-7-2-3-6-8-3-18-4h-24l-15 4-10 4-10 7-9 8-4 4-1-6 6-10 10-8 13-5z" fill="#CB7F0A" />
              <path transform="translate(1215,589)" d="m0 0 4 2 14 9 17 7 14 3h20l12-3 11-6 12-9 2-2 2 2v3l-6 3h-2v2l-4 4-3 5-4 3-16 4h-25l-11-1h-9l-6-1v-2l-6-2-5-5-11-14z" fill="#FAB81E" />
              <path transform="translate(1955,717)" d="m0 0 3 1-1 9-8 9-11 9-9 6-7-1-14-13-8-7-1-6 3-3 5 4 5 3 4 4v2l9 3 8 2v-3l4-2 3-1 4-5h2l2-4 5-6z" fill="#C1740B" />
              <path transform="translate(1060,732)" d="m0 0h16l25 5 16 9 14 10 7 10 4 11v8h-2l-2-10h-2l-7-11-8-8-15-8-17-6-7-1-23-1-1-5z" fill="#694A17" />
              <path transform="translate(466,91)" d="m0 0 3 1 1 4 1 15 2 6v18l-2 64-2 17-1 6h-2z" fill="#E19913" />
              <path transform="translate(1909,587)" d="m0 0h1l2 34 5-1 2 7 1 18h-1l-3-11-3-2 1 2 2 31 1 8 3-3v12l-1 5-7-1v2h-2l-1 5h-1v-46l1-19z" fill="#CBA66C" />
              <path transform="translate(1507,390)" d="m0 0v3l-5 6-6 3-51 2-24 3-26 1h-27l-6 1-9-1-1-3-3-1 49-1 32-2 29-2 24-2 15-2 8-4z" fill="#573D11" />
              <path transform="translate(102,530)" d="m0 0 5 1 2 2h8l1 4 10 1 8 1 1 3-4 6h-7l-4-4-7 1-5-3-14-1-7 3-3 3v2l-11 6h-7l1-7 5-5 7-2v-2l10-5 9-3z" fill="#DC920C" />
              <path transform="translate(410,617)" d="m0 0h2l6 10 1 3 1 18v89l-2 40-3 32-2 2 1-12 2-135v-33l-4-10z" fill="#CBA76D" />
              <path transform="translate(1145,73)" d="m0 0 6 1 3 3h-2l2 10 12 16 9 10 7 8 4 3 6 2 2 2-1 4-5 3h-5v-2l-5-1-1-1-10-1h-60l-18 1 3-2 6-1 46-1 28-1 3-1-10-14-12-18-8-16z" fill="#E8AE32" />
              <path transform="translate(65,641)" d="m0 0h38l40 2 20 2 2 3-2 11h-1l-2-7-5-2-14-1-80-2 2-5z" fill="#9E6106" />
              <path transform="translate(1318,772)" d="m0 0h33l10 2h11l12 2h10v2l-9 2h-8l-5-1-4 5h-6l-1-5-6 3-4 4-2-1 3-5h-2l-1 3-1-3h-9l-1 5-15 1-1-6-6-1h-4l3-5z" fill="#E19A11" />
              <path transform="translate(2035,718)" d="m0 0h4v7l-2 2h-3l-7 8-6 9-11 10-12 7-8 3-9 5-8 2 4-4 7-3 10-7h2v-2l5-3 7-7 3-7 2-3 2-6 3-6 2-3h2v2l5-1z" fill="#CE7703" />
              <path transform="translate(1306,830)" d="m0 0h25l6 3 17 3h16l4-2-1 5-6 7-9-3-10-3-23-3h-23l-15 4-14 7-9 7-4 2 2-4 9-10 15-7 15-5z" fill="#A36810" />
              <path transform="translate(371,461)" d="m0 0 5 1 1 4-5 7-1-2v-4h-10l-7 3-5 3-1 3h-2l-1 5-10 7-4 4h-2v2l-8 4-4-2-7-2-5-8-9-3-4 2-7 2-4-11v-3l8 1 16 8 10 7 7 1 13-11 14-10 16-7z" fill="#FAB71D" />
              <path transform="translate(622,599)" d="m0 0 3 3 3 8 4 30v5l1 1 1 19v22l-2 9-3 4-2 8v7l-3 5-3 1 3-18 3-43v-15l-1-8-1-24-2-10z" fill="#CBA56B" />
              <path transform="translate(640,549)" d="m0 0 5 2 12 16 5 10-1 2-7-11-6-7-5 1-7 3-5 4-14 7-5 2-5 3-6-1-12 9-5 4h-3v-2l13-9 14-10 13-8 14-12z" fill="#F9B824" />
              <path transform="translate(818,781)" d="m0 0 3 4v2h2l4 7 13 15 14 14v3h4v-2l14-9 10-7 5-2-4 6-17 13-11 8-6-1-8-8-9-11-7-11-5-14-2-4z" fill="#5F4111" />
              <path transform="translate(1206,10)" d="m0 0 4 1 1 3 1 61 1 34 2 2 8-4h3l-8 8-6 7-3 1-1-6 1-6-1-78-2-12-1-5-3 9-2 41-1 38-2-2v-16l2-50 2-21z" fill="#FEFEFE" />
              <path transform="translate(854,667)" d="m0 0v3l-5 10-6 16-8 15-6 9-8 11-6 5 2-9 10-24 7-12 11-13z" fill="#CCA66B" />
              <path transform="translate(379,234)" d="m0 0 1 2h3l5 11 5 10 15 22 5 5v2h2l7 8 15 11 14 8v2l-9-2-8-6-15-10-10-9-4-3v-2h-2v-2h-2l-14-21-3-6h-2l-4-14z" fill="#C87B06" />
              <path transform="translate(1290,802)" d="m0 0 5 2 10 8 12 9 6 5 1 3-18 2-15 4-18 8-5 3 2-6 3-5 9-1 8-1 7-4 3-2-3-7-6-11-1-1z" fill="#EBA30F" />
              <path transform="translate(1848,776)" d="m0 0 6 1 3 5-1 4-19 13-17 12-3-1v-6l3-4h2l2-6 4-1 7-8 6-5 1-3z" fill="#C97809" />
              <path transform="translate(787,365)" d="m0 0h4l-1 8-16 12-5 5-22 15-7 3-9 5 1-3 6-4 1-3h2l2-4 8-7 4-4h2l1-3 11-7 15-10z" fill="#DB900B" />
              <path transform="translate(1598,599)" d="m0 0h2v130l-3-1-1-2-1-67v-22l-1-8v-27z" fill="#CEA767" />
              <path transform="translate(787,241)" d="m0 0 2 1-16 12-11 8-32 24-11 9-12 11-3 1 1-7 3-9 5-4 8 1v-2h2v-2h3v-2l10-7 7-7 5-2 7-4 3-5 4-3 5-1 4-1 10-7z" fill="#DA9110" />
              <path transform="translate(1499,399)" d="m0 0 2 1-6 4-7 2-9 1-80 3-47 1-3-1-1-4 4-1 1 2 11 1 4-1h27l26-1 24-3 51-2z" fill="#A36A14" />
              <path transform="translate(1e3 854)" d="m0 0h12l26 3 46 9 11 2 32 1-2 2-13 2h-27l-20-3-2-1v-2l-7-1v-2l-8-1-39-7-9-1z" fill="#A1670F" />
              <path transform="translate(1083,663)" d="m0 0 7 2 3 6 2 1v3h2l7 9 3 3 3 5 8 10 3 8v3l3 1v2l-5-2v-2h-2l-8-17-7-9-11-8-12-5-10-2-17-1-3-1 1-3h6v-2h15z" fill="#D78D0B" />
              <path transform="translate(1327,195)" d="m0 0h1l1 25 1 57 2 52 6 14 3 4-1 2-8-11-4-10-2-11v-89z" fill="#CBA76E" />
              <path transform="translate(109,553)" d="m0 0h1v6h2l1 5-3 8-8 10-4 8v3h2v-2l2 1-9 15-1-3 4-7h-2v2l-7 3-3 3-7 2-2 5-3 2 2-5 7-10 12-17 13-19z" fill="#DE940D" />
              <path transform="translate(1412,309)" d="m0 0 4 2 6 9 4 9 6 12v2h2l3 5v2h2l3 8 3 2-6 1h-1v3l-2 1h-7l4-2h3l-4-9-8-10-6-13-3-10v-3l-11 6-3-1 7-6 2-7z" fill="#CA800F" />
              <path transform="translate(1138,191)" d="m0 0h3v8l-2 1-52 1-1-1v-7l1-1z" fill="#D78E10" />
              <path transform="translate(1403,787)" d="m0 0 1 3-9 16-13 20-7 10h-3v1h-18v-1l12-1 2-1v-6l5-8h2l2-4 7-9 2-4h2v-2h2l1-4h2v-2h2v-2z" fill="#BE6F05" />
              <path transform="translate(1458,599)" d="m0 0 4 2 1 1v136l3 9 8 13v3l-7-9-6-12-3-14v-24l1-26 1-73z" fill="#D0AB6F" />
              <path transform="translate(1686,802)" d="m0 0 3 1 2 18v32l6 4v2l5 2 2 3v8l-1 2-11 2-2 2-5 2-6 4h-3l1-4 5-7 11-6-1-5-4-7-1-9v-37z" fill="#ECA410" />
              <path transform="translate(641,394)" d="m0 0 6 1 24 7 23 9 16 6 6 4-1 3h-2v4l-5-2-2-3-10-5-9-3-8-4-26-9-12-3-1-3z" fill="#5F400F" />
              <path transform="translate(35,700)" d="m0 0h9l23 10 26 12 9 4h11l-5 5-5 3-24-11-21-10-15-8-8-4z" fill="#5D3F0F" />
              <path transform="translate(1019,386)" d="m0 0 5 1v2l7 2 18 11 10 6 14 9 2 3 3 1v2l4 1-1 7-5-2-20-14-15-10-5-3-1-4-4-2-11-5z" fill="#805E28" />
              <path transform="translate(1688,759)" d="m0 0h1v13l-2 3-4 1 2 1-1 5h-2v2l-11 3-12-1v7l8 3 12 2 2 2h-16l-6-2-2-4v-8l4-8 5-4 16-5 5-3z" fill="#EBA40F" />
              <path transform="translate(1549,718)" d="m0 0h4l2 7 1 9 6 11 1 4 2 1 3 5 1 3 4 2 7 6 3 1 1 5-1 2h-6l-5-3-9-11-7-11-4-14v-4h-3v-2l-3-1-1-6z" fill="#C67A06" />
              <path transform="translate(386,849)" d="m0 0h2l-2 4-7 6-17 8-9 3h-4l-1 3-15 6-5 3-5 1v3l14-2 8-1 2 2-20 6-8 1-3-3 1-5 7-6 38-16 21-11z" fill="#E0970E" />
              <path transform="translate(847,593)" d="m0 0 2 1 8 21 7 21 3 6 1 11-1 5-1 6h-2l-1 5-5 7-3 5-6 9-9 16-7 11h-2l2-5 8-14 6-14 5-13 8-11 6-8-19-57z" fill="#FBB71C" />
              <path transform="translate(412,853)" d="m0 0h2l-1 3-22 12-19 8-22 8h-10l-3 1h-10l4-2 14-4 12-5 22-7 9-3 14-8z" fill="#A66B10" />
              <path transform="translate(1157,795)" d="m0 0 4 1-1 7-8 10-8 9-5 7h-9l-6-3 6-7 5-5 7-2 1-4 6-5 3-2z" fill="#DD930E" />
              <path transform="translate(383,253)" d="m0 0 3 2 5 10 10 14v2h2v2h2l5 5 10 9 20 13v2l11 3 8 3-3 2-5-1-18-7-7-5-6-2-8-7-11-11-9-13-9-17z" fill="#9E6106" />
              <path transform="translate(862,589)" d="m0 0 7 1 17 6 24 8 12 6 10 4 4 3v4l-6-2-25-10-29-10-12-4-3-5z" fill="#A66707" />
              <path transform="translate(1165,196)" d="m0 0 7 4 5 6 2 6v8l-1 6 2 15v22l-1 6v13l-1 1-1 29h-1v-33l-1-57-3-8-10-14 1-3z" fill="#E0A42D" />
              <path transform="translate(1705,867)" d="m0 0 5 2 11 8 13 13 8 7 4 3v2h3v2l4 1 3 4v2l-9-2-4-2-5-5-9-7-10-9-9-11-6-2z" fill="#604210" />
              <path transform="translate(706,192)" d="m0 0 5 2 8 7 8 10 8 7 9 10 14 14 6 4v2l-9 1-6-9-27-27-7-8-4-3-5-1z" fill="#A66607" />
              <path transform="translate(1463,684)" d="m0 0h2l1 36 1 3 1 11 7 18 7 10-1 3h-5l-11-18-3-9v-47z" fill="#CA7D06" />
              <path transform="translate(1250,466)" d="m0 0 3 1 2 6-2 4-5 2 4-8-11 4-15 9-27 14-10 5-1-3 16-11 21-13 8-5 2 1-1 2 8-3 2-2 5-1z" fill="#F6B930" />
              <path transform="translate(1563,319)" d="m0 0 21 2 20 1 8 1h17v3l-12 7h-8l-19-5-12-2-3-3v-2h-15z" fill="#AD6904" />
              <path transform="translate(704,308)" d="m0 0h1l1 45 4 15v5h8l9-3 7 1 4 5 2 5v3h7l-3 1-1 5-4-1-6-8-4-2-14-1-4-3-7-3-1-4z" fill="#F7B725" />
              <path transform="translate(27,700)" d="m0 0 2 1-4 15-2 7-5 10-6 1v-2l-7-2 1-7 4-2 6-14h2v-2l8-3z" fill="#CA7D06" />
              <path transform="translate(1082,285)" d="m0 0h1l1 79 1 4 15 6 6 4 5 6-4-1h-3l-10 2-8-3 1-7-4-2-1-4-1-32v-39z" fill="#ECA511" />
              <path transform="translate(1513,752)" d="m0 0h3l-2 4-18 13-9 6-8-6-3-4 5-1-2-6h2l2 2 8-3h6 4l6-3z" fill="#CC7806" />
              <path transform="translate(1218,512)" d="m0 0 8 1 12 3 10 4 11 3 10 5 11 6 12 9 2 4-6-2-14-9-16-8-16-6-25-4v-4l-2-1z" fill="#986516" />
              <path transform="translate(17,692)" d="m0 0 1 4 9 2 4 2h-4l-1 3-8 2v2l-3 3-3 9-1 4h-5v7l6 2v2l6-2 4-9 3-12h1l-1 10-4 11-3 4h-8l-5-3-1-2v-9l5-15z" fill="#CF7B06" />
              <path transform="translate(400,837)" d="m0 0 1 3-2 7-2 4-11 7-6 4-10 5-6 2-13 5-9 4h-2v2l-12 4 4 2h-9l-1-3 10-4 9-4 7-3 1-2 13-4 17-8 8-7 3-5z" fill="#CB800A" />
              <path transform="translate(1746,810)" d="m0 0 11 3 18 7 6 3v2l5 2 2 2 9-5 2 1-11 9-10-3-22-11-10-4z" fill="#674610" />
              <path transform="translate(422,306)" d="m0 0 6 1 5 4 20 8v2h7l5 3 11 2 14 1 9 1h34v1l-11 2-11 1h-12l-21-2-21-5-15-6-13-7-7-5z" fill="#D99621" />
              <path transform="translate(1408,309)" d="m0 0h2l-1 8-7 6 1 2-12 8-18 13-4 2 2-4 2-1 3-5 3-1 2-5 5-4 4-2 3-7 8-7z" fill="#DA9112" />
              <path transform="translate(1664,114)" d="m0 0h11l5 3 1 2-1 13-5 13-2 1 1-22-1-3-7 2v6h-3l-3-1-1 4-2-5 1-6 3-5z" fill="#FABA29" />
              <path transform="translate(1920,639)" d="m0 0h1v16h2v14l-1 14-2 5-4 4h-2l-1 11 4 3-1 6-5 4h-2l-1 2-2-4-4 2 4-10 2-13 2-5h2v-2l7 1 1-30z" fill="#DC9412" />
              <path transform="translate(764,247)" d="m0 0 2 1h11l-3 3-4 3h-6l-4 3h-2l-2 5-9 6-5 2-4 5-9 6-3 1v2h-3v2h-2v2l-3 1-4-3 9-7 9-4 8-7 5-6 5-3 5-8h2v-2z" fill="#EBA30E" />
              <path transform="translate(53,662)" d="m0 0 4 2 20 11 10 9 7 7 12 6-4 1-7-3v-2l-7-2-9-7v-2l-4-2v-2l-2 1h-9l-2-1v-2l-5-2-5-5z" fill="#DE950F" />
              <path transform="translate(1416,298)" d="m0 0 1 4-3 5-14 7-11 9-10 10-3 1v2l-4-1-2-5v-3l-3-1 1-11 4-2 1-2 2 15 8-4 10-7 15-9 7-6z" fill="#F8B621" />
              <path transform="translate(1545,718)" d="m0 0 4 1-3 3 1 6 3 3-4 3-10 7-2-1 1-1h-7l-6 2-6-1-4-3v-2l3-2 1-2 5 1 10-2 6-1 3-1 3-7h2z" fill="#DD9718" />
              <path transform="translate(1260,662)" d="m0 0h9l24 5 8 3-3 2h-12l-1-2-5-1h-37l-1 2-5-1-7 2v-2l14-5z" fill="#FBB81F" />
              <path transform="translate(715,628)" d="m0 0h1l5 35 2 15v7l-5 2h-3l1 31 1 11-3-1v-44l1-12z" fill="#CBA66C" />
              <path transform="translate(87,526)" d="m0 0h17l4 2h-2v2l-10 4-15 5v2l-8 4-3 3-2 7 4 1-4 1-2-2 1-7 8-13 5-5z" fill="#EEB130" />
              <path transform="translate(1627,776)" d="m0 0 12 12 7 8 3 5-1 9-5 2-2 4-3 5-9 3 3-6v-4l9-6 1-5-8-12-6-8v-2h-2z" fill="#778817" />
              <path transform="translate(986,846)" d="m0 0 2 1-3 2zm-2 3v3l12 2 17 1 43 8 4 1v2l7 1v2l-10-2-24-6-15-3h-13l-11 2-9 3h-8v-5z" fill="#C0750B" />
              <path transform="translate(466,91)" d="m0 0 3 1 1 4 1 15v26l-2 8-1 43h-1l-1-7z" fill="#CBA66C" />
              <path transform="translate(2e3 764)" d="m0 0m-2 1h2l-1 2zm-2 1h2v4l-12 7-19 8-9 3h-5l-1-3 5-4 8-4 7-3 9-2 9-3z" fill="#9F6E24" />
              <path transform="translate(1189,794)" d="m0 0 1 2-5 13-2 7-2 5-5 5-6 3-13 2-3-1 2-4 14-8 10-9 6-9z" fill="#FCB81C" />
              <path transform="translate(910,145)" d="m0 0h6l10 9 8 8 4 8v2l-6-2-14-12-6-3-7-1 1-4z" fill="#CC7E06" />
              <path transform="translate(1592,175)" d="m0 0h18l28 3 1 4-2 2-30-1-5-2-8-1z" fill="#CD7703" />
              <path transform="translate(94,502)" d="m0 0h32l12 4 15 11 3 4-5 3-4-2v-2l-7-2-3-6-8-3-18-4-17-2z" fill="#C37306" />
              <path transform="translate(1459,289)" d="m0 0h1v34l13-2h8l2 2v5l-7 7-1-2-12-1-3 1-2-3z" fill="#EEA713" />
              <path transform="translate(1413,364)" d="m0 0h6l-4 2-2 2-11 2h-51l-14-1 3-2z" fill="#CBA76E" />
              <path transform="translate(1036,812)" d="m0 0 16 2v2l-16 2h-9v2h-2v2h-2v2l-12 3v2h-2l-2 4-5 5-5 2-5 1 6-7 10-9 16-9 7-3z" fill="#DF9815" />
              <path transform="translate(285,804)" d="m0 0 5 2 9 4 6 2 3 2v2l4 2 9 7 2 4-3 3-9-5-5-2-11-10-8-7z" fill="#9A610B" />
              <path transform="translate(424,83)" d="m0 0h15l15 2-1 3-10 2h-2l-1-2h-25l-19 5-11 5-1-3 5-4 9-4 14-3z" fill="#BA7A19" />
              <path transform="translate(754,248)" d="m0 0 3 1v2l-3 2-4 7-7 5-7 8-7 5-9 4-11 9-3-1 1-4 11-7 9-8 19-14 6-5z" fill="#F9B722" />
              <path transform="translate(1176,125)" d="m0 0 11 3v-3l5 1 2 2-1 4-5 3h-5v-2l-5-1-1-1-10-1h-60l-18 1 3-2 6-1 46-1 28-1z" fill="#FEFEFE" />
              <path transform="translate(715,729)" d="m0 0h2l2 5 13 13v2l6 2v2l12-1-2 4-5 3h-8l-6-4-8-8-5-10z" fill="#A7690D" />
              <path transform="translate(100,466)" d="m0 0h7l24 5-1 3-15-2h-13l-22 4-8 3-7 2-4-1 16-8 14-4z" fill="#F7B729" />
              <path transform="translate(851,579)" d="m0 0 3 1h-2v6l-2 1 1 5 4 5 6 12 6 14v6l-4-2-1-4h-2v-2h-2l-6-17-5-11-1-2v-9z" fill="#DF940C" />
              <path transform="translate(60,497)" d="m0 0m-5 1h5v2l3 1-14 6-9 5-3 3h-2v2l-4 2-7 8-5 8-4 12-1-4 6-16 7-12 14-10 7-4z" fill="#ECA40F" />
              <path transform="translate(1665,227)" d="m0 0h2l2 6v61l-2 8-7 8-11 6-2-1 14-9 3-4 1-6z" fill="#D2A963" />
              <path transform="translate(1204,12)" d="m0 0h3l2 6 1 13v78h-1l-1-76-2-12-1-5-3 9-2 41-1 38-2-2v-16l2-50 2-21z" fill="#E3D1B4" />
              <path transform="translate(1667,121)" d="m0 0h6l2 5-1 13-3 6-5 1-2-7h-2l-2-4-1-8 4 1 2 1v-6z" fill="#DE940D" />
              <path transform="translate(1713,868)" d="m0 0 7 1 10 5 3 3 2 6 4 2 3 3-1 7-4-2-10-9-7-7-8-6z" fill="#EAA10E" />
              <path transform="translate(1747,763)" d="m0 0 17 8 28 10 11 6 5 4h3v4l-4-1-19-9-17-6-16-8-6-4z" fill="#CCA76D" />
              <path transform="translate(1706,873)" d="m0 0 4 1 9 10 4 5 8 7 12 9-2 1-11-7-12-11-10-10-6 3-12 10-8 5-9 3-2-2 1-6h2v2l11-2 8-6 11-11z" fill="#CDA970" />
              <path transform="translate(698,579)" d="m0 0 5 2 2 8 3 5v2l4 1 2 4v7l-4-5-9-10-5-1-6 2h-4l-1-3 2-3h2l2-5 3-3z" fill="#DE940E" />
              <path transform="translate(383,175)" d="m0 0h1v20l-5 15-3 10-2-3-1-10 5-24 2-7z" fill="#EEA916" />
              <path transform="translate(141,703)" d="m0 0h2v3l-5 3-3 5-22 15-2-1-1-1-4-1 6-2 2-4h2l1-4 7-6 16-6z" fill="#C87403" />
              <path transform="translate(1992,749)" d="m0 0v3l-5 8-8 7-8 5-5 2-5 1-7 2v2l-10 3-10 4-1 4 18-3-1 3-9 2h-9l-2-2 1-4 8-5 26-11 15-9 8-7z" fill="#FBB923" />
              <path transform="translate(651,207)" d="m0 0 2 4 1 7v33l-2 57-1 17h-1v-31z" fill="#CBA66C" />
              <path transform="translate(204,520)" d="m0 0h1l3 133v10h-1l-2-24-2-80v-33z" fill="#DCAD51" />
              <path transform="translate(1691,723)" d="m0 0h1l2 10 2 2 2 7v7l-4 13-1 9-3 3-2-2z" fill="#ECA40F" />
              <path transform="translate(1261,676)" d="m0 0h9l21 3 8 3 7 3v2h6l1 5-3 5-11-7-11-6-13-4-14-3z" fill="#C87F0D" />
              <path transform="translate(1790,614)" d="m0 0 4 2 13 13 4 8v44l-1 39v32l-2 3v-54l1-36v-26l-5-9-9-10z" fill="#DDBD84" />
              <path transform="translate(1487,342)" d="m0 0 5 1 3 2-1 2-6-3-1 7v12l-2 3-16 2h-7l-4-4h-18l3-2 5-2v2l31-1 3-1 1-2 2-13z" fill="#858F1B" />
              <path transform="translate(1483,395)" d="m0 0h9l-1 2-17 2-34 2-22 2-20 1h-43v-1l36-1 51-4z" fill="#537403" />
              <path transform="translate(850,586)" d="m0 0 6 2 7 5v9l6 20 6 17-1 3-9-21-8-19-6-9-2-2z" fill="#FBB71C" />
              <path transform="translate(1e3 854)" d="m0 0h12l26 3 46 9 18 3v1l-16-1-30-4-25-5-22-4-9-1z" fill="#563C11" />
              <path transform="translate(548,549)" d="m0 0h6l19 19 2 4-1 3-4-6-8-8-11-7-7 1v2l-4 4-7 8-4 7-2 5h-6l-2 1 2-5 7-7 7-8z" fill="#FBB923" />
              <path transform="translate(1674,596)" d="m0 0 8 1 4 5 2 7h2l2 5v10h-2l-4-12-8-5-11-3-1-4 4-3z" fill="#CE7F06" />
              <path transform="translate(830,796)" d="m0 0 9 2 8 7v2h2l7 8v4l-4 2-8-7-9-9-5-6z" fill="#FAB51A" />
              <path transform="translate(1314,400)" d="m0 0 5 1 3 4h5l3 7 10 5v8h-7l-5-5-11-13-3-5z" fill="#5E4514" />
              <path transform="translate(1481,839)" d="m0 0 3 1-5 2-5 12-3 4-5-2-7-6-4-6-1-4 4 2 6 2 7-1z" fill="#9F6816" />
              <path transform="translate(477,322)" d="m0 0 18 1h52v2l-10 3h-24l-21-1v-2l-14-1z" fill="#9E6209" />
              <path transform="translate(959,689)" d="m0 0 5 2 4 1 8 1 8 3 2 1 2 6v9l-2-3-8-3-13-11-6-5z" fill="#ECA40F" />
              <path transform="translate(413,573)" d="m0 0 2 1-13 12-5 5-6 7-10 5-9 6-9 4-4 2-2 3h-2v-5l9-3 13-10 17-12 13-10z" fill="#FBBA25" />
              <path transform="translate(1231,126)" d="m0 0h21l59 2 13 1v3 1l-31-2-10-1-50-2z" fill="#FEFEFE" />
              <path transform="translate(1503,608)" d="m0 0h2l-1 94-3-4v-39z" fill="#CFAC74" />
              <path transform="translate(1331,328)" d="m0 0h3l6 13 10 8 4 2h9v-2h3v2l-14 9-4 1-4-8-3-3-6-11z" fill="#A97526" />
              <path transform="translate(1079,660)" d="m0 0 10 2 6 2 1 5h2l3 5 2 7 7 8 4 6-1 2-6-7-3-5-7-8v-2h-2v-3l-3-1-3-6-6-1-18 1-9-1v2h-6l2-1v-2l6-2h10v2z" fill="#E59D0D" />
              <path transform="translate(1971,771)" d="m0 0 2 1-5 5-8 3-8 5v3l2 1h-3v-2l-11 3h-7l1-5 17-6h3v-2l9-4 5-1z" fill="#C27205" />
              <path transform="translate(1612,237)" d="m0 0h10l8 5 6 8 1 4v8l-2 5-2-1-2-9-4-8-5-4-10-6z" fill="#C17105" />
              <path transform="translate(294,639)" d="m0 0h1v106l-1 26 1 3-4 1 2-69z" fill="#CFAC77" />
              <path transform="translate(1296,549)" d="m0 0 4 2 6 4 10 12 8 14 1 7-5-1-9-17-8-11-4-4z" fill="#AD6D0E" />
              <path transform="translate(1544,239)" d="m0 0 7 1 7 3 8 5 5 2 5 5v2l-9-1-16-8-7-6z" fill="#5E400F" />
              <path transform="translate(1210,709)" d="m0 0h1v32l-1 25-3 20-2 8-1-4 2-18 2-51z" fill="#F5B526" />
              <path transform="translate(851,275)" d="m0 0 1 2v8l4 2 5 5v2l-5-2 2 4 5 2 16 12-2 1-13-8-14-8-4-4v-6z" fill="#DA920B" />
              <path transform="translate(1230,234)" d="m0 0h1l1 13v107h-1l-2-90v-15z" fill="#CBA66C" />
              <path transform="translate(604,85)" d="m0 0 4 1-5 5-10 7-12 5-18 4h-13l-2-4 26-2 15-4 5-3 5-6z" fill="#D2A04A" />
              <path transform="translate(138,516)" d="m0 0 9 4 3 5-6 7-5-1-2-5-10 3-6 2h-5v-3l11-2 9-7z" fill="#FBB71B" />
              <path transform="translate(1110,749)" d="m0 0 6 1 7 4v-2l4 1 5 4 7 11 3 9v8h-2l-2-10h-2l-7-11-8-8-11-6z" fill="#9E6611" />
              <path transform="translate(1559,749)" d="m0 0h2v2h2v2l4 1 2 4 4 2 7 6 3 1 1 5-1 2h-6l-5-3-7-9-6-11z" fill="#634411" />
              <path transform="translate(165,709)" d="m0 0 8 6 6 8 11 5 4 2-1 4-4 2-8-4-8-7-8-12z" fill="#A36911" />
              <path transform="translate(156,678)" d="m0 0v3l-5 9-5 5-6 5-11 2-10-2-10-1v-1l21-1 12-6 13-12z" fill="#E59C0D" />
              <path transform="translate(1321,386)" d="m0 0 4 2 3 3-1 14-5 1-5-5h-4l-1-5 1-2h4l1-5z" fill="#678008" />
              <path transform="translate(1266,142)" d="m0 0h6l-4 1-1 4-2 1-33 2-4-7h22z" fill="#D0AE7A" />
              <path transform="translate(855,291)" d="m0 0 10 4 4 4 14 8 5 5 7 4h6l5-3 2 1-6 5-8 1-16-10-15-11-6-3z" fill="#61410F" />
              <path transform="translate(1598,242)" d="m0 0 2 1-8 9-1 5-10 14-6 8h-1l1-9 4-6v-5l5-5 5-3h2v-3z" fill="#C87C07" />
              <path transform="translate(880,649)" d="m0 0 6 2 15 8 16 8 14 8-4 1h-2v-2l-9-3-7-3-6-2-6-3v-2l-5-2-10-6z" fill="#FBB81D" />
              <path transform="translate(69,489)" d="m0 0 3 1h-2v2l-5 4-10 3-10 4-11 8-6 4 2-5 9-10 8-3h4v-2z" fill="#FCB81C" />
              <path transform="translate(1406,773)" d="m0 0h2l-1 11-3 4-7 5h-2v2h-2v2l-8 4v-2l-2-1 5-4 1-2h2l1-2 3-3h2l3-6 1-5z" fill="#CD7703" />
              <path transform="translate(1176,394)" d="m0 0 3 1 2 7h2l1 4 4 2 2 5 4 2 13 11v4l-5-1-10-9-9-10-7-12z" fill="#A16408" />
              <path transform="translate(1578,325)" d="m0 0h12l9 1h23l7-1-5 4-7 4h-8l-19-5-12-2z" fill="#CD8A1E" />
              <path transform="translate(985,850)" d="m0 0 23 1 33 4 21 5-4 1-22-4-22-2h-18l-13-2z" fill="#9D6005" />
              <path transform="translate(939,799)" d="m0 0 15 15 11 12 4 6-4 1-5-5-6-5-9-11-6-10z" fill="#664818" />
              <path transform="translate(382,610)" d="m0 0 2 1v2l-23 16-3 1-3-2 1-5 1-2 5-2 2-2h6l1-2 7-4z" fill="#CA7D06" />
              <path transform="translate(1865,657)" d="m0 0h1v36l-1 80-3 7-1-3 1-9 1-20 1-76z" fill="#D1AE76" />
              <path transform="translate(1803,565)" d="m0 0 1 2-2 5-14 13-5 4-7 8-6 2-2-1 19-19 8-7z" fill="#D4AD6B" />
              <path transform="translate(526,726)" d="m0 0h2l3 7 8 7 5 5 6 5 4 4 3 2-2 3-6-1-7-8-13-13-3-5z" fill="#915C0D" />
              <path transform="translate(1082,285)" d="m0 0h1l1 79 1 4 9 4v2l2 1h-7l-5-3h-2l-1-35v-39z" fill="#CBA66B" />
              <path transform="translate(1198,165)" d="m0 0h1l1 34 1 14 1-4v2h3v32l-2-1-1-8-1-10h-2l-1-18z" fill="#BD914E" />
              <path transform="translate(614,581)" d="m0 0 7 6 4 5v5l-3 1-10-8-7 1-3 1 1-4 5-6z" fill="#DC920F" />
              <path transform="translate(642,385)" d="m0 0 2 1-3 12 14 4 29 10 8 4 7 3 8 4v3l-23-11-25-9-17-5-4-2-1-4z" fill="#CAA366" />
              <path transform="translate(1674,792)" d="m0 0h12l4 2 1 6-1 3-9-3-8-2-10-2-1-3z" fill="#9A5F07" />
              <path transform="translate(1082,605)" d="m0 0 5 1 11 8 12 12 8 14 3 7-1 4-4-1-6-16-7-11-10-10-11-7z" fill="#925E0E" />
              <path transform="translate(1263,850)" d="m0 0 2 1-4 4-1 5-10 10-6 3-1-5 4-8 3-2h3v-2z" fill="#A86D14" />
              <path transform="translate(1421,406)" d="m0 0m-72-1h3l1 2 11 1 4-1h53v1l-22 2-47 1-3-1-1-4z" fill="#A46C16" />
              <path transform="translate(1470,693)" d="m0 0v3 18l2 14 2 6 2 2-2 3-4-1-3-8-1-7v-24l1-4z" fill="#CDA667" />
              <path transform="translate(898,603)" d="m0 0 6 1 13 5 13 4 6 4v4l-6-2-25-10-9-3v-2z" fill="#795417" />
              <path transform="translate(704,308)" d="m0 0h1l1 45 4 15v5h10l-2 4-5-1-9-4-1-4z" fill="#CCA86E" />
              <path transform="translate(1686,802)" d="m0 0 3 1 2 18v32l4 3-1 5-2 1-4-7-1-9v-37z" fill="#CCA870" />
              <path transform="translate(1553,711)" d="m0 0h1l3 9 4 11 3 12 5 11-1 2-3-3v-3l-3-1-4-10-3-5-2-16-3-1 2-1z" fill="#DC910B" />
              <path transform="translate(509,94)" d="m0 0h7l18 5 5 2v2h-3l-2 4-6-3-14-4-5-2z" fill="#985F09" />
              <path transform="translate(576,305)" d="m0 0h2l-2 4-15 10-13 6-1-2-5 1h-47l-13-2v-1l23 1h34l19-4 15-10z" fill="#C07605" />
              <path transform="translate(1201,800)" d="m0 0 2 1-9 19-10 14-9 10-8 8-4 3-2-1 5-5 10-11 11-14 8-11z" fill="#CFAD77" />
              <path transform="translate(35,702)" d="m0 0 5 1 17 8 16 8 23 11 8 3 5-4 2 1-6 5-8-1-17-8-19-10-26-13z" fill="#CCA971" />
              <path transform="translate(1218,512)" d="m0 0 8 1 12 3 10 4 7 2-3 1-4 1-16-4-15-2v-4l-2-1z" fill="#89580A" />
              <path transform="translate(55,639)" d="m0 0 29 1v1l-19 1-3 3-1 2h36v1l-27 1-11 2-7-5 1-5z" fill="#C37407" />
              <path transform="translate(1257,823)" d="m0 0 1 4v4l2 1-2 11-5 7-2 7-5 6-1-2z" fill="#EBA81E" />
              <path transform="translate(1546,588)" d="m0 0h8l5 4v11l-4 1-1-3-4-2-5-6z" fill="#DE981A" />
              <path transform="translate(1900,723)" d="m0 0 10 5 2 6 4 5 5 5v3h2v2l-4-2-11-10-8-7-1-6z" fill="#A06307" />
              <path transform="translate(1165,196)" d="m0 0 7 4 4 4 1 8v7l-3-1-7-11-5-7 1-3z" fill="#C77D0C" />
              <path transform="translate(1087,136)" d="m0 0 48 4 36 2 11 1-1 2-31-1-16-2-36-3-9-1z" fill="#A56507" />
              <path transform="translate(1076,670)" d="m0 0 12 3v2l6 2 6 5 5 3 8 11 5 6 3 8v3l3 1v2l-5-2v-2h-2l-8-17-7-9-11-8-14-6z" fill="#6A460D" />
              <path transform="translate(1212,603)" d="m0 0 5 1 1 3 4 1 5 2 5 5 5 2 2 4-7-1-16-8-4-3-1-5z" fill="#DC9310" />
              <path transform="translate(1060,410)" d="m0 0 5 2 8 5 2 3 3 1v2l4 1-1 7-5-2-5-5-10-7z" fill="#624311" />
              <path transform="translate(1361,774)" d="m0 0h11l12 2h10v2l-9 2h-8l-5-1-4 5h-6l-1-6 5-2-5-1z" fill="#CCA76C" />
              <path transform="translate(321,880)" d="m0 0h2v6l14-2 8-1 2 2-20 6-8 1-3-3 1-5z" fill="#CFAD77" />
              <path transform="translate(136,473)" d="m0 0 7 1 27 8-2 5-31-10-4-2z" fill="#D0AB6F" />
              <path transform="translate(229,691)" d="m0 0h6l2 2-1 5-5 6-1-3 2-7h-6l-1 5-6 3-5-1-1-6z" fill="#E9A00E" />
              <path transform="translate(1131,809)" d="m0 0h5l-2 6-7 9-4-1h-1l-4-1-1-5 12-6z" fill="#F9B61D" />
              <path transform="translate(1100,413)" d="m0 0 2 1-11 10-8 7-2 1 1-8-4-1-3-4 1-3 6 2 13-1z" fill="#E9A10E" />
              <path transform="translate(1663,788)" d="m0 0h11l15 3 3 4-1 16-2-3 1-11-1-3-3-1h-24v2l-4-2v-3h5z" fill="#C97B05" />
              <path transform="translate(1464,741)" d="m0 0 7 4 10 16v4h-5l-11-18z" fill="#5C4010" />
              <path transform="translate(997,855)" d="m0 0h16l43 8 4 1v2l7 1v2l-10-2-24-6-15-3h-21z" fill="#BC8736" />
              <path transform="translate(1067,666)" d="m0 0h13l10 6 4 4-2 1-4-2v-2l-7-1-4-1-2 1-8-2-16-2v-1z" fill="#C47705" />
              <path transform="translate(102,471)" d="m0 0h14v2l-23 3-24 5-11 4-3-1 5-3 16-4 8-3z" fill="#DA9313" />
              <path transform="translate(1703,872)" d="m0 0 3 2-8 7-9 2-1 1h-6l-6 4-3 3 1-5 1-2 8-3 3-3 5-2 1-1 11-1z" fill="#DC930F" />
              <path transform="translate(392,599)" d="m0 0 4 1 10 7 5 5 7 8 3 8-1 2-4-5-4-6v-2l-4-2-9-9-8-2z" fill="#B5710B" />
              <path transform="translate(1320,587)" d="m0 0h6l3 9v20l-2-3-1-6-5-2 2-10-3-6z" fill="#DD930D" />
              <path transform="translate(1025,292)" d="m0 0 2 1-1 15-1 38-1 6h-1v-59z" fill="#EBBB5C" />
              <path transform="translate(914,152)" d="m0 0 9 1 5 5 5 3 5 9v2l-6-2-11-12-7-4z" fill="#AA6908" />
              <path transform="translate(14,723)" d="m0 0h8l-1 5-3 5-6 1v-2l-7-2 1-5 1-1z" fill="#A16305" />
              <path transform="translate(909,555)" d="m0 0 9 2 12 5-3 1v2l-7-1-3-1v-2h-3l-4 4-1 5-3-2 1-9z" fill="#CFAA6D" />
              <path transform="translate(846,145)" d="m0 0 5 3 4 12 1 7-2 1-3-6-5-6-5-3 1-5z" fill="#CD810C" />
              <path transform="translate(422,306)" d="m0 0 6 1 5 4 20 8v2h7l3 2v3l-9-2-16-7-12-7z" fill="#C88A26" />
              <path transform="translate(1016,190)" d="m0 0h15l-3 15h-3l-1-5-3-2 1-2h-6l-3-3v-2z" fill="#B46A03" />
              <path transform="translate(357,864)" d="m0 0 2 1-5 3-1 2h-4l-1 3-15 6-5 3h-5l1-4z" fill="#E7A219" />
              <path transform="translate(722,743)" d="m0 0 3 2 7 2v2l6 2v2l12-1-2 4h-6v2l-6-1-9-6-5-5z" fill="#6D4910" />
              <path transform="translate(615,730)" d="m0 0 2 3 5 2 5 6 9 7 8 6 7-1-3 3-6 1-13-9-14-14-1-3z" fill="#65430E" />
              <path transform="translate(419,632)" d="m0 0 2 3 1 9v46l-1 19h-1z" fill="#ECA40F" />
              <path transform="translate(1139,819)" d="m0 0 4 1-1 5-3 4h-9l-6-3 5-5 6-1z" fill="#CB7D06" />
              <path transform="translate(1688,759)" d="m0 0h1v13l-2 3-11 1h-10l-1 2-3-1 6-4 8-2 10-4 1-1z" fill="#CFAB71" />
              <path transform="translate(1581,174)" d="m0 0h31l7 1v1l-27-1 2 5h8l2 2-12-1-8-2h-5l-3 2 1-5z" fill="#D48B11" />
              <path transform="translate(847,593)" d="m0 0 2 1 8 21 7 21 3 6 1 11-1 2-2-1 1-3-19-56z" fill="#F9B71F" />
              <path transform="translate(1318,772)" d="m0 0h33l9 2v1h-7l-10-1h-8l-7 1-4 2-11 3-1-2 5-5z" fill="#D1A762" />
              <path transform="translate(629,617)" d="m0 0h1l6 45v17l-2 16-2 1 1-9v-22l-1-19-1-3-2-19z" fill="#ECA40F" />
              <path transform="translate(497,285)" d="m0 0 8 1 20 6 6 4 4 3h-8v-2l-9-2-21-9z" fill="#F5B82F" />
              <path transform="translate(1016,188)" d="m0 0 13 1v1l-16 1v2l3 1v2h6v2l4 3-2 4v-4l-9-2-9-4v-5z" fill="#DF9918" />
              <path transform="translate(790,360)" d="m0 0 5 1 1 7-7 8-18 13-2-1 10-8 10-7 2-8-6 2-1-2 4-4z" fill="#D9B066" />
              <path transform="translate(412,297)" d="m0 0 5 2 8 5 17 8 15 5 2 2-6 1-20-8-7-5-6-2-8-7z" fill="#583D10" />
              <path transform="translate(1358,272)" d="m0 0h2l2 8-1 9-2 3h-2l-4-5 2-14z" fill="#DD920C" />
              <path transform="translate(1419,367)" d="m0 0h14l1 2-2 6-6 1-9-4v-4z" fill="#547403" />
              <path transform="translate(1214,184)" d="m0 0h1v14l-4 69h-3l3-37 2-39z" fill="#C59E60" />
              <path transform="translate(1664,114)" d="m0 0h4v6l3 1-5 2v6h-3l-3-1-1 4-2-5 1-6 3-5z" fill="#E9A61B" />
              <path transform="translate(718,728)" d="m0 0 1 2 5 1 4 4 6 9 1 4-4-1-14-14z" fill="#F0AA13" />
              <path transform="translate(1592,138)" d="m0 0h5v2l-7 4-4 3-2 6-4 2h-3v2l-8-1-1-2 14-7z" fill="#D0AD74" />
              <path transform="translate(1373,834)" d="m0 0 1 4-7 8-9-3-14-4v-1h21l5-2z" fill="#AA7422" />
              <path transform="translate(76,675)" d="m0 0 6 4 12 12 12 6-4 1-7-3v-2l-7-2-9-7v-2h-2z" fill="#E2AC42" />
              <path transform="translate(1127,377)" d="m0 0 2 1v5l2 4-9 11-5 4-2-1 1-2h2l1-4 5-9-1-4z" fill="#BD770C" />
              <path transform="translate(851,579)" d="m0 0 3 1h-2v6l-2 1 1 5 4 5 3 6h-3l-1 3-3-4-4-9-1-2v-9z" fill="#E0960C" />
              <path transform="translate(891,797)" d="m0 0h9l-5 5-8 7-2-1-3 1-5 2 2-5 5-3 5-5z" fill="#C07B11" />
              <path transform="translate(1466,673)" d="m0 0 3 4 2 11v5l-4 2-1 25h-1v-33z" fill="#F7B218" />
              <path transform="translate(1783,605)" d="m0 0 6 2 5 4 7 8 5 5 4 5v3h-2l-4-5v-2l-4-2-8-8-9-7z" fill="#7A4E0B" />
              <path transform="translate(1328,776)" d="m0 0h29l1 4-7 6-2-1 3-5h-2l-1 3-1-3h-16l2-2-6-1z" fill="#D2A960" />
              <path transform="translate(1676,603)" d="m0 0 10 1 2 5h2l2 5v10h-2l-4-12-8-5-3-1z" fill="#BE7003" />
              <path transform="translate(1645,810)" d="m0 0 1 3-4 6-6 7-6 1-26 3h-5l1-2 24-3 7-2 8-4 4-7h2z" fill="#536107" />
              <path transform="translate(1447,819)" d="m0 0 2 1 1 11 5 5 5 4 8 4-4 1-7-3-2-1-1 2-7-8-2-12z" fill="#5B3F11" />
              <path transform="translate(1163,101)" d="m0 0 15 15 4 5 5 5h-4l-1-2-5-1-4-4-6-9-4-7z" fill="#BB7506" />
              <path transform="translate(424,83)" d="m0 0h15l15 2-1 3-10 2h-2l-1-2-9-1v-2h-6z" fill="#A46505" />
              <path transform="translate(25,711)" d="m0 0h1l-1 10-4 11-3 4h-8l-5-3-1-2v-9l3-10 1 4-2 10v4l6 2v2l6-2 4-9z" fill="#DA9416" />
              <path transform="translate(1550,712)" d="m0 0h1v5l-5 2-3 3-3 7-3 1-6 1-5 1-1-2 13-8 11-9z" fill="#EBB951" />
              <path transform="translate(1437,359)" d="m0 0 5 1 5-1-4 4 11 1v1l-5 1-22 1-14 1 2-3 4-1h19z" fill="#81800D" />
              <path transform="translate(1419,329)" d="m0 0h2l8 14v2h2l4 5v7l-4-5-7-8-5-10z" fill="#BE6F04" />
              <path transform="translate(1664,289)" d="m0 0h1v13l-5 6-13 8h-2v-2l10-7h2l1-7 2-4 4-1z" fill="#C97D07" />
              <path transform="translate(1463,718)" d="m0 0h1l4 16 4 10-1 3v-2l-4-1-2-2-2 1z" fill="#9D6005" />
              <path transform="translate(157,611)" d="m0 0 1 4-1 6-13 2h-12l-12-1-2-2h26l8-3 3-1z" fill="#CCA76E" />
              <path transform="translate(1452,589)" d="m0 0 8 1 3 2v10l-12-7-2-4z" fill="#A76504" />
              <path transform="translate(371,461)" d="m0 0 5 1 1 4-5 7-1-2v-4h-10l-7 3v-3l8-4z" fill="#FCB91F" />
              <path transform="translate(1614,176)" d="m0 0 14 1 5 2-3 3h-18l-2-2 3-1z" fill="#C06E02" />
              <path transform="translate(1906,485)" d="m0 0 3 3 3 13-1 10-1-2h-2l-3-16v-7z" fill="#EAA311" />
              <path transform="translate(530,717)" d="m0 0 1 4-4 5v6l6 8 16 16h-3l-15-14-7-8v-7z" fill="#CFAB74" />
              <path transform="translate(1962,773)" d="m0 0 2 2-4 3h-3v2l-11 5-9 2v2l3 1h-7l1-5 17-6h3v-2z" fill="#D08006" />
              <path transform="translate(477,322)" d="m0 0 55 3v1h-40l-14-2z" fill="#563C11" />
              <path transform="translate(1110,196)" d="m0 0 9 1 5 1h13l2 2h-42l3-3z" fill="#CBA66C" />
              <path transform="translate(1087,193)" d="m0 0h47l-2 2-43 1-2 1z" fill="#8C5707" />
              <path transform="translate(1271,787)" d="m0 0 2 2 4 1-2 5-2 4h-2l-1 3h-2v2h-2l-3 9-2-1z" fill="#E9A415" />
              <path transform="translate(578,272)" d="m0 0 2 1-6 5-7 9-4 4-2-10z" fill="#E5A11A" />
              <path transform="translate(1712,558)" d="m0 0 5 2 4 5-9 1-6 3h-4l1-4z" fill="#CFAB6F" />
              <path transform="translate(1992,749)" d="m0 0v3l-5 8-8 7-8 5-7 2v-2h3v-2l8-4v-2l11-8z" fill="#ECA512" />
              <path transform="translate(1903,477)" d="m0 0 7 1 5 4h-8l-1 2v14l-3-4-2-5v-9z" fill="#CDA971" />
              <path transform="translate(613,731)" d="m0 0 17 17 12 8 6-1 5-2v-2l3 1-7 6-7 1-9-6-10-9-9-9z" fill="#D8B77F" />
              <path transform="translate(225,704)" d="m0 0h3l-1 4-11 10-9 6v-2l-2-1 5-1-1-2 3-1 3-5 9-7z" fill="#B38948" />
              <path transform="translate(1344,500)" d="m0 0 5 3 12 12 5 7 4 9-1 3-7-12-11-13-7-7z" fill="#CB7B05" />
              <path transform="translate(1060,732)" d="m0 0 4 4 5 1 18 1 1 3-29-1-1-5z" fill="#C79D5A" />
              <path transform="translate(862,602)" d="m0 0 2 4 6 19 5 14-1 3-9-21-4-9z" fill="#F8BC32" />
              <path transform="translate(270,596)" d="m0 0h17l8 2v3l-22-1-3-1z" fill="#C77C0C" />
              <path transform="translate(1744,810)" d="m0 0h2l-2 31-2-3-2-10 1-11z" fill="#E8A419" />
              <path transform="translate(1747,763)" d="m0 0 17 8 11 4-1 2-3 2-16-8-6-4z" fill="#D4AC65" />
              <path transform="translate(571,741)" d="m0 0h2l-1 4-11 9-3 3-2-1 2-4 2-1v-2l-4-1 1-2 10-4v2z" fill="#C18127" />
              <path transform="translate(1890,567)" d="m0 0 20 1 1 4-3 2h-6l-11-5z" fill="#AF6A04" />
              <path transform="translate(1309,477)" d="m0 0 5 1 13 8 9 6 9 7-2 3-12-10-22-14z" fill="#E1970C" />
              <path transform="translate(694,589)" d="m0 0h7l5 3 2 4 4 1 2 4v7l-4-5-9-10-7-2z" fill="#C67709" />
              <path transform="translate(539,559)" d="m0 0 1 2-7 8-4 7-2 5h-6l-2 1 2-5z" fill="#F6B522" />
              <path transform="translate(1253,174)" d="m0 0 4 5 2 5-10 8-5 6-1-3 3-7h2l-1-4 5-1 4-1-3-5z" fill="#CBA66B" />
              <path transform="translate(100,466)" d="m0 0h7l24 5-1 3-17-3-3-1h-6l-5-2z" fill="#FEFEFE" />
              <path transform="translate(463,324)" d="m0 0 13 2 14 1 2 4-14-1-14-3z" fill="#D29E46" />
              <path transform="translate(1705,867)" d="m0 0 5 2 11 8-4 3 2 3-4-2-6-7-5-1z" fill="#905907" />
              <path transform="translate(521,582)" d="m0 0 8 2 4 6 2 4v3l-5-2-6-7-4-3z" fill="#CB8211" />
              <path transform="translate(1366,523)" d="m0 0 4 4 1 6h2l7 15 2 9-3-3-8-16-3-9z" fill="#EBA30E" />
              <path transform="translate(378,183)" d="m0 0h1l-1 16-2 21-2-3-1-10z" fill="#D1B07D" />
              <path transform="translate(357,796)" d="m0 0v3l-4 4-2 5-9 8-4 1 1-4 5-5z" fill="#996B27" />
              <path transform="translate(1666,776)" d="m0 0h10l1 3-11 5-7-1 2-5z" fill="#FCB81E" />
              <path transform="translate(597,280)" d="m0 0h2l-2 5-8 11-7 6v-5l5-7 8-7z" fill="#D6972C" />
              <path transform="translate(1061,732)" d="m0 0h15l6 1v1l-9 2 9 1v1h-13l-7-2z" fill="#AD6904" />
              <path transform="translate(281,473)" d="m0 0 8 1 7 3-1 4h-12l-2-5z" fill="#D0AB6E" />
              <path transform="translate(1965,468)" d="m0 0h7l1 2-3 6h-9l-2-5z" fill="#CFAE7A" />
              <path transform="translate(1631,244)" d="m0 0 4 4 2 6v8l-2 5-2-1-2-9-1-4h2z" fill="#CA7D08" />
              <path transform="translate(1319,181)" d="m0 0h7l2 4v8l-4-2-6-4v-5z" fill="#BC7004" />
              <path transform="translate(1349,405)" d="m0 0h3l1 2 11 1 4-1h13v2l-8 1-21 1-3-1-1-4z" fill="#9D6005" />
              <path transform="translate(1694,865)" d="m0 0 1 3-2 5-9 4h-2l-2 4-2 3h-2l1-4 5-7 11-6z" fill="#CEA96B" />
              <path transform="translate(1054,598)" d="m0 0 12 1 14 1 8 4 2 3h-3l-8-2-9-3-16-3z" fill="#E19B14" />
              <path transform="translate(1331,316)" d="m0 0 2 4 2 7 3 3 7 13 4 5-4-2-6-5-5-10v-3l-3-1z" fill="#CD8308" />
              <path transform="translate(1759,890)" d="m0 0 4 4 3 7-1 7-2 2h-3v-13z" fill="#CEAA72" />
              <path transform="translate(1323,780)" d="m0 0h16l-1 5-15 1z" fill="#FAB71E" />
              <path transform="translate(613,585)" d="m0 0 5 2 7 8-1 3-5-2-8-7v-3z" fill="#C47403" />
              <path transform="translate(1318,392)" d="m0 0 2 1 4 9v2l3 1-5 1-5-5h-4l-1-5 1-2z" fill="#5B7403" />
              <path transform="translate(853,288)" d="m0 0 5 2v2l-2 1 2 3 5 2 16 12-2 1-13-8-8-4-3-7z" fill="#A1650C" />
              <path transform="translate(1812,815)" d="m0 0 2 1-15 13-6 5-4 1v-3l13-10z" fill="#CBA66C" />
              <path transform="translate(2038,689)" d="m0 0 2 3 3 5 1 12-2 8-1-4 1-7-3-1-2-7z" fill="#E09A1A" />
              <path transform="translate(33,592)" d="m0 0 3 3 7 11 11 13 6 6-1 3-7-7-7-8v-2h-2l-9-15z" fill="#FCC74D" />
              <path transform="translate(932,282)" d="m0 0h2l-1 6-3 2-7-1-9-4 3-1h9z" fill="#F9B826" />
              <path transform="translate(873,811)" d="m0 0 4 1-11 8-7 4-2-2v-3z" fill="#C27705" />
              <path transform="translate(1249,522)" d="m0 0 10 1 11 6 2 3-7-1-14-6z" fill="#8D611C" />
              <path transform="translate(706,416)" d="m0 0 6 2 4 4-1 2h-2v4l-5-2-2-3-7-3 3-1 7 1-4-3z" fill="#9E630A" />
              <path transform="translate(1176,394)" d="m0 0 3 1 2 7h2l1 4 4 2 2 5h-2v3l-9-12-3-6z" fill="#B07826" />
              <path transform="translate(1207,757)" d="m0 0 1 2h2l-1 16-4 19-1-4 2-18z" fill="#FCBF34" />
              <path transform="translate(1467,730)" d="m0 0 2 3 2 5 3 1 1-3h3v7l-3 5-4-4-4-10z" fill="#E9A00F" />
              <path transform="translate(1012,385)" d="m0 0h5l4 6 10 4 5 5-2 1-18-10-4-4z" fill="#C99C57" />
              <path transform="translate(1545,239)" d="m0 0 10 2 7 4 4 3 5 2 5 5v2h-5v-3l-13-8-13-6z" fill="#A76504" />
              <path transform="translate(1116,818)" d="m0 0 3 4 5-1v2l2 2-3 3 2 3h-3l-9-7v-4z" fill="#DF9610" />
              <path transform="translate(853,269)" d="m0 0h1v8l7 9v4l-5-2-4-3-1-8z" fill="#FBB71B" />
              <path transform="translate(1086,193)" d="m0 0 2 3 1-1h13l-2 1v2l-6 3h-7l-1-1z" fill="#D28A11" />
              <path transform="translate(1971,583)" d="m0 0 4 1-6 5-5 5-8 5v-3l6-7z" fill="#DE9512" />
              <path transform="translate(1188,819)" d="m0 0 3 1-8 10-8 6 1-5 10-11z" fill="#C98109" />
              <path transform="translate(1e3 577)" d="m0 0h5l-1 6-5 2h-8l2-1 1-4z" fill="#CFA96B" />
              <path transform="translate(1248,463)" d="m0 0 2 1-1 5-6 2-5 3h-4l2-4 8-5z" fill="#F9B71F" />
              <path transform="translate(1182,102)" d="m0 0 6 2 10 5v6l-4-1-8-6z" fill="#FEFEFE" />
              <path transform="translate(764,720)" d="m0 0 14 2 2 1-1 5-3 3v-2l-3-1-4-4-5-3z" fill="#F0B637" />
              <path transform="translate(1630,178)" d="m0 0h8l1 4-2 2-30-1v-1l23-1z" fill="#D38A12" />
              <path transform="translate(1127,375)" d="m0 0h6l1 1v6l-3 4-3-4-1-4-5 3-4 2v-2z" fill="#E09B24" />
              <path transform="translate(69,489)" d="m0 0 3 1h-2v2l-12 4-12 5h-6l2-2 5-2h4v-2z" fill="#ECA40F" />
              <path transform="translate(1212,158)" d="m0 0 5 1 9 6 6 7-5-2-14-8z" fill="#BC7910" />
              <path transform="translate(379,871)" d="m0 0 3 1-29 11-5 1v-3l12-3 10-4z" fill="#CBA567" />
              <path transform="translate(444,826)" d="m0 0h2l-2 4-8 9-7 6h-2l2-5z" fill="#A56A12" />
              <path transform="translate(1605,733)" d="m0 0 12 1 4 2 1 5-5 5 1-4 1-2-5-2-9-4z" fill="#D1AD73" />
              <path transform="translate(1056,664)" d="m0 0h9l6 1-4 2-10 1 10 1v1h-15l-3-1 1-3h6z" fill="#D5972C" />
              <path transform="translate(1199,516)" d="m0 0h3l-2 4-8 3-7 3h-5v-2l11-5 6-2z" fill="#C07E16" />
              <path transform="translate(1226,517)" d="m0 0 12 1 2-1 15 5-3 1-4 1-16-4-7-1z" fill="#AD7D33" />
              <path transform="translate(883,809)" d="m0 0 2 1-11 9-16 12-1-3 13-10z" fill="#CBA76E" />
              <path transform="translate(27,694)" d="m0 0h6l11 5v1l-7 1-3 1-7-5z" fill="#9E6106" />
              <path transform="translate(579,100)" d="m0 0 4 1-11 3-12 1-10 2-2-4 26-2z" fill="#C97D06" />
              <path transform="translate(814,771)" d="m0 0 3 3 3 5-1 5 6 14-1 3-7-15-3-10z" fill="#BE852D" />
              <path transform="translate(145,688)" d="m0 0 2 1-3 5-4 1-1 2-7 2h-23v-1l21-1 12-6z" fill="#EAB442" />
              <path transform="translate(936,787)" d="m0 0 5 4 5 13-1 2-5-6-2-1-2-7z" fill="#BF7506" />
              <path transform="translate(1463,661)" d="m0 0h1l1 6v17h-2v7h-1v-24z" fill="#DD920C" />
              <path transform="translate(1051,667)" d="m0 0h21l9 2 3 3-7-1-2 1-8-2-16-2z" fill="#A36A12" />
              <path transform="translate(225,468)" d="m0 0h5l1 5-3 4v-3h-8l-5 1 4-4z" fill="#EEB63D" />
              <path transform="translate(1100,413)" d="m0 0 2 1-11 10-8 7-2 1 1-6 11-8z" fill="#CBA66C" />
              <path transform="translate(353,774)" d="m0 0 7 6 3 6v7l-3 3-2-11-5-9z" fill="#CFA35A" />
              <path transform="translate(1343,773)" d="m0 0 10 1 13 1 3 2-7 1-5 1v-3l-5 1-10-1z" fill="#ECA40F" />
              <path transform="translate(1569,758)" d="m0 0 4 2 7 6 3 1 1 5-1 2-5-1-5-10z" fill="#AD6B08" />
              <path transform="translate(136,710)" d="m0 0v3l-14 10-9 6-2-1v-3l10-5h3v-2h3v-2l5-3z" fill="#9A6E2B" />
              <path transform="translate(370,618)" d="m0 0 2 1-2 4-10 7-4-1 1-4 12-6z" fill="#CE7A08" />
              <path transform="translate(1554,589)" d="m0 0 5 3v11l-4 1-2-7z" fill="#ECA40F" />
              <path transform="translate(1296,549)" d="m0 0 4 2 8 9 8 11-1 3-3-5-5-5-8-9z" fill="#70501C" />
              <path transform="translate(102,471)" d="m0 0h14v2l-20 2-16 1 4-2z" fill="#E79F0F" />
              <path transform="translate(1563,319)" d="m0 0 21 2 8 3v1h-14l-3-2v-2h-15z" fill="#C06E02" />
              <path transform="translate(1181,830)" d="m0 0v3l-11 12-6 7h-2l-1 4-3-1 9-10 12-13z" fill="#8F5909" />
              <path transform="translate(1657,793)" d="m0 0 24 6-3 1h-13l-6-2z" fill="#D3B483" />
              <path transform="translate(1076,670)" d="m0 0 12 3v2l6 2 6 5 5 3 2 2-1 4-4-5-11-8-14-6z" fill="#714B0F" />
              <path transform="translate(1145,73)" d="m0 0 6 1 3 3-2 1-1 10-3-4-3-7z" fill="#FEFEFE" />
              <path transform="translate(1694,868)" d="m0 0 3 4-2 2h-3l-2 4-5 2-6 4v-3l5-5 9-3z" fill="#F5AF16" />
              <path transform="translate(33,618)" d="m0 0 3 1 7 8 5 6 5 3-1 4-3-3-8-7-8-10z" fill="#D1AF7B" />
              <path transform="translate(1082,128)" d="m0 0 3 4v1h-5l-1 4 2-1h6l-4 2h-7l-3-2 2-5 5-1z" fill="#DEB064" />
              <path transform="translate(1263,73)" d="m0 0h3l-1 7-1-2-7 3-8 6-2-1 12-11z" fill="#FEFEFE" />
              <path transform="translate(1672,891)" d="m0 0h2v2l11-1-1 3-11 4-2-2z" fill="#CFAD78" />
              <path transform="translate(1479,758)" d="m0 0h2l5 7v5l-5 1-5-6 5-1z" fill="#AA711E" />
              <path transform="translate(204,724)" d="m0 0 3 1-9 7-7 5-4 1-6-4-3-4 4 2 8 3 3-1 1-3 6-4z" fill="#D6BA8E" />
              <path transform="translate(1299,671)" d="m0 0 6 1 9 5v1h-11l-3-2v-2h-2z" fill="#EEA91A" />
              <path transform="translate(1082,605)" d="m0 0 5 1 11 8 9 9v2l-4-2-10-10-11-7z" fill="#85550D" />
              <path transform="translate(1250,466)" d="m0 0 3 1 2 6-2 4-5 2 4-8-10 3 2-4 5-2z" fill="#E4CB9E" />
              <path transform="translate(1244,382)" d="m0 0 15 3 3 2-2 4-3 3-1-2 1-4-7-1-6-3z" fill="#CEA96C" />
              <path transform="translate(851,275)" d="m0 0 1 2-2 7 3 12-4-2-3-3v-6z" fill="#CDAA73" />
              <path transform="translate(1016,190)" d="m0 0h15v1l-10 1 5 4-1 3-3-1v-2h-6l-3-3v-2z" fill="#CB7E06" />
              <path transform="translate(433,558)" d="m0 0h6l10 9 6 7-1 2-8-8-3-2v-2l-4-2-2-2h-5z" fill="#F6BA33" />
              <path transform="translate(79,471)" d="m0 0 2 3-1 2-8 3-7 2-4-1 16-8z" fill="#F5B526" />
              <path transform="translate(576,305)" d="m0 0h2l-2 4-15 10-13 6v-3l12-5 14-10z" fill="#AA7424" />
              <path transform="translate(787,241)" d="m0 0 2 1-16 12-11 8-4 1 5-5 11-8z" fill="#A26A17" />
              <path transform="translate(396,88)" d="m0 0 2 1v1l4 1-12 5-5 2-1-3 5-4z" fill="#D29022" />
              <path transform="translate(1704,874)" d="m0 0m-1 1 3 2-5 5-14 11-2-1 13-12z" fill="#CCA567" />
              <path transform="translate(885,806)" d="m0 0 2 1-15 11-11 8-2 1-1-3 14-9 10-7z" fill="#6D480D" />
              <path transform="translate(229,691)" d="m0 0h6l2 2-1 5-5 6-1-3 2-7h-7l1-2z" fill="#D1AF78" />
              <path transform="translate(1920,639)" d="m0 0h1v16h2v14l-2 5h-1z" fill="#DD9A1D" />
              <path transform="translate(460,39)" d="m0 0 10 1v4h-16l1-3z" fill="#D1A964" />
              <path transform="translate(1140,826)" d="m0 0 12 1-5 4-2 1h-7l-2-1v-2h3z" fill="#CCA362" />
              <path transform="translate(718,741)" d="m0 0h2l6 8 8 6 8 3v-2l4 1-3 2h-8l-6-4-8-8z" fill="#CFAB72" />
              <path transform="translate(1210,709)" d="m0 0h1v25l-3 1v-14z" fill="#CBA66C" />
              <path transform="translate(140,696)" d="m0 0 3 1-3 3-11 2-10-2v-1l13-1z" fill="#E39A0E" />
              <path transform="translate(1058,661)" d="m0 0h10v2l4 1-1 1-15-1v2h-6l2-1v-2z" fill="#ECA40F" />
              <path transform="translate(1331,328)" d="m0 0h3l8 16 1 5h-2l-7-12z" fill="#66440E" />
              <path transform="translate(764,253)" d="m0 0 5 1-4 4-12 8-2 1 2-4 9-9z" fill="#DB8F0B" />
              <path transform="translate(1338,834)" d="m0 0 16 2 15 1-4 2h-15l-6-2h-5z" fill="#583D10" />
              <path transform="translate(293,749)" d="m0 0h1v22l1 3-4 1 1-25z" fill="#D1B17E" />
              <path transform="translate(627,712)" d="m0 0h2l-1 8-8 9h-1v-5l6-7z" fill="#EDA50F" />
              <path transform="translate(382,610)" d="m0 0 2 1v2l-12 8-1-2 2-5 5-3z" fill="#CA7904" />
              <path transform="translate(1412,179)" d="m0 0 4 1 2 1v11l-3-1-4-9z" fill="#CD810A" />
              <path transform="translate(1348,148)" d="m0 0 6 1 7 6-2 2-10-4-4-2z" fill="#D5B173" />
              <path transform="translate(1556,743)" d="m0 0 6 4 3 3v3h-2v-2h-2v-2h-2l6 10-1 2-7-10-1-2z" fill="#BA8739" />
              <path transform="translate(629,742)" d="m0 0 5 2 12 8 7-1v2l-9 2-8-6-7-6z" fill="#9C6007" />
              <path transform="translate(1482,556)" d="m0 0 6 2 1 4h-8l-8 1v-2z" fill="#D0AB6F" />
              <path transform="translate(143,514)" d="m0 0 4 1v2l4 2 1 4h-4l-1-3-7-2-1-3z" fill="#AB6905" />
              <path transform="translate(1211,230)" d="m0 0h1v21l-1 16h-3z" fill="#BA7A0F" />
              <path transform="translate(1581,174)" d="m0 0h31l7 1v1h-37l-6 5 1-5z" fill="#DE930C" />
              <path transform="translate(1167,110)" d="m0 0 3 3 5 8 3 2h5l1 3h3v2l-7-1-5-2-8-11z" fill="#CFAB71" />
              <path transform="translate(859,628)" d="m0 0h2l5 13 1 1 1 11-1 2-2-1 1-3-7-21z" fill="#F2B42A" />
              <path transform="translate(642,385)" d="m0 0 2 1-3 12 8 3-1 2-10-4-1-4z" fill="#D1AE77" />
              <path transform="translate(1082,365)" d="m0 0h2l1 3 9 4v2l2 1h-7l-5-3h-2z" fill="#D2A964" />
              <path transform="translate(77,720)" d="m0 0 10 4 16 6-4 2-18-8-4-2z" fill="#945B06" />
              <path transform="translate(770,566)" d="m0 0h7l-1 5-4 6h-1l-1-7-2-1v-2z" fill="#CFAC75" />
              <path transform="translate(790,360)" d="m0 0 5 1 1 4h-2l-1 3h-2v-3l-6 2-1-2 4-4z" fill="#DAC098" />
              <path transform="translate(386,849)" d="m0 0h2v2l-7 4-6 4-13 5v-2l21-11z" fill="#CBA568" />
              <path transform="translate(1201,518)" d="m0 0 3 2v6l-5 5-1-9-2-1z" fill="#E6A21C" />
              <path transform="translate(1416,298)" d="m0 0 1 4-3 5-10 5-2-1 4-4 8-6z" fill="#FBBE33" />
              <path transform="translate(622,43)" d="m0 0 4 1-1 7h-12l5-5z" fill="#DBB36B" />
              <path transform="translate(1107,623)" d="m0 0 4 5 4 7-1 5-2-1-7-14h2z" fill="#AF7E32" />
              <path transform="translate(851,579)" d="m0 0 3 1h-2v6l-2 1-2 4h-2v-9z" fill="#CEAB74" />
              <path transform="translate(1019,386)" d="m0 0 5 1v2l7 2 2 3-4 1-9-4z" fill="#5C3F0F" />
              <path transform="translate(1014,782)" d="m0 0h6l2 2-1 5-2 2v-6l-8 1h-3l3-3z" fill="#EAB84D" />
              <path transform="translate(1554,734)" d="m0 0h2l6 11-1 2-4-3-2 1-2-9z" fill="#C87D0B" />
              <path transform="translate(939,568)" d="m0 0 5 1 10 6-1 2-9-3-5-5z" fill="#DCAF58" />
              <path transform="translate(1187,411)" d="m0 0 14 12v2l-5-1-7-7z" fill="#5E400F" />
              <path transform="translate(1537,231)" d="m0 0 4 2 9 5v2l-6-1-1 2-6-7z" fill="#D0860D" />
              <path transform="translate(1327,130)" d="m0 0 4 2-1 6-3 3-7 2v-2h2l1-4 4-1z" fill="#FEFEFE" />
              <path transform="translate(1749,903)" d="m0 0h9l2 1v7h-4l-4-6z" fill="#BE790E" />
              <path transform="translate(1344,411)" d="m0 0 1 4-3 10-2 2-5-1v-1h5l-1-9h2l2-4z" fill="#76680E" />
              <path transform="translate(1578,325)" d="m0 0h12l10 2 5 4-11-2-16-3z" fill="#CEAB74" />
              <path transform="translate(604,85)" d="m0 0 4 1-5 5-4 3h-4l2-4z" fill="#E09C1B" />
              <path transform="translate(997,855)" d="m0 0h16l7 2-2 1h-21z" fill="#C28D39" />
              <path transform="translate(1159,853)" d="m0 0m-2 1 2 1v3l-14 8-1-2h2l1-3 9-6z" fill="#A87528" />
              <path transform="translate(924,760)" d="m0 0v3l-10 9-9 7-5 2 5-5 13-11z" fill="#F3AE17" />
              <path transform="translate(687,581)" d="m0 0m-1 1 1 2-3 5-8 1v2l-8 2v-2l7-3z" fill="#FBBD30" />
              <path transform="translate(602,576)" d="m0 0m-1 1 2 2-9 6-10 8h-3v-2l13-9z" fill="#FBB71B" />
              <path transform="translate(274,563)" d="m0 0h7l8 4v1l-9-1-7-1h-3z" fill="#D6AF6C" />
              <path transform="translate(1545,242)" d="m0 0 5 2 14 8 5 3-2 1-16-8-6-5z" fill="#D3B380" />
              <path transform="translate(357,864)" d="m0 0 2 1-5 3-1 2-11 4h-6l2-2z" fill="#E2A937" />
              <path transform="translate(91,614)" d="m0 0 19 4 7 1-3 2-24-4z" fill="#EDBC55" />
              <path transform="translate(1794,612)" d="m0 0 7 2 7 8-1 3-4-3v-2l-4-2z" fill="#D88F0A" />
              <path transform="translate(1244,469)" d="m0 0m-1 1m-1 1m-2 1h2l-2 4h-8l-2 4h-4l2-4 6-3z" fill="#CEA766" />
              <path transform="translate(1573,258)" d="m0 0h1l-1 7-4 6-1 5h-2v2l-5 2 2-4 6-9 3-5z" fill="#F0A912" />
              <path transform="translate(846,145)" d="m0 0 5 3 2 9-2-2-1-3-9-1 2-4z" fill="#E49A0D" />
              <path transform="translate(1759,893)" d="m0 0 2 4-1 7-6-1 2-7 3-1z" fill="#EBA30E" />
              <path transform="translate(1645,810)" d="m0 0 1 3-4 6-6 7h-2v-2l-2-1 3-2 4-2 4-7h2z" fill="#6C6519" />
              <path transform="translate(1962,773)" d="m0 0 2 2-4 3h-3v2l-6 3-7-1 4-2 6-1v-2z" fill="#DE930B" />
              <path transform="translate(1353,410)" d="m0 0h20v1l-17 1-9 2-3 7-1-4 2-6z" fill="#D0AF7B" />
              <path transform="translate(780,377)" d="m0 0 3 1-13 10-5 2 3-5h3l1-3z" fill="#B26D05" />
              <path transform="translate(1473,321)" d="m0 0h8l1 4-13-1-6 2v-2l-3-1z" fill="#E8B240" />
              <path transform="translate(456,85)" d="m0 0 13 2v5l-8-1-3-1 1-3-3-1z" fill="#B97408" />
              <path transform="translate(1470,728)" d="m0 0h2l2 6 2 2-2 3-4-1-2-7z" fill="#D0A863" />
              <path transform="translate(1539,721)" d="m0 0 2 1-6 7-9 3-1-2 13-8z" fill="#E6B551" />
              <path transform="translate(217,710)" d="m0 0m-1 1m-1 1 3 1-1 4-9 7-1-2-2-1 5-1-1-2 3-1z" fill="#AE8951" />
              <path transform="translate(1984,573)" d="m0 0 8 1 6 5h-3l-4-3-6-1-1 2 3 1v2h-8l2-4z" fill="#DC910C" />
              <path transform="translate(756,393)" d="m0 0m-1 1 2 4-11 7-1-4z" fill="#C7892B" />
              <path transform="translate(1661,303)" d="m0 0h2l-2 4-14 9h-2v-2z" fill="#8A5608" />
              <path transform="translate(1080,97)" d="m0 0 3 1 1 3-2 2-4 1-5 2v-3z" fill="#D9B678" />
              <path transform="translate(977,858)" d="m0 0 11 1 1 3-4 1h-8z" fill="#CCA86F" />
              <path transform="translate(1405,766)" d="m0 0 5 1 1 2v8l-4 5v-15z" fill="#DBAD55" />
              <path transform="translate(1060,732)" d="m0 0 4 4 5 1-3 3h-7l-1-5z" fill="#E5AA31" />
              <path transform="translate(1798,617)" d="m0 0 5 3 7 9v3h-2l-4-5v-2l-4-2z" fill="#61410F" />
              <path transform="translate(1903,477)" d="m0 0 7 1 5 4h-14z" fill="#F1C872" />
              <path transform="translate(1446,360)" d="m0 0h2v2h15v1l-5 1h-18l3-2z" fill="#DBC29B" />
              <path transform="translate(1403,767)" d="m0 0 1 2-2 6-8 2 2-4z" fill="#EAB138" />
              <path transform="translate(228,693)" d="m0 0h5l-1 4h-2l-1 3-9 1 3-2 2-1 1-4z" fill="#DF940C" />
              <path transform="translate(51,623)" d="m0 0 3 1v3h2v5h-5l-3-2 1-2 2 1-1-5z" fill="#CD7703" />
              <path transform="translate(1689,609)" d="m0 0 2 2 1 3v10h-2l-3-11h2z" fill="#CC7E0A" />
              <path transform="translate(1080,337)" d="m0 0h2v30h-1l-2-7 1-1z" fill="#EDA510" />
              <path transform="translate(1253,174)" d="m0 0 4 5 2 5-5 4-4-1 2-4 4-1-3-5z" fill="#FEFEFE" />
              <path transform="translate(1663,115)" d="m0 0 3 1-2 1-2 5-2 1v9l-2-2-1-7 4-7z" fill="#F9B824" />
              <path transform="translate(408,85)" d="m0 0h6v2l-9 2-7 2-2-1 2-3z" fill="#A36305" />
              <path transform="translate(1798,790)" d="m0 0 5 1 6 4-1 2-6-1-4-4z" fill="#CF7A04" />
              <path transform="translate(1949,787)" d="m0 0h2l-1 3-9 2h-9v-2z" fill="#CDAA73" />
              <path transform="translate(1848,786)" d="m0 0 5 1-4 4-7 4 1-5z" fill="#C48424" />
              <path transform="translate(1559,619)" d="m0 0 2 1 1 11v7l-2-2-2-12z" fill="#ECA40F" />
              <path transform="translate(1888,565)" d="m0 0 4 4 10 4v1h-7l-5-2-2-2z" fill="#CDAA73" />
              <path transform="translate(1528,328)" d="m0 0 2 1v2l14-3 4 1-12 4h-7z" fill="#D1AF78" />
              <path transform="translate(934,282)" d="m0 0h5l-2 6-6 8-1-4 3-1z" fill="#CFA96D" />
              <path transform="translate(757,262)" d="m0 0 3 2-13 9-2-2 3-1 1-4 4-1z" fill="#C78B2A" />
              <path transform="translate(910,145)" d="m0 0h6l4 4h-2v2l-5-1-3-2-2-1z" fill="#DE930C" />
              <path transform="translate(1971,771)" d="m0 0 2 1-5 5-7 2-4-1z" fill="#C57403" />
              <path transform="translate(1808,626)" d="m0 0 4 4 1 6h2l-1 4-3-1-3-7h2z" fill="#B16C05" />
              <path transform="translate(853,585)" d="m0 0 7 1 6 2-4 3h-3l-7-4z" fill="#DD920B" />
              <path transform="translate(1573,286)" d="m0 0 16 3-4 2-11-1z" fill="#EBB648" />
              <path transform="translate(1211,158)" d="m0 0 3 4v19h-1l-2-16z" fill="#FEFEFE" />
              <path transform="translate(440,89)" d="m0 0h5l-1 4-9 7v-3z" fill="#CEAC76" />
              <path transform="translate(982,854)" d="m0 0 8 2v3h-12v-2z" fill="#A46405" />
              <path transform="translate(1620,770)" d="m0 0 6 3 1 2-7 1-4 2 1-5z" fill="#F1C56C" />
              <path transform="translate(1992,749)" d="m0 0v3l-5 8-8 7h-2l2-4 5-3 7-10z" fill="#E19912" />
              <path transform="translate(1955,708)" d="m0 0 6 5 1 7-4 6-1-2 1-2v-8l-3-4z" fill="#CEAA71" />
              <path transform="translate(1070,601)" d="m0 0 12 1 7 3v3l-4-2-6-1-9-3z" fill="#BC7A0B" />
              <path transform="translate(535,594)" d="m0 0 2 3v8l-2 2v-5l-3-1-1-6 4 2z" fill="#DC9A23" />
              <path transform="translate(881,574)" d="m0 0 5 1 1 3-1 1h-9l-1-4z" fill="#D0AB6F" />
              <path transform="translate(131,523)" d="m0 0v3l-5 4-9 1-1-3 11-2z" fill="#F7C452" />
              <path transform="translate(1094,372)" d="m0 0 6 2 6 4-3 1-13-3 4-2z" fill="#F5B931" />
              <path transform="translate(1404,311)" d="m0 0h2l-2 6-4 2-5-1 5-5z" fill="#ECA40F" />
              <path transform="translate(1763,772)" d="m0 0 6 1 6 2-1 2-3 2-6-3z" fill="#EBB134" />
              <path transform="translate(718,728)" d="m0 0 1 2 5 1v8l-7-6z" fill="#E9A213" />
              <path transform="translate(1463,703)" d="m0 0h1l2 17 1 3v7l-2-4-2-8z" fill="#C47403" />
              <path transform="translate(286,480)" d="m0 0h6l1 2h-3l-1 4-4 1-2-6z" fill="#F1AD1C" />
              <path transform="translate(1368,407)" d="m0 0h13v2l-8 1h-9l1-2z" fill="#865408" />
              <path transform="translate(1363,349)" d="m0 0h3v2l-9 6-2-2-3-4h11z" fill="#CC8F31" />
              <path transform="translate(519,328)" d="m0 0h14v1l-11 2h-9v-2z" fill="#CBA66C" />
              <path transform="translate(882,306)" d="m0 0 4 2 11 7 4 2h-6l-10-6z" fill="#A46304" />
              <path transform="translate(1325,130)" d="m0 0h2l1 6-1 1h-14v-1l10-1-2-2z" fill="#B68740" />
              <path transform="translate(1659,127)" d="m0 0 4 1 2 5v4h-4z" fill="#D1840C" />
              <path transform="translate(452,40)" d="m0 0h5l-2 1v4h-7l-5-1 2-2z" fill="#FCBD2D" />
              <path transform="translate(1670,790)" d="m0 0 10 1v1l-6 1h-12v2l-3-1 1-3h9z" fill="#BE6E02" />
              <path transform="translate(1207,757)" d="m0 0 1 2h2l-1 14-2-1z" fill="#F4B835" />
              <path transform="translate(177,726)" d="m0 0 11 6 2 1-1 3-8-4-4-3z" fill="#6D4A13" />
              <path transform="translate(223,693)" d="m0 0 3 1-3 4-9-1-1-2z" fill="#FCC13A" />
              <path transform="translate(55,663)" d="m0 0 11 6-3 1-9-1-1-3z" fill="#E1CAA5" />
              <path transform="translate(97,467)" d="m0 0 7 2h7l4 3h-18z" fill="#FCB81C" />
              <path transform="translate(863,299)" d="m0 0 11 7 5 5-5-2-12-7z" fill="#CBA569" />
              <path transform="translate(1760,821)" d="m0 0 6 1 10 5 4 4-6-2-14-7z" fill="#CBA66C" />
              <path transform="translate(1335,773)" d="m0 0h6l1 3-16 1 2-3z" fill="#E69D0E" />
              <path transform="translate(25,711)" d="m0 0h1l-1 10-4 11-2 1 1-5 2-5z" fill="#E2B459" />
              <path transform="translate(755,644)" d="m0 0h1v22h-1l-1-13-2-6z" fill="#ECA40F" />
              <path transform="translate(33,592)" d="m0 0 3 3 7 11 3 5h-3l-9-15z" fill="#FBBD2E" />
              <path transform="translate(1442,592)" d="m0 0 5 1 4 2 6 2 1 2-14-3z" fill="#D4AD69" />
              <path transform="translate(1281,537)" d="m0 0 4 1 8 6v3l-5-2-4-5z" fill="#7D500B" />
              <path transform="translate(1366,523)" d="m0 0 4 4 5 13-1 3-4-7-2-7z" fill="#DF940C" />
              <path transform="translate(722,419)" d="m0 0 3 1-10 7h-2v-3h2v-4z" fill="#D2B280" />
              <path transform="translate(1423,339)" d="m0 0 4 5 6 7 2 3-1 3-6-8-4-5z" fill="#A6690D" />
              <path transform="translate(422,306)" d="m0 0 6 1 5 4 3 1v3l-5-2-9-6z" fill="#C49852" />
              <path transform="translate(1165,196)" d="m0 0 7 4 4 4-1 2-4-4-7-2-1 2-1-3z" fill="#E49B0D" />
              <path transform="translate(424,83)" d="m0 0h15l-2 1v2l-6 1v-2h-6z" fill="#B26B04" />
              <path transform="translate(345,879)" d="m0 0m-2 1h2v2l-8 3h-10l4-2z" fill="#A36405" />
              <path transform="translate(1244,865)" d="m0 0 1 4 6-4 3 1-7 6-3 1-1-5z" fill="#DCC49E" />
              <path transform="translate(1472,849)" d="m0 0 1 4-3 2-5-1-2-3z" fill="#6A460D" />
              <path transform="translate(1944,779)" d="m0 0m-2 1h2l-1 3-9 3-2 5-2-1 1-4 8-5z" fill="#F8B41F" />
              <path transform="translate(1406,773)" d="m0 0h2l-1 11-2 2-1-12z" fill="#C17002" />
              <path transform="translate(1138,770)" d="m0 0 3 4 1 3v8h-2l-3-12z" fill="#61410F" />
              <path transform="translate(917,685)" d="m0 0 6 1 7 1 1 4-6-1-8-4z" fill="#C98217" />
              <path transform="translate(76,675)" d="m0 0 6 4v5l2 3-4-2-1-3h-2z" fill="#F6B41E" />
              <path transform="translate(1261,526)" d="m0 0 6 1 5 5-7-1-4-3z" fill="#714C10" />
              <path transform="translate(217,474)" d="m0 0 3 1-10 6-5 2 1-3 7-5z" fill="#FABB2B" />
              <path transform="translate(1182,405)" d="m0 0 5 2 3 4-1 2v3l-5-5z" fill="#9A6312" />
              <path transform="translate(1023,393)" d="m0 0 6 1 6 4 1 3-6-2-7-4z" fill="#CAA469" />
              <path transform="translate(1476,844)" d="m0 0 1 3-5 10h-4l-2-2 6-2 3-8z" fill="#E1CCAC" />
              <path transform="translate(309,826)" d="m0 0 5 2 7 3h3l-5 3-6-3z" fill="#CBA66D" />
              <path transform="translate(7,712)" d="m0 0 1 4-2 10 1 5 1 3-3-1-1-2v-9z" fill="#EFBF5B" />
              <path transform="translate(1218,512)" d="m0 0 8 1 3 2-10-1 4 2v2h-6v-4l-2-1z" fill="#BA7711" />
              <path transform="translate(1309,477)" d="m0 0 5 1 13 8-2 2-16-10z" fill="#EBA30E" />
              <path transform="translate(743,405)" d="m0 0 2 1-16 11-1-3z" fill="#CBA66C" />
              <path transform="translate(1560,322)" d="m0 0h15v1l-16 2-4 2-4-1 3-2z" fill="#DB9314" />
              <path transform="translate(532,291)" d="m0 0h13v2l-5 1h-8z" fill="#CEAB74" />
              <path transform="translate(1195,809)" d="m0 0 2 2-5 8-4-1 6-8z" fill="#D78C0A" />
              <path transform="translate(153,690)" d="m0 0 2 3v4l-6 5h-2v-3h2v-2h2z" fill="#C57303" />
              <path transform="translate(1344,500)" d="m0 0 5 3 7 7v2l-4-2-8-8z" fill="#BB6F03" />
              <path transform="translate(738,382)" d="m0 0h2v2h7l-3 1-1 5-4-1z" fill="#CCA669" />
              <path transform="translate(453,321)" d="m0 0h7l3 2v3l-9-2z" fill="#C78218" />
              <path transform="translate(730,214)" d="m0 0 7 6 6 8-4-2-5-4v-2h-2z" fill="#C87804" />
              <path transform="translate(1204,12)" d="m0 0h3l1 12-2-3-1-5-2 5h-1v-7z" fill="#A66E1A" />
              <path transform="translate(561,748)" d="m0 0m-1 1v3l-3 3-5-1-2-4h7z" fill="#BC6C02" />
              <path transform="translate(1712,558)" d="m0 0 5 2 4 5h-8z" fill="#FEFEFE" />
              <path transform="translate(1393,328)" d="m0 0h2l-1 3-11 8-1-3z" fill="#C9841B" />
              <path transform="translate(412,297)" d="m0 0 5 2 9 6v1h-5l-9-8z" fill="#694A18" />
              <path transform="translate(433,237)" d="m0 0 4 5 7 9-1 2-10-11z" fill="#EBA616" />
              <path transform="translate(469,199)" d="m0 0h1v11l-2 12h-1v-13l1-1z" fill="#E79E0E" />
              <path transform="translate(1012,197)" d="m0 0h10l4 4-2 4v-4l-9-2z" fill="#CBA66C" />
              <path transform="translate(1626,152)" d="m0 0 12 5 1 2-7-1-5-3z" fill="#D4B279" />
              <path transform="translate(385,851)" d="m0 0 2 1-8 7-4 2h-3v-2l8-4z" fill="#DF950C" />
              <path transform="translate(1632,822)" d="m0 0m-1 1 3 2-4 2-12 1 1-2z" fill="#545C08" />
              <path transform="translate(6,730)" d="m0 0h13l-1 3-6 1v-2l-6-1z" fill="#C67704" />
              <path transform="translate(1565,696)" d="m0 0h2v6h-4l-3-2 3-3z" fill="#CFAE7A" />
              <path transform="translate(714,601)" d="m0 0 2 4 1 10-2 1-2-8z" fill="#DB9312" />
              <path transform="translate(277,598)" d="m0 0h14l3 1v2l-11-1-6-1z" fill="#A46912" />
              <path transform="translate(694,589)" d="m0 0h7l5 5-1 3-6-5-5-1z" fill="#AA6704" />
              <path transform="translate(371,461)" d="m0 0 5 1 1 4-3 3-2-4-2-1z" fill="#D4B480" />
              <path transform="translate(745,402)" d="m0 0 2 1-1 2-10 5-5 3 1-3 8-6z" fill="#A96808" />
              <path transform="translate(562,283)" d="m0 0h7l-5 8-2-3z" fill="#CFAA6B" />
              <path transform="translate(578,272)" d="m0 0 2 1-9 7h-6l4-3z" fill="#CEA766" />
              <path transform="translate(1599,243)" d="m0 0 3 1-9 10-2-2z" fill="#CEAB75" />
              <path transform="translate(560,104)" d="m0 0h9v2l-6 1h-10l4-2z" fill="#CD891E" />
              <path transform="translate(539,102)" d="m0 0h1l-1 9-5-4 2-4z" fill="#D88C0A" />
              <path transform="translate(548,103)" d="m0 0h10l2 2-10 2z" fill="#995E06" />
              <path transform="translate(1372,778)" d="m0 0 5 1-2 3-5 2-1 1h-7v-1l7-2z" fill="#F1AA13" />
              <path transform="translate(204,724)" d="m0 0 3 1-9 7-4 3-2-1 4-4z" fill="#D0AE7A" />
              <path transform="translate(1557,604)" d="m0 0h3v13h-2l-1-10z" fill="#ECA40F" />
              <path transform="translate(1674,596)" d="m0 0 4 2-12 5 1-4z" fill="#E0960C" />
              <path transform="translate(1487,342)" d="m0 0 5 1 3 2-1 2-6-3-3 9-1-4z" fill="#DABE90" />
              <path transform="translate(1575,322)" d="m0 0h7l10 2v1h-14z" fill="#CD8007" />
              <path transform="translate(1590,253)" d="m0 0h2l-1 4-6 9-1-3z" fill="#F6B31F" />
              <path transform="translate(1612,237)" d="m0 0h10l6 3-3 1-2-1h-10z" fill="#CB7C05" />
              <path transform="translate(1319,179)" d="m0 0h8l1 1v5l-3-3h-6l-2 4v-5z" fill="#E3990D" />
              <path transform="translate(913,154)" d="m0 0 4 1 6 4 6 7-4-2-10-8z" fill="#D3B484" />
              <path transform="translate(906,150)" d="m0 0 7 1v4l-8-1z" fill="#D18308" />
              <path transform="translate(1153,144)" d="m0 0h26v3l-2-1-24-1z" fill="#E39F1A" />
              <path transform="translate(509,99)" d="m0 0 10 1 1 2h-8l-2 7h-1z" fill="#D5B27A" />
              <path transform="translate(1263,850)" d="m0 0 2 1-8 7-4-2z" fill="#C07304" />
              <path transform="translate(1863,767)" d="m0 0h2l-1 10-3 3 1-12z" fill="#ECB84D" />
              <path transform="translate(1489,765)" d="m0 0h2v5l1 2-6 3-2-3 3-1z" fill="#CE8719" />
              <path transform="translate(156,678)" d="m0 0v3l-4 6-4 2v-3z" fill="#F6BF43" />
              <path transform="translate(79,471)" d="m0 0 2 3-10 4-2-2z" fill="#D0AC73" />
              <path transform="translate(1053,406)" d="m0 0 5 1 4 6h-3l-6-5z" fill="#865408" />
              <path transform="translate(1017,374)" d="m0 0 2 1-2 8h-4l2-6z" fill="#DFB462" />
              <path transform="translate(1028,200)" d="m0 0h1v9l-2 3h-2v-7h3z" fill="#DE9E27" />
              <path transform="translate(1379,172)" d="m0 0 3 4-3 5h-2v-8z" fill="#D0AC73" />
              <path transform="translate(934,163)" d="m0 0 7 6 3 3-6 1-3-7z" fill="#E29E1A" />
              <path transform="translate(1142,827)" d="m0 0h10l-3 3-6 1-2-1z" fill="#FEFEFE" />
              <path transform="translate(885,806)" d="m0 0 2 1-12 9-3-1 10-7z" fill="#62420E" />
              <path transform="translate(163,645)" d="m0 0h3l1 6-3 7h-1l1-11z" fill="#C06E02" />
              <path transform="translate(382,610)" d="m0 0 2 1v2l-8 5v-3l3-4z" fill="#CB8113" />
              <path transform="translate(1320,587)" d="m0 0h6v6l-3 1-3-5z" fill="#CC7D05" />
              <path transform="translate(1964,579)" d="m0 0m-1 1m-2 1h2l-2 4-4 1-1 2-3-2z" fill="#FCBA24" />
              <path transform="translate(1985,577)" d="m0 0 7 1 3 3-1 4-7-6z" fill="#BE7006" />
              <path transform="translate(763,389)" d="m0 0h6l-4 4-6 4h-2l2-4z" fill="#C08D41" />
              <path transform="translate(1649,178)" d="m0 0 2 1-8 10-1-2 2-5z" fill="#D2B078" />
              <path transform="translate(439,86)" d="m0 0h8l4 3-10 1z" fill="#CB7D0A" />
              <path transform="translate(354,879)" d="m0 0h4v2l-10 3v-3z" fill="#CEAC76" />
              <path transform="translate(1248,859)" d="m0 0 2 1-2 6 2 1-5 2v-6z" fill="#D0870F" />
              <path transform="translate(855,828)" d="m0 0h2l-1 4-5 1-3-3z" fill="#A5680D" />
              <path transform="translate(1446,818)" d="m0 0 1 3-1 3 1 9-3-3-1-4z" fill="#9C9342" />
              <path transform="translate(900,676)" d="m0 0 5 1 4 2v2l4 2-5-1-8-4z" fill="#CB9C54" />
              <path transform="translate(57,635)" d="m0 0 3 1v3l3 1h-8l-3 2 2-5h3z" fill="#CC7703" />
              <path transform="translate(1670,605)" d="m0 0 8 1 3 3h-15l4-1z" fill="#CEAC76" />
              <path transform="translate(1270,529)" d="m0 0 5 2-1 3 4 2-1 2-9-5 3-2z" fill="#B47B22" />
              <path transform="translate(82,502)" d="m0 0h10l-1 2-4 1h-7z" fill="#C97A08" />
              <path transform="translate(695,418)" d="m0 0 6 2 6 3v3l-12-6z" fill="#CBA66C" />
              <path transform="translate(1418,182)" d="m0 0h1l1 5v8l-3 1-1-4h2z" fill="#EAA412" />
              <path transform="translate(1566,155)" d="m0 0 2 1 1 5-4 1-3-3z" fill="#D2AB68" />
              <path transform="translate(856,819)" d="m0 0 3 4-1 3h-4l-2-5z" fill="#CE8509" />
              <path transform="translate(529,723)" d="m0 0h1l2 8 2 4-4-2-3-7z" fill="#C06E02" />
              <path transform="translate(136,710)" d="m0 0v3l-10 7h-2v-2h3v-2l5-3z" fill="#885C19" />
              <path transform="translate(613,585)" d="m0 0 5 2 3 4-5-2-3-1-1 2-1-4z" fill="#CA7E09" />
              <path transform="translate(1199,516)" d="m0 0h3l-2 4-1 1h-7l5-4z" fill="#A86706" />
              <path transform="translate(851,286)" d="m0 0 3 2 1 6 1 4-3-1-2-5z" fill="#CC810A" />
              <path transform="translate(1268,143)" d="m0 0h5v4l-8 1z" fill="#FCB81C" />
              <path transform="translate(1664,114)" d="m0 0h4v6l-5 1z" fill="#CEAA6E" />
              <path transform="translate(1344,783)" d="m0 0" fill="#FCB81C" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
